import { BerthVisitId, IBerthVisit } from '../state/IBerthVisit'

export const ADD_BERTH_VISITS = 'ADD_BERTH_VISITS'
export interface IAddBerthVisitsAction {
  type: typeof ADD_BERTH_VISITS
  berthVisits: IBerthVisit[]
}

export function addBerthVisits(berthVisits: IBerthVisit[]): IAddBerthVisitsAction {
  return {
    type: ADD_BERTH_VISITS,
    berthVisits,
  }
}
export const CLEAR_BERTH_VISITS = 'CLEAR_BERTH_VISITS'
export interface IClearBerthVisitsAction {
  type: typeof CLEAR_BERTH_VISITS
}

export function clearBerthVisits(): IClearBerthVisitsAction {
  return {
    type: CLEAR_BERTH_VISITS,
  }
}

export const LOAD_NO_BERTH_VISITS = 'LOAD_NO_BERTH_VISITS'
export interface ILoadNoBerthVisitsAction {
  type: typeof LOAD_NO_BERTH_VISITS
}

export function loadNoBerthVisits(): ILoadNoBerthVisitsAction {
  return {
    type: LOAD_NO_BERTH_VISITS,
  }
}

export const UPDATE_BERTH_VISITS = 'UPDATE_BERTH_VISITS'
export interface IUpdateBerthVisitsAction {
  type: typeof UPDATE_BERTH_VISITS
  payload: IBerthVisit[]
}
export function updateBerthVisitsAction(newBerthVisits: IBerthVisit[]): IUpdateBerthVisitsAction {
  return {
    type: UPDATE_BERTH_VISITS,
    payload: newBerthVisits,
  }
}

export const DELETE_BERTH_VISITS = 'DELETE_BERTH_VISITS'

export interface IDeleteBerthVisitsAction {
  type: typeof DELETE_BERTH_VISITS
  berthVisitIds: BerthVisitId[]
}

export function deleteBerthVisits(berthVisitIds: BerthVisitId[]): IDeleteBerthVisitsAction {
  return {
    type: DELETE_BERTH_VISITS,
    berthVisitIds,
  }
}
