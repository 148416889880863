import { IBerthVisitForm } from '../../state/Form/IBerthVisitForm'
import { IBerthVisitFormState } from './BerthVisitForm'

export const berthVisitFormStateToBerthVisitForm = ({
  id,
  vessel,
  berth,
  terminal,
  bollardsStart,
  bollardsEnd,
  orientation,
  arrival: arrivalBerth,
  startCargo,
  completeCargo,
  departure: departureBerth,
  stakeholderIds,
  remark,
}: IBerthVisitFormState): IBerthVisitForm => ({
  id,
  ship: vessel,
  terminalUuid: berth ? null : terminal?.terminalUuid || null,
  berthUuid: berth?.uuid || null,
  bollards:
    bollardsStart || bollardsEnd
      ? {
          start: bollardsStart,
          end: bollardsEnd,
        }
      : null,
  orientation,
  arrivalBerth,
  arrivalBerthIsActual: arrivalBerth.isActual,
  startCargo,
  startCargoIsActual: startCargo.isActual,
  completeCargo,
  completeCargoIsActual: completeCargo.isActual,
  departureBerth,
  departureBerthIsActual: departureBerth.isActual,
  stakeholderIds,
  remark,
})
