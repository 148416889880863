import React from 'react'
import styles from './SideMenuHeader.module.scss'
import { LogoWithAppName } from '../LogoWithAppName/LogoWithAppName'
import classnames from 'classnames'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

interface ISideMenuHeaderProps {
  onClose: () => void
}

export const SideMenuHeader = (props: ISideMenuHeaderProps) => (
  <div className={styles.sideMenuHeader}>
    <div className={styles.closeIconWrapper}>
      <button className={classnames([styles.closeIcon, styles.top, styles.right])} onClick={props.onClose}>
        <MaterialIcon type="close" className={styles.icon} />
      </button>
    </div>
    <div className={styles.headerLogo}>
      <LogoWithAppName />
    </div>
  </div>
)
