import React from 'react'
import styles from './InlineLoader.module.scss'
import classnames from 'classnames'

type InlineLoaderTheme = Partial<{
  loaderInline: string
  spinnerWrapper: string
  spinner: string
}>

enum InlineLoaderSize {
  Small,
  Medium,
  Large,
}

const getDefaultLightBackgroundInlineLoader = (size: InlineLoaderSize): InlineLoaderTheme => ({
  loaderInline: classnames(styles.loaderInline, {
    [styles.small]: size === InlineLoaderSize.Small,
    [styles.medium]: size === InlineLoaderSize.Medium,
    [styles.large]: size === InlineLoaderSize.Large,
  }),
  spinnerWrapper: styles.spinnerWrapper,
  spinner: styles.lightBackgroundSpinner,
})

interface IInlineLoaderProps {
  theme?: InlineLoaderTheme
  size?: InlineLoaderSize
}

// simple and small inline loader
export const InlineLoader = (props: IInlineLoaderProps) => {
  const { size = InlineLoaderSize.Small } = props
  const theme = props.theme || getDefaultLightBackgroundInlineLoader(size)

  return (
    <div className={theme.loaderInline}>
      <div className={theme.spinnerWrapper}>
        <div className={theme.spinner} />
      </div>
    </div>
  )
}
