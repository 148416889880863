import React from 'react'
import styles from './MultiSelectOptionList.module.scss'
import { SelectOptionList } from './SelectOptionList'
import { SelectOptionItem } from './SelectOptionItem'
import { GetItemPropsOptions } from 'downshift'
import { MultiSelectOption } from './MultiSelect'
import { Checkbox } from '../Checkbox/Checkbox'
import { noOp } from '../../../utils/functions'

export type MultiSelectMenuChildProps<Value> = Readonly<{
  isOpen: boolean
  getMenuProps: () => any
  getItemProps: (props: GetItemPropsOptions<MultiSelectOption<Value>>) => any
  getKey: (item: MultiSelectOption<Value>) => string
  highlightedIndex: number | null
  options: MultiSelectOption<Value>[]
}>

export function MultiSelectOptionList<Value>({ isOpen, getMenuProps, getItemProps, getKey, highlightedIndex, options }: MultiSelectMenuChildProps<Value>) {
  return (
    <SelectOptionList isOpen={isOpen} menuProps={getMenuProps()}>
      {options.map((item, index) => (
        <SelectOptionItem
          itemProps={getItemProps({
            item: item,
            index: index,
            onClick: evt => evt.preventDefault(),
          })}
          key={getKey(item)}
          isHighlighted={index === highlightedIndex}
          isSelected={item.isSelected}>
          <div className={styles.multiSelectOptionListItem}>
            <Checkbox label={''} name={''} checked={item.isSelected} onChange={noOp} />
            {item.label}
          </div>
        </SelectOptionItem>
      ))}
    </SelectOptionList>
  )
}
