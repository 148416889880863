import { useDispatch, useSelector } from 'react-redux'
import { selectedSortMethodSelector } from '../../selectors/selectedSortMethodSelector'
import { changeBerthVisitSortMethod } from '../../actions/uiActions'
import { AllSortMethodType } from '../../state/IUIState'

export const useSelectedSortMethod = () => {
  const dispatch = useDispatch()
  const selectedSortMethod = useSelector(selectedSortMethodSelector)
  const changeSortMethod = (type: AllSortMethodType) => {
    dispatch(changeBerthVisitSortMethod(type))
  }
  return { selectedSortMethod, changeSortMethod }
}
