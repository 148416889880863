import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { Pages, PAGE_PLANNING } from '../../state/Page'
import { BerthVisits } from '../BerthVisits/BerthVisits'
import { PageWrapper } from '../PageWrapper/PageWrapper'
import { LocalisationProvider } from '../../context/LocalisationContext'
import { useSelector } from 'react-redux'
import { userLocaleSelector } from '../../selectors/userLocaleSelector'
import { useBerthVisitEntryModal } from '../BerthVisitEntryModal/useBerthVisitEntryModal'
import { useSideMenu } from '../SideMenu/useSideMenu'
import { errorSelector } from '../../selectors/errorSelector'
import { Dialog } from '../Dialog/Dialog'
import { useTrackUser } from './useTrackUser'

export const App = () => {
  useTrackUser()

  const locale = useSelector(userLocaleSelector)
  const berthVisitEntryProps = useBerthVisitEntryModal()
  const sideMenuProps = useSideMenu()
  const error = useSelector(errorSelector)

  return (
    <PageWrapper {...sideMenuProps} {...berthVisitEntryProps}>
      <LocalisationProvider locale={locale}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={Pages[PAGE_PLANNING]} />} />
          <Route path={Pages[PAGE_PLANNING]} component={BerthVisits} />
          <Route render={() => '404'} />
        </Switch>
      </LocalisationProvider>
      {error && (
        <Dialog>
          <error.dialogContents />
        </Dialog>
      )}
    </PageWrapper>
  )
}
