import ky from 'ky'
import { BACKEND_URL } from '../config'
import { Either, left, right } from 'fp-ts/lib/Either'
import { deconstructResponseError } from './handleActionPromise'

export const injectHeaders = (request: Request) => {
  request.headers.set('X-Requested-With', 'Pronto')
}

export const backend = ky.create({
  prefixUrl: BACKEND_URL,
  hooks: {
    beforeRequest: [injectHeaders],
  },
})

export type HandleRequestReturn<T> = Promise<Either<[number, Error], T>>
export const handleRequest = async <T>(requestAction: () => Promise<T>): HandleRequestReturn<T> => {
  try {
    const result = await requestAction()
    return right(result)
  } catch (e) {
    if (e instanceof ky.HTTPError) {
      const responseError = await deconstructResponseError(e.response)
      return left(responseError)
    }

    // Generic error (not HTTP)
    return left([-1, e])
  }
}
