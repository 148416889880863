import React from 'react'
import { Label, LabelTheme } from '../Label/Label'
import { renderIf } from '../../../utils/rendering'
import styles from './InputLabel.module.scss'
import classnames from 'classnames'

const disabledLabelTheme: LabelTheme = {
  label: styles.disabled,
}

export type InputLabelProps = Readonly<{
  text: string
  isRequired?: boolean
  isDisabled?: boolean
}>

export const InputLabel: React.FC<InputLabelProps> = ({ text, isRequired, isDisabled, children }) => (
  <div className={styles.inputLabel}>
    <Label theme={getLabelTheme(isDisabled)}>
      <div className={styles.labelText}>
        <span>{text}</span>
        {renderIf(isRequired === true, <span className={getClassNamesForAsterisk(isDisabled)}>*</span>)}
      </div>
      {children}
    </Label>
  </div>
)

const getLabelTheme = (disabled?: boolean) => (disabled ? disabledLabelTheme : undefined)

const getClassNamesForAsterisk = (disabled?: boolean) => classnames(disabled ? [styles.asterisk, styles.disabled] : styles.asterisk)
