import React from 'react'
import { TimeInput, TimeInputProps } from './TimeInput'
import { FieldAttributes, useField } from 'formik'

export type FormikTimeInputProps = FieldAttributes<Omit<TimeInputProps, 'value' | 'handleChange' | 'errorMessage'>>
export const FormikTimeInput: React.FC<FormikTimeInputProps> = props => {
  const [field, meta, helpers] = useField<string>(props)
  const errorMessage = meta.error && meta.touched ? meta.error : undefined

  return <TimeInput value={field.value} handleChange={helpers.setValue} handleBlur={() => helpers.setTouched(true)} errorMessage={errorMessage} {...props} />
}
