import React from 'react'
import { Modal, modalNarrowTheme } from '../Modal/Modal'
import { ModalHeader } from '../Modal/Header/ModalHeader'
import { ModalContent } from '../Modal/Content/ModalContent'
import { Button, buttonSecondaryTheme } from '../Form/Button/Button'
import { ButtonContainer } from '../ButtonContainer/ButtonContainer'
import { ModalBody } from '../Modal/Body/ModalBody'

type ConfirmationModalProps = Readonly<{
  title: string
  body: React.ReactNode
  cancelText: string
  confirmText: string
  onClose: () => void
  onConfirm: () => void
}>

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ title, body, cancelText, confirmText, onClose, onConfirm }) => {
  return (
    <Modal onClose={onClose} modalTheme={modalNarrowTheme}>
      <ModalHeader onCloseButtonClick={onClose} title={title} />
      <ModalContent>
        <ModalBody>{body}</ModalBody>
        <ButtonContainer>
          <Button type="button" theme={buttonSecondaryTheme} onClick={onClose} text={cancelText} />
          <Button type="button" onClick={onConfirm} text={confirmText} />
        </ButtonContainer>
      </ModalContent>
    </Modal>
  )
}
