import { BerthVisitTableRow, LayBySlotTableRow } from './BerthVisitTableRow'
import React from 'react'
import styles from './BerthVisitsTable.module.scss'
import { ITableRow, TableRowType } from '../../selectors/berthVisitTableRow'
import { UseSelectRows } from '../BerthVisits/useSelectRows'
import { UseBerthVisitEntryModal } from '../BerthVisitEntryModal/useBerthVisitEntryModal'
import { isEmpty } from 'fp-ts/lib/Array'
import { NoBerthVisits } from './NoBerthVisits'
import { ILayByBerth } from '../../Domain/LayByBerth'

type BerthVisitsTableProps = Readonly<{
  rows: ITableRow[]
  editLayByBerthHandler: (layByBerthId: ILayByBerth['uuid']) => void
}> &
  Pick<UseSelectRows, 'selectedRows' | 'selectSingle'> &
  Pick<UseBerthVisitEntryModal, 'editBerthVisitHandler'>

export const BerthVisitsTable: React.FC<BerthVisitsTableProps> = ({ rows, selectSingle, selectedRows, editBerthVisitHandler, editLayByBerthHandler }) =>
  isEmpty(rows) ? (
    <NoBerthVisits />
  ) : (
    <table className={styles.berthVisitsTable}>
      <tbody data-testid="berth-visits-table">
        {rows.map(item => {
          switch (item.type) {
            case TableRowType.BERTH_VISIT: {
              return (
                <BerthVisitTableRow
                  key={item.id}
                  berthVisit={item}
                  selected={selectedRows.includes(item)}
                  onSelect={checked => selectSingle(item, checked)}
                  onClick={() => editBerthVisitHandler(item.id)}
                />
              )
            }
            case TableRowType.LAY_BY_BERTH: {
              return (
                <LayBySlotTableRow
                  key={item.id}
                  layByBerth={item}
                  selected={selectedRows.includes(item)}
                  onSelect={checked => selectSingle(item, checked)}
                  onClick={() => editLayByBerthHandler(item.id)}
                />
              )
            }
            default: {
              const exhaustive: never = item
              throw new Error(exhaustive)
            }
          }
        })}
      </tbody>
    </table>
  )
