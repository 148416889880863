import React from 'react'
import styles, { Styles } from './Label.module.scss'
import classnames from 'classnames'

type LabelProps = Readonly<{
  theme?: LabelTheme
}>

export type LabelTheme = Partial<Styles>

export const Label: React.FC<LabelProps> = ({ theme, children }) => <label className={classnames(styles.label, theme?.label)}>{children}</label>
