import React from 'react'
import { useDispatch } from 'react-redux'
import { ErrorHeader } from '../Dialog/ErrorHeader'
import { ErrorFooter } from '../Dialog/ErrorFooter'
import { Button } from '../Form/Button/Button'
import { clearError } from '../../actions/errorActions'
import { getReloadBerthVisitsThunk } from '../../actions/thunks'

type InvalidInputErrorProps = Readonly<{
  error?: Error
}>

export const InvalidInputError = ({ error }: InvalidInputErrorProps) => {
  const dispatch = useDispatch()

  return (
    <>
      <ErrorHeader>Invalid input</ErrorHeader>
      <p>Something went wrong when submitting the form. Please review the errors and try again.</p>
      {error && (
        <>
          <p>Response from server was:</p>
          <p>
            <code>{error.message}</code>
          </p>
        </>
      )}
      <ErrorFooter>
        <Button
          onClick={() => {
            dispatch(getReloadBerthVisitsThunk())
            dispatch(clearError())
          }}
          text="Close"
          type="button"
        />
      </ErrorFooter>
    </>
  )
}
