import React from 'react'
import styles from './BerthVisitsHeader.module.scss'
import { Checkbox, CheckboxTheme } from '../Form/Checkbox/Checkbox'
import { UseSelectRows } from '../BerthVisits/useSelectRows'
import { Button, ButtonTheme } from '../Form/Button/Button'
import { Arrays } from '../../utils/Arrays'
import classnames from 'classnames'
import { DropoutMenu, DropoutStateType } from '../DropoutMenu/DropoutMenu'
import { DropoutMenuDropper } from '../DropoutMenu/DropoutMenuDropper'
import { Select, SelectOption } from '../Form/Select/Select'
import { toArray } from 'fp-ts/es6/Record'
import { useSelectedSortMethod } from '../BerthVisits/useSelectedSortMethod'
import { TimeRangeOptions, useSelectedTimeRange } from '../BerthVisits/useSelectedTimeRange'
import { useSelectedVesselTypes } from '../BerthVisits/useSelectedVesselTypes'
import { entries } from '../../utils/Object'
import { StandardOptionList } from '../Form/Select/StandardOptionList'
import { BerthVisitSorterDescription, AllSortMethods, CommonSortMethods, AllSortMethodType } from '../../state/IUIState'
import { SecondaryHeader } from '../Header/Header'
import { CenteredGrid } from '../CenteredGrid/CenteredGrid'
import { FeatureToggle, useFeatureToggle } from '../FeatureToggle/FeatureToggle'
import { isVesselPlanner } from '../../utils/roles'

const deleteButtonTheme: ButtonTheme = {
  button: styles.deleteButton,
  label: styles.deleteButtonLabel,
}
const selectAllButtonTheme: CheckboxTheme = {
  checkBoxLabel: styles.selectAllLabel,
  checkboxIcon: styles.selectAllIcon,
  checkBox: styles.selectAllCheckbox,
}
const filterButtonTheme = (dropoutState: DropoutStateType): ButtonTheme => ({
  label: styles.filterButtonLabel,
  button: classnames(styles.filterButton, { [styles.filterButtonActive]: dropoutState === DropoutStateType.Open }),
  icon: styles.filterButtonIcon,
})

const timeRangesToOptions = (timeRanges: TimeRangeOptions) =>
  entries(timeRanges).map(([key, value]): SelectOption<number> => ({ label: value, value: Number(key) }))

const sortMethodsToOptions = <T extends AllSortMethodType>(sortMethods: Record<T, BerthVisitSorterDescription>) =>
  entries(sortMethods).map(([type, sorter]): SelectOption<T> => ({ label: sorter.label, value: type }))

export const BerthVisitsHeader = (props: Pick<UseSelectRows, 'allSelected' | 'selectedRows' | 'selectAll' | 'deselectAll' | 'deleteSelected'>) => {
  const { allSelected, selectAll, deselectAll, selectedRows, deleteSelected } = props
  const { changeTimeRange, selectedTimeRange, timeRanges } = useSelectedTimeRange()
  const { toggleVesselType, vesselTypes } = useSelectedVesselTypes()
  const { changeSortMethod, selectedSortMethod } = useSelectedSortMethod()
  const checkIsTerminalPlanner = useFeatureToggle([isVesselPlanner])

  const timeRangeOptions = timeRangesToOptions(timeRanges)
  const selectedTime = timeRangeOptions.find(tr => tr.value === selectedTimeRange) || timeRangeOptions[0]
  const sortingOptions = sortMethodsToOptions(checkIsTerminalPlanner ? AllSortMethods : CommonSortMethods)
  const selectedSortingOption = sortingOptions.find(tr => tr.value === selectedSortMethod) || sortingOptions[0]

  return (
    <SecondaryHeader className={styles.berthVisitsHeader}>
      <CenteredGrid className={styles.berthVisitsHeaderInner}>
        <div className={styles.berthVisitsHeaderLeft}>
          <Checkbox
            label={allSelected ? 'Deselect all' : 'Select all'}
            checked={allSelected}
            name={'select-all'}
            theme={selectAllButtonTheme}
            onChange={selected => (selected ? selectAll() : deselectAll())}
          />
          {Arrays.isNotEmpty(selectedRows) && (
            <Button
              type="button"
              text={
                <>
                  Cancel <span className={styles.cancelButtonTextInner}>{selectedRows.length}&nbsp;</span>
                  {selectedRows.length === 1 ? 'entry' : 'entries'}
                </>
              }
              onClick={deleteSelected}
              materialIconName={'delete_forever'}
              theme={deleteButtonTheme}
            />
          )}
        </div>
        {selectedRows.length === 0 && (
          <div className={styles.berthVisitsHeaderRight}>
            <DropoutMenu>
              {({ dropoutState, closeDropout, openDropout }) => (
                <>
                  <Button
                    type="button"
                    materialIconName="filter_list"
                    text="Filter list"
                    onClick={dropoutState.state === DropoutStateType.Open ? closeDropout : openDropout}
                    theme={filterButtonTheme(dropoutState.state)}
                  />
                  <DropoutMenuDropper isOpen={dropoutState.state === DropoutStateType.Open}>
                    <FeatureToggle checks={[isVesselPlanner]}>
                      <div className={styles.dropoutHeaderInputGroup} data-testid="vessel-options">
                        <h2 className={styles.dropoutHeader}>Filter entries:</h2>
                        {toArray(vesselTypes).map(([vesselType, vesselOption]) =>
                          !vesselOption.isDisabled ? (
                            <Checkbox
                              label={vesselOption.label}
                              onChange={() => toggleVesselType(vesselType)}
                              checked={vesselOption.selected}
                              key={`vesselType-${vesselType}`}
                              name={`vesselType-${vesselType}`}
                            />
                          ) : null
                        )}
                      </div>
                      <div className={styles.dropoutHeaderInputGroup} data-testid="time-options">
                        <Select
                          name="time-range"
                          label="Time range"
                          options={timeRangeOptions}
                          selectedOption={selectedTime}
                          handleChange={changeTimeRange}
                          getKey={option => option.value.toString()}>
                          {props => <StandardOptionList {...props} />}
                        </Select>
                      </div>
                    </FeatureToggle>
                    <div className={styles.dropoutHeaderInputGroup} data-testid="sort-options">
                      <h2 className={styles.dropoutHeader}>Sort entries:</h2>
                      <Select
                        name="sort-berth-visits"
                        label="Choose sorting"
                        options={sortingOptions}
                        selectedOption={selectedSortingOption}
                        handleChange={option => changeSortMethod(option.value)}
                        getKey={option => option.value}>
                        {props => <StandardOptionList {...props} />}
                      </Select>
                    </div>
                  </DropoutMenuDropper>
                </>
              )}
            </DropoutMenu>
          </div>
        )}
      </CenteredGrid>
    </SecondaryHeader>
  )
}
