import { entries, omitKey } from '../utils/Object'
import { BrandedCovariant } from '../utils/branded'
import { Arrays } from '../utils/Arrays'
import { None, none } from '../utils/strictNull'

export type Imo = BrandedCovariant<string, 'imo'>
export type Eni = BrandedCovariant<string, 'eni'>
export type Mmsi = BrandedCovariant<string, 'mmsi'>
export type Uscg = BrandedCovariant<string, 'uscg'>

export interface IShipId {
  imo?: Imo
  eni?: Eni
  mmsi?: Mmsi
  uscg?: Uscg
  callSign?: string
  name?: string
}
type ShipIdKeys = Omit<IShipId, 'name' | 'callSign'>
type ShipIdsList = [keyof ShipIdKeys, string | undefined][]
const ShipIdKeyOrder: Record<keyof ShipIdKeys, number> = {
  imo: 0,
  eni: 1,
  mmsi: 2,
  uscg: 3,
}

export function shipIdToDescription(shipId: IShipId): string {
  const shipIds: ShipIdsList = entries(omitKey(omitKey(shipId, 'name'), 'callSign'))
  const sortedShipIds = shipIds.sort(([key]) => ShipIdKeyOrder[key])
  const firstId = sortedShipIds.find(([key, value]) => value !== undefined)
  if (firstId !== undefined) {
    return `${firstId[0].toLocaleUpperCase()} ${firstId[1]}`
  } else {
    throw new Error('Not a single id available!')
  }
}

export type ShipIdComponents = Readonly<{
  label: string
  text: string
}>

export function shipIdToDescriptionComponents(shipId: IShipId): ShipIdComponents {
  const shipIds: ShipIdsList = entries(omitKey(omitKey(shipId, 'name'), 'callSign'))
  const sortedShipIds = shipIds.sort(([key]) => ShipIdKeyOrder[key])
  const firstId: [string, string] | None = Arrays.getFirst(Arrays.filterTypeGuard(sortedShipIds, ([key, value]) => (value !== undefined ? [key, value] : none)))

  if (firstId !== none) {
    const [label, text] = firstId
    return { label: label.toUpperCase(), text }
  } else {
    throw new Error('Not a single id available!')
  }
}

export interface IShip {
  shipId: IShipId
  name: string
}
