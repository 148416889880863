import React from 'react'
import classnames from 'classnames'
import styles from './Toggle.module.scss'
import { HiddenCheckboxWrapper } from '../HiddenCheckboxWrapper/HiddenCheckboxWrapper'

export const TOGGLE_THEME_DEFAULT: ToggleTheme = {
  toggle: styles.toggle,
  checked: styles.checked,
  disabled: styles.disabled,
  toggleText: styles.toggleText,
}

export const TOGGLE_THEME_GREEN: ToggleTheme = {
  ...TOGGLE_THEME_DEFAULT,
  toggle: styles.toggleGreen,
}

export type ToggleTheme = Readonly<{
  toggleContainer?: string
  toggle: string
  checked: string
  disabled: string
  toggleText: string
}>

interface IToggleProps {
  isChecked: boolean
  labelOn?: string
  labelOff?: string
  disabled?: boolean
  handleToggle: (checked: boolean) => void
  theme?: ToggleTheme
}

export const Toggle = ({ theme = TOGGLE_THEME_DEFAULT, ...props }: IToggleProps) => {
  const label = props.isChecked ? props.labelOn : props.labelOff

  return (
    <div className={theme?.toggleContainer} data-testid="toggle">
      <HiddenCheckboxWrapper isChecked={props.isChecked} disabled={props.disabled} handleToggle={props.handleToggle}>
        <div className={classnames(theme.toggle, { [theme.checked]: props.isChecked, [theme.disabled]: props.disabled })}>
          {label && <div className={theme.toggleText}>{label}</div>}
        </div>
      </HiddenCheckboxWrapper>
    </div>
  )
}
