import * as React from 'react'
import styles from './Dialog.module.scss'
import classnames from 'classnames'

export const Dialog = (props: { children?: React.ReactNode }) => (
  <div
    className={classnames(styles.overlay, styles.active)}
    onClick={e => {
      e.stopPropagation()
      e.preventDefault()
    }}>
    <div className={styles.dialog}>{props.children}</div>
  </div>
)
