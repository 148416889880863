import { Subject } from 'rxjs'
import { toResultObservable } from '../../utils/toResultObservable'
import { PromiseOfValidated } from '../../utils/PromiseOfValidated'
import { AppAction } from '../../actions/AppAction'
import { useMemo } from 'react'

export const useResultObservable = <Result>(fetcher: (query: string) => PromiseOfValidated<AppAction, Result[]>) => {
  const querySubject = useMemo(() => new Subject<string>(), [])
  const shipResultObservable = useMemo(
    () =>
      toResultObservable({
        searchQueryObservable: querySubject,
        fetcher,
      }),
    [fetcher, querySubject]
  )

  return { querySubject, shipResultObservable }
}
