import { IBerth } from '../state/IBerth'

export const SET_BERTHS = 'SET_BERTHS'

export interface ISetBerthsAction {
  type: typeof SET_BERTHS
  berths: IBerth[]
}

export function setBerths(berths: IBerth[]): ISetBerthsAction {
  return {
    type: SET_BERTHS,
    berths
  }
}