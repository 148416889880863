import { AppAction } from '../actions/AppAction'
import { LOGIN, LOGOUT } from '../actions/authActions'

export function authTokenReducer(authToken: string | undefined = undefined, action: AppAction): string | undefined {
  switch (action.type) {
    case LOGIN:
      return action.authToken
    case LOGOUT:
      return undefined
    default:
      return authToken
  }
}
