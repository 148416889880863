import React from 'react'
import classnames from 'classnames'
import styles, { Styles } from './TextArea.module.scss'

type TextAreaTheme = Partial<Styles>

type TextAreaProps = Readonly<{
  id?: string
  name?: string
  defaultValue?: string
  value?: string
  placeholder?: string
  rows?: number
  maxLength?: number
  isDisabled?: boolean
  hasError?: boolean
  theme?: TextAreaTheme
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
}>

export const TextArea: React.FC<TextAreaProps> = ({ id, name, defaultValue, value, placeholder, rows, maxLength, isDisabled, hasError, theme, handleChange, handleBlur }) => (
  <textarea
    className={classnames(styles.textarea, {[styles.error]: hasError}, theme?.textarea)}
    id={id}
    name={name}
    defaultValue={defaultValue}
    value={value}
    placeholder={placeholder}
    rows={rows}
    maxLength={maxLength}
    disabled={isDisabled}
    onChange={handleChange}
    onBlur={handleBlur}
  />
)
