import React, { useRef, useEffect } from 'react'
import styles, { Styles } from './Modal.module.scss'
import classnames from 'classnames'
import { useClickOutside } from '../DropoutMenu/useClickOutside'
import { noOp } from '../../utils/functions'

export const modalNarrowTheme: ModalTheme = {
  modal: styles.modalNarrow,
}

export type ModalTheme = Partial<Styles>

type ModalProps = Readonly<{
  children: React.ReactNode
  modalTheme?: ModalTheme
  closeOnClickOutside?: boolean
  onClose: () => void
}>

export const Modal = ({ children, modalTheme, onClose, closeOnClickOutside = true }: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const { onRef } = useClickOutside(closeOnClickOutside ? onClose : noOp)
  useEffect(() => {
    onRef(ref)
  }, [ref, onRef])

  return (
    <div className={styles.modalContainer} data-testid="modal">
      <div ref={ref} className={classnames(styles.modal, modalTheme?.modal)}>
        {children}
      </div>
    </div>
  )
}
