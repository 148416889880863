import mixpanel, { Config } from 'mixpanel-browser'
import md5 from 'md5'

/* Try not to use these methods directly, connect through useMixpanel hook */
export const initMixpanel = (id: string, config: Partial<Config>) => {
  mixpanel.init(id)
  mixpanel.set_config(config)
}

export const trackEvent = (name: string, properties?: {}) => {
  mixpanel.track(name, properties)
}

export const setTrackingSuperProperties = (properties: {}) => {
  mixpanel.register(properties)
}

export const setTrackingSuperPropertiesOnce = (properties: {}) => {
  mixpanel.register_once(properties)
}

export const identifyUserForTracking = (email: string) => {
  // Obfuscate email to make it unreadable in mixpanel dashboard
  const distinctId = md5(email)
  mixpanel.identify(distinctId)
}

export const setTrackingUserProperties = (properties: {}) => {
  mixpanel.people.set(properties)
}

export const incrementUserProperty = (key: string, value: number) => {
  mixpanel.people.increment({ [key]: value })
}

export const incrementSuperProperty = (key: string, value: number) => {
  setTrackingSuperPropertiesOnce({ [key]: 0 })
  const incrementedProp = mixpanel.get_property(key) + value
  setTrackingSuperProperties({ [key]: incrementedProp })
}

export const resetTracking = () => {
  mixpanel.reset()
}
/* End of methods */
