import { format, utcToZonedTime } from 'date-fns-tz'
import { TimeZone, timeZoneUTC } from '../../state/TimeZone'
import { Option, getOrElse } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'

export function formatDateWithOptionalTimeZone(date: Date, formatting: string, timeZone: Option<TimeZone>): string {
  const timeZoneInfo = pipe(
    timeZone,
    getOrElse<TimeZone>(() => timeZoneUTC)
  )

  const zonedTime = utcToZonedTime(date, timeZoneInfo)
  return format(zonedTime, formatting, { timeZone: timeZoneInfo })
}
