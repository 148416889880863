import { createSelector } from 'reselect'
import { ITerminalWithBerths } from '../state/IBerth'
import { sortAlphabeticallyWithNullLast } from '../utils/ordering'
import { terminalsWithBerthsSelector } from './terminalsWithBerthsSelector'

export const getSortedTerminalsWithSortedBerths = (terminals: ITerminalWithBerths[]): ITerminalWithBerths[] =>
  terminals
    .map(terminal => ({
      ...terminal,
      berths: terminal.berths.sort((left, right) => sortAlphabeticallyWithNullLast(left.name, right.name)),
    }))
    .sort((left, right) =>
      // Sorts terminal by terminalUuid when left and right has no terminalName
      // This results in the terminal with no terminalName and no terminalUuid to be last
      !left.terminalName && !right.terminalName
        ? sortAlphabeticallyWithNullLast(left.terminalUuid, right.terminalUuid)
        : sortAlphabeticallyWithNullLast(left.terminalName, right.terminalName)
    )

export const sortedTerminalsWithSortedBerthsSelector = createSelector(terminalsWithBerthsSelector, getSortedTerminalsWithSortedBerths)
