import { WithDefault, keys } from './Object'

export type Validated<E, A> = Valid<E, A> | Invalid<E, A>

export type ErrorOfValidated<V extends Validated<any, any>> = V['errorType']
export type ValueOfValidated<V extends Validated<any, any>> = V['valueType']

export type ValidatedOf<E, O> = { [K in keyof O]: Validated<E, O[K]> }

export const Validated = {
  ok<E, A>(a: A): Valid<E, A> {
    return new Valid<E, A>(a)
  },

  error<E, A>(error: E): Invalid<E, A> {
    return new Invalid<E, A>([error])
  },

  errors<E, A>(errors: E[]): Invalid<E, A> {
    return new Invalid<E, A>(errors)
  },

  notUndefined<E, A>(value: A | undefined, error: E): Validated<E, A> {
    if (value === undefined) {
      return Validated.error(error)
    } else {
      return Validated.ok(value)
    }
  },

  fromEither<A>(validated: Validated<A, A[]>): A[] {
    return validated.fold(v => v.value, v => v.errors)
  },

  seq<E, A>(vs: Validated<E, A>[]): Validated<E, A[]> {
    const errors: E[] = []
    const values: A[] = []
    vs.forEach(v => {
      if (v.type === Valid.type) {
        values.push(v.value)
      } else {
        errors.push(...v.errors)
      }
    })

    if (errors.length === 0) {
      return Validated.ok(values)
    } else {
      return Validated.errors(errors)
    }
  },

  combine<O extends Record<string, Validated<any, any>>, K extends keyof O>(o: O): Validated<ErrorOfValidated<O[K]>, { [K in keyof O]: ValueOfValidated<O[K]> }> {
    const errors: any[] = []
    const values: { [K in keyof O]?: any } = {}
    keys(o).forEach(key => {
      const validated: Validated<any, any> = o[key]
      if (validated.type === Valid.type) {
        values[key as keyof O] = validated.value
      } else if (validated.type === Invalid.type) {
        validated.errors.forEach((e: any) => {
          errors.push(e)
        })
      } else {
        const exhaustive: never = validated
        throw exhaustive
      }
    })

    if (errors.length > 0) {
      return new Invalid(errors)
    } else {
      return new Valid(values as any)
    }
  },

  /**
   * Split an array of `Validated<E, T>` into errors (`E[]`) and values (`T[]`).
   */
  splitArray<E, T>(validateds: Array<Validated<E, T>>): { invalid: E[]; valid: T[] } {
    const invalid: E[] = []
    const valid: T[] = []
    validateds.forEach(validated => {
      if (validated.type === Valid.type) {
        valid.push(validated.value)
      } else if (validated.type === Invalid.type) {
        invalid.push(...validated.errors)
      } else {
        const exhaustive: never = validated
        throw exhaustive
      }
    })

    return { valid, invalid }
  },

  withDefault<O, Default>(validatedOf: ValidatedOf<any, O>, d: Default): WithDefault<O, Default> {
    const result: Partial<WithDefault<O, Default>> = {}
    const keys = Object.keys(validatedOf) as Array<keyof O>
    keys.forEach((key: keyof O) => {
      const validated: Validated<any, O[keyof O]> = validatedOf[key]
      if (validated.type === Valid.type) {
        result[key] = validated.value
      } else if (validated.type === Invalid.type) {
        result[key] = d
      } else {
        const exhaustive: never = validated
        throw exhaustive
      }
    })

    return result as WithDefault<O, Default>
  },

  splitObject<E, O>(validatedOf: ValidatedOf<E, O>): { invalid: E[]; valid: Partial<O> } {
    const invalid: E[] = []
    const valid: Partial<O> = {}
    const keys = Object.keys(validatedOf) as Array<keyof O>
    keys.forEach((key: keyof O) => {
      const validated: Validated<E, O[keyof O]> = validatedOf[key]
      if (validated.type === Valid.type) {
        valid[key] = validated.value
      } else if (validated.type === Invalid.type) {
        invalid.push(...validated.errors)
      } else {
        const exhaustive: never = validated
        throw exhaustive
      }
    })

    return { valid, invalid }
  },

  compose<E, T>(...rules: Array<(t: T) => Validated<E, T>>): (t: T) => Validated<E, T> {
    return (t: T): Validated<E, T> => {
      const applied = rules.map(rule => rule(t))
      const split = Validated.splitArray(applied)
      if (split.invalid.length === 0) {
        return Validated.ok(t)
      } else {
        return Validated.errors(split.invalid)
      }
    }
  }
}

export class Valid<E, A> {
  static readonly type = 'ok'
  readonly type = Valid.type

  readonly valueType: A = (null as any) as A
  readonly errorType: E = (null as any) as E

  constructor(readonly value: A) {}

  isValid(): this is Valid<E, A> {
    return true
  }

  map<B>(fn: (a: A) => B): Validated<E, B> {
    return new Valid<E, B>(fn(this.value))
  }

  flatMap<B>(fn: (a: A) => Validated<E, B>): Validated<E, B> {
    return fn(this.value)
  }

  mapError<F>(fn: (e: E) => F): Validated<F, A> {
    return new Valid<F, A>(this.value)
  }

  fold<B>(ok: (v: Valid<E, A>) => B, error: (v: Invalid<E, A>) => B): B {
    return ok(this)
  }

  andThen<B>(fn: (a: A) => Validated<E, A>): Validated<E, A> {
    return fn(this.value)
  }

  withValueOf<B>(that: Validated<E, B>): Validated<E, B> {
    return that
  }

  fromEither<A>(this: Validated<A, A[]>): A[] {
    return Validated.fromEither(this)
  }
}

export class Invalid<E, A> {
  static readonly type = 'error'
  readonly type = Invalid.type

  readonly valueType: A = (null as any) as A
  readonly errorType: E = (null as any) as E

  constructor(readonly errors: E[]) {}

  isValid(): this is Valid<E, A> {
    return false
  }

  map<B>(fn: (a: A) => B): Validated<E, B> {
    return new Invalid<E, B>(this.errors)
  }

  flatMap<B>(fn: (a: A) => Validated<E, B>): Validated<E, B> {
    return Validated.errors<E, B>(this.errors)
  }

  mapError<F>(fn: (e: E) => F): Validated<F, A> {
    return new Invalid<F, A>(this.errors.map(fn))
  }

  fold<B>(ok: (v: Valid<E, A>) => B, error: (v: Invalid<E, A>) => B): B {
    return error(this)
  }

  andThen<B>(fn: (a: A) => Validated<E, B>): Validated<E, B> {
    return Validated.errors(this.errors)
  }

  withValueOf<B>(that: Validated<E, B>): Validated<E, B> {
    return that.isValid() ? Validated.errors(this.errors) : Validated.errors([...this.errors, ...that.errors])
  }

  fromEither<A>(this: Validated<A, A[]>): A[] {
    return Validated.fromEither(this)
  }
}
