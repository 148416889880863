import React from 'react'
import classnames from 'classnames'
import styles from './SideMenu.module.scss'
import { SideMenuHeader } from './SideMenuHeader'

interface ISideMenuProps {
  isActive: boolean
  onClose: () => void
  children: React.ReactNode
}

export const SideMenu: React.FC<ISideMenuProps> = ({ onClose, isActive, children }) => (
  <>
    <div className={classnames(styles.sideMenuOverlay, { [styles.active]: isActive })} onClick={onClose} />
    <div className={classnames(styles.sideMenu, { [styles.active]: isActive })} data-testid={isActive ? 'side-menu-open' : 'side-menu-closed'}>
      <SideMenuHeader onClose={onClose} />
      <div className={styles.menuContainer}>{children}</div>
    </div>
  </>
)
