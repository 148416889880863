import * as React from 'react'
import { ErrorHeader } from '../Dialog/ErrorHeader'
import { SUPPORT_EMAIL } from '../../constants'

export const DefaultError = () => (
  <>
    <ErrorHeader>Something went wrong</ErrorHeader>
    <p>
      Something unexpected went wrong. This means that either some piece of information could not be retrieved from the server, or your last action could not
      complete. Please refresh the page and try again. If this does't work we kindly ask you to contact us so we can investigate further. You can reach us
      through <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
    </p>
  </>
)
