import { createHeaders } from './createHeaders'
import { fetchJsonWithErrorHandling } from './fetchJsonWithErrorHandling'
import { IShip, IShipId } from '../state/IShip'
import { PromiseOfValidated } from '../utils/PromiseOfValidated'
import { AppAction } from '../actions/AppAction'
import { BACKEND_URL } from '../config'

interface IShipJson {
  ship: IShipId
  name: string
}

function toShip(json: IShipJson): IShip {
  return { shipId: json.ship, name: json.name }
}

function toShips(json: IShipJson[]): IShip[] {
  return json.map(toShip)
}

export function fetchVesselSearch(queryString: string, authToken: string | undefined): PromiseOfValidated<AppAction, IShip[]> {
  const escapedQuery = encodeURIComponent(queryString)
  const options: RequestInit = {
    method: 'GET',
    headers: createHeaders({ authToken }),
  }

  return fetchJsonWithErrorHandling<IShipJson[]>(`${BACKEND_URL}/api/vessels/search/${escapedQuery.toUpperCase()}`, options).map(toShips)
}
