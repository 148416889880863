import { createSelector } from 'reselect'
import { berthsSelector } from './berthsSelector'
import { IBerth, ITerminal, ITerminalWithBerths } from '../state/IBerth'
import { terminalsSelector } from './terminalsSelector'

export const getTerminalsWithBerths = (terminals: ITerminal[], berths: IBerth[]): ITerminalWithBerths[] =>
  terminals.map(
    (terminal: ITerminal): ITerminalWithBerths => ({
      ...terminal,
      berths: berths.filter(berth => berth.terminalUuid === terminal.terminalUuid && berth.terminalName === terminal.terminalName),
    })
  )

export const terminalsWithBerthsSelector = createSelector(terminalsSelector, berthsSelector, getTerminalsWithBerths)
