import { ILayByBerth } from '../Domain/LayByBerth'

export const CLEAR_LAY_BY_BERTHS = 'CLEAR_LAY_BY_BERTHS'
export interface IClearLayByBerthsAction {
  type: typeof CLEAR_LAY_BY_BERTHS
}
export const clearLayByBerths = (): IClearLayByBerthsAction => ({
  type: CLEAR_LAY_BY_BERTHS,
})

export const LOAD_NO_LAY_BY_BERTHS = 'LOAD_NO_LAY_BY_BERTHS'
export interface ILoadNoLayByBerthsAction {
  type: typeof LOAD_NO_LAY_BY_BERTHS
}

export function loadNoLayByBerths(): ILoadNoLayByBerthsAction {
  return {
    type: LOAD_NO_LAY_BY_BERTHS,
  }
}

export const ADD_LAY_BY_BERTHS = 'ADD_LAY_BY_BERTHS'
export interface IAddLayByBerthsAction {
  type: typeof ADD_LAY_BY_BERTHS
  payload: ILayByBerth[]
}
export const addLayByBerths = (layByBerths: ILayByBerth[]): IAddLayByBerthsAction => ({
  type: ADD_LAY_BY_BERTHS,
  payload: layByBerths,
})
