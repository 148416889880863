import { IBerth, BERTH_TYPE, TerminalUuid, BerthUuid } from '../../state/IBerth'
import { MooringType, BERTH_TYPES_TO_DISPLAY_AS_JETTY } from '../../Domain/MooringType'
import { TimeZone } from '../../state/TimeZone'

export interface IBerthJson {
  uuid: BerthUuid
  nameShort: string
  harbourName: string
  terminalName?: string
  terminalUuid?: TerminalUuid
  bollardStart?: number
  bollardEnd?: number
  mooringType: MooringType
  timeZone: TimeZone
}

function toBerth({ uuid, bollardStart: start, bollardEnd: end, nameShort: name, terminalName, terminalUuid, mooringType, timeZone }: IBerthJson): IBerth {
  return {
    type: BERTH_TYPE,
    uuid,
    name,
    terminalName: terminalName ? terminalName : null,
    terminalUuid: terminalUuid ? terminalUuid : null,
    bollards: start && end ? { start, end } : null,
    isJetty: BERTH_TYPES_TO_DISPLAY_AS_JETTY.includes(mooringType),
    timeZone,
  }
}

export function toBerths(json: IBerthJson[]): IBerth[] {
  return json.map(toBerth)
}
