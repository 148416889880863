import React from 'react'
import classnames from 'classnames'
import { InputWrapper } from '../InputWrapper/InputWrapper'
import { MaskedInput, MaskedInputTheme } from '../MaskedInput/MaskedInput'
import styles from './TimeInput.module.scss'
import { TIME_MASK } from '../../../constants'

export type TimeInputProps = {
  label: string
  leadingText?: string
  value?: string
  isDisabled?: boolean
  isRequired?: boolean
  errorMessage?: string
  handleChange: (newValue: string) => void
  handleBlur?: (e: React.FocusEvent) => void
}

const timeInputTheme: MaskedInputTheme = {
  maskedInput: styles.timeInput,
}

export const TimeInput: React.FC<TimeInputProps> = ({ label, leadingText, value, isDisabled, isRequired, errorMessage, handleChange, handleBlur }) => {
  const hasError = !!errorMessage
  const inputWrapperTheme = {
    childContainer: classnames(styles.timeInputContainer, { [styles.error]: hasError }),
  }

  return (
    <InputWrapper label={label} isDisabled={isDisabled} isRequired={isRequired} errorMessage={errorMessage} theme={inputWrapperTheme}>
      {leadingText && <span className={styles.leadingText}>{leadingText}</span>}
      <MaskedInput
        mask={TIME_MASK}
        placeholder="HH:MM"
        value={value}
        isDisabled={isDisabled}
        hasError={hasError}
        handleChange={e => handleChange(e.currentTarget.value)}
        handleBlur={handleBlur}
        theme={timeInputTheme}
      />
    </InputWrapper>
  )
}
