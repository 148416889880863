import { Unlocode } from './../Domain/Port'
import { UserRole } from './../state/UserRoles'
import { Company } from '../state/User'
import jwtDecode from 'jwt-decode'

type JwtToken = {
  'https://pronto.portcalloptimization.nl/authorization': {
    groups: string[]
    roles: UserRole[]
  }
  'https://pronto.portcalloptimization.nl/ports': Unlocode[]
  'https://pronto.portcalloptimization.nl/company': Company['id']
}

export type DecodedToken = {
  groups: string[]
  roles: UserRole[]
  ports: Unlocode[]
  company: Company['id']
} & { [key: string]: unknown }

export const decodeAuthToken = (tokenString: string): DecodedToken => {
  const token = jwtDecode<JwtToken>(tokenString)
  const decoded: DecodedToken = {
    groups: token['https://pronto.portcalloptimization.nl/authorization'].groups,
    roles: token['https://pronto.portcalloptimization.nl/authorization'].roles,
    ports: token['https://pronto.portcalloptimization.nl/ports'],
    company: token['https://pronto.portcalloptimization.nl/company'],
  }

  return decoded
}

export const MOCK_TOKEN_WITH_ALL_ROLES =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJodHRwczovL3Byb250by5wb3J0Y2FsbG9wdGltaXphdGlvbi5ubC9hdXRob3JpemF0aW9uIjp7InJvbGVzIjpbIkFkZFN0YWtlaG9sZGVyc0luVmVzc2VsUGxhbm5lciIsIlRlcm1pbmFsIiwiQWdlbnQiLCJDYXJyaWVyIiwiTGF5YnkgQmVydGggUGxhbm5lciIsIlZlc3NlbCBQbGFubmVyIl19LCJqdGkiOiJmYTI4NTEyMi1lMGMzLTQwZGUtOWJlMy00NGZlN2MxMTM5YjAiLCJpYXQiOjE2MTgzMTg5NjgsImV4cCI6MTYxODMyMjU2OH0.wpQE72vfxIW1Ke9SDF7qogd4OlVrvFm2bLz6aOG0V0I'

export const MOCK_TOKEN_WITH_LAYBY_ROLE =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJodHRwczovL3Byb250by5wb3J0Y2FsbG9wdGltaXphdGlvbi5ubC9hdXRob3JpemF0aW9uIjp7InJvbGVzIjpbIkFkZFN0YWtlaG9sZGVyc0luVmVzc2VsUGxhbm5lciIsIlRlcm1pbmFsIiwiQWdlbnQiLCJDYXJyaWVyIiwiTGF5YnkgQmVydGggUGxhbm5lciJdfSwianRpIjoiZmEyODUxMjItZTBjMy00MGRlLTliZTMtNDRmZTdjMTEzOWIwIiwiaWF0IjoxNjE4MzE4OTY4LCJleHAiOjE2MTgzOTgwNDF9.7pitFi61SMuKtHxi80vYjwGAbcM6yR4XtPOPUDtdWHw'

export const MOCK_TOKEN_WITH_TERMINAL_ROLE = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJodHRwczovL3Byb250by5wb3J0Y2FsbG9wdGltaXphdGlvbi5ubC9hdXRob3JpemF0aW9uIjp7InJvbGVzIjpbIkFkZFN0YWtlaG9sZGVyc0luVmVzc2VsUGxhbm5lciIsIlRlcm1pbmFsIiwiQWdlbnQiLCJDYXJyaWVyIiwiVmVzc2VsIFBsYW5uZXIiXX0sImp0aSI6ImZhMjg1MTIyLWUwYzMtNDBkZS05YmUzLTQ0ZmU3YzExMzliMCIsImlhdCI6MTYxODMxODk2OCwiZXhwIjoxNjE4Mzk4MDYwfQ.TnzyKQG_RX30Ygk0VXPqrKglkq08nF4lAF9g_w1owoA`

export const MOCK_TOKEN_WITH_NO_ROLES = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJodHRwczovL3Byb250by5wb3J0Y2FsbG9wdGltaXphdGlvbi5ubC9hdXRob3JpemF0aW9uIjp7InJvbGVzIjpbXX0sImp0aSI6ImZhMjg1MTIyLWUwYzMtNDBkZS05YmUzLTQ0ZmU3YzExMzliMCIsImlhdCI6MTYxODMxODk2OCwiZXhwIjoxNjE4Mzk4MTcxfQ.Tfx38ulB-ZSahaZ1cocVsqGqyLbulKji5xahD-5TNkY`
