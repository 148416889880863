import { isTerminal } from './../utils/roles'
import { ILayByBerth, LayByBerthRequestBody } from '../Domain/LayByBerth'
import { addLayByBerth, deleteLayByBerths, editLayByBerth, FetchLayByBerthsResult } from '../rest/layByBerths/layByBerths'
import { IAppState } from '../state/IAppState'
import { addBerthVisits, deleteBerthVisits, clearBerthVisits, loadNoBerthVisits } from './berthVisitsActions'
import { VesselPlannerAction, VesselPlannerDispatch } from '../thunk'
import { AppAction } from './AppAction'
import { setBerths } from './berthsActions'
import { fetchBerths } from '../rest/berths/fetchBerths'
import { addBerthVisit, fetchBerthVisits, updateBerthVisitAsync, deleteBerthVisitsAsync } from '../rest/berthVisits/berthVisits'
import { IBerthVisit, BerthVisitId } from '../state/IBerthVisit'
import { dispatchPromiseOfValidated, errorFromResponseStatus, setErrorAction } from '../rest/handleActionPromise'
import { fetchUser } from '../rest/fetchUser'
import { setUserAction } from './setUserAction'
import { isLaybyBerthPlanner, isVesselPlanner } from '../utils/roles'
import { closeBerthVisitEntryModal, openNewBerthVisitModal } from './uiActions'
import { actions as toastrActions } from 'react-redux-toastr'
import { Subject, of, Observable, defer } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { PromiseOfValidated } from '../utils/PromiseOfValidated'
import { BatchSettings } from '../components/BerthVisitForm/BerthVisitForm'
import { isSome, Option, some } from 'fp-ts/es6/Option'
import { fetchStakeholders } from '../rest/stakeholders/fetchStakeholders'
import { setStakeholders } from './stakeholdersActions'
import { fetchLayByBerths } from '../rest/layByBerths/layByBerths'
import { addLayByBerths, clearLayByBerths, loadNoLayByBerths } from './layByBerthsActions'
import { Either, fold } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import { thunkFromAction, withToken } from '../utils/thunkUtils'
import { map as arrayMap } from 'fp-ts/es6/Array'
import { getFeatureToggleFromAuthToken } from '../components/FeatureToggle/FeatureToggle'
import { setError } from './errorActions'
import { ForbiddenError } from '../components/errors/ForbiddenError'

export const submitEditBerthVisitThunk = (berthVisit: IBerthVisit, isEtaShift: boolean): VesselPlannerAction =>
  withToken((token, dispatch) =>
    dispatchPromiseOfValidated(
      dispatch,
      updateBerthVisitAsync(token, berthVisit, isEtaShift).map(berthVisit => {
        dispatch(closeBerthVisitEntryModal())
        dispatch(getReloadBerthVisitsThunk())
        return toastrActions.add({
          type: 'success',
          title: 'Edited berth visit',
          message: `Edited berth visit for ${berthVisit.ship.name || 'Unknown ship name'}`,
        })
      })
    )
  )

export const submitNewLayByBerthThunk = (requestBody: LayByBerthRequestBody): VesselPlannerAction =>
  withToken(async (token, dispatch) => {
    pipe(
      await addLayByBerth(token, requestBody),
      fold(
        () =>
          thunkFromAction(
            toastrActions.add({
              type: 'error',
              title: 'Problem occurred when creating new lay-by berth',
              message: `No lay-by berth has been added. Please, try again later`,
            })
          ),
        () => getReloadLayByBerthsThunk()
      ),
      dispatch
    )
  })

export const submitDeleteLayByBerthsThunk = (layByBerthIds: ILayByBerth['uuid'][]): VesselPlannerAction =>
  withToken(async (token, dispatch) => {
    pipe(
      await deleteLayByBerths(token, layByBerthIds),
      fold(
        () => [
          thunkFromAction(
            toastrActions.add({
              type: 'error',
              title: 'Problem occurred when removing selected lay-by berths',
              message: `No lay-by berths were removed. Please, try again later`,
            })
          ),
        ],
        () => [
          getReloadLayByBerthsThunk(),
          thunkFromAction(
            toastrActions.add({
              type: 'success',
              title: 'Successfully removed',
              message: `Removed ${layByBerthIds.length} lay-by ${layByBerthIds.length === 1 ? 'berth' : 'berths'}`,
            })
          ),
        ]
      ),
      arrayMap(dispatch)
    )
  })

export const submitEditLayByBerthThunk = (layByBerthId: ILayByBerth['uuid'], requestBody: LayByBerthRequestBody): VesselPlannerAction =>
  withToken(async (token, dispatch) => {
    pipe(
      await editLayByBerth(token, layByBerthId, requestBody),
      fold(
        () =>
          thunkFromAction(
            toastrActions.add({
              type: 'error',
              title: 'Problem occurred when updating lay-by berth',
              message: `No lay-by berth has been updated. Please, try again later`,
            })
          ),
        () => getReloadLayByBerthsThunk()
      ),
      dispatch
    )
  })

export const submitNewBerthVisitThunk = (berthVisit: IBerthVisit, isEtaShift: boolean, batchSettings: Option<BatchSettings>): VesselPlannerAction =>
  withToken((token, dispatch) =>
    dispatchPromiseOfValidated(
      dispatch,
      addBerthVisit(token, berthVisit, isEtaShift).map(berthVisit => {
        dispatch(closeBerthVisitEntryModal())
        dispatch(getReloadBerthVisitsThunk())
        isSome(batchSettings) &&
          dispatch(
            openNewBerthVisitModal(
              some({
                isEtaShift,
                preStartCargoMargin: batchSettings.value.preStartCargoMargin,
                preDepartureMargin: batchSettings.value.preDepartureMargin,
              })
            )
          )
        return toastrActions.add({
          type: 'success',
          title: 'Saved berth visit',
          message: `Saved new berth visit for ${berthVisit.ship.name || 'Unknown ship name'}`,
        })
      })
    )
  )

export const deleteBerthVisitsThunk = (ids: BerthVisitId[]): VesselPlannerAction =>
  withToken((token, dispatch) => {
    dispatch(deleteBerthVisits(ids))

    dispatchPromiseOfValidated(
      dispatch,
      deleteBerthVisitsAsync(token, ids).map(() =>
        toastrActions.add({
          type: 'success',
          title: 'Successfully removed',
          message: `Removed ${ids.length} berth ${ids.length === 1 ? 'visit' : 'visits'}`,
        })
      )
    )
  })

export const initializeThunk = (authToken: string): VesselPlannerAction => async dispatch => {
  const featureToggler = getFeatureToggleFromAuthToken(authToken)

  if (!featureToggler([isTerminal])) {
    dispatch(
      setError({
        dialogContents: ForbiddenError,
      })
    )
    return
  }

  const generalThunks = [getUserThunk(authToken), getBerthsThunk(authToken)]
  const terminalThunks = [getStakeholdersThunk(authToken), getReloadBerthVisitsThunk()]
  const layByThunks = [getReloadLayByBerthsThunk()]

  pipe(generalThunks, arrayMap(dispatch))

  if (featureToggler([isVesselPlanner])) {
    pipe(terminalThunks, arrayMap(dispatch))
  } else {
    dispatch(loadNoBerthVisits())
  }

  if (featureToggler([isLaybyBerthPlanner])) {
    pipe(layByThunks, arrayMap(dispatch))
  } else {
    dispatch(loadNoLayByBerths())
  }
}

export const getReloadBerthVisitsThunk = (): VesselPlannerAction => (dispatch, getState: () => IAppState) => {
  const { selectedTimeFilter } = getState().ui.viewAgnostic

  // Reset the list to empty
  dispatch(clearBerthVisits())

  // Refetch the berth visits
  dispatch(getBerthVisitsThunk(selectedTimeFilter, addBerthVisits))
}

export const getReloadLayByBerthsThunk = (): VesselPlannerAction => dispatch => {
  dispatch(clearLayByBerths())
  dispatch(getLayByBerthsThunk())
}

export const getBerthsThunk = (authToken: string): VesselPlannerAction => dispatch => {
  dispatchPromiseOfValidated(dispatch, fetchBerths(authToken).map(setBerths))
}

export const getStakeholdersThunk = (authToken: string): VesselPlannerAction => dispatch => {
  dispatchPromiseOfValidated(dispatch, fetchStakeholders(authToken).map(setStakeholders))
}

export const getBerthVisitsThunk = (timeFilter: number, action: (berthVisits: IBerthVisit[]) => AppAction): VesselPlannerAction =>
  withToken((token, dispatch) => {
    const from = getDateFromTimeFilter(timeFilter)
    // Pass query arguments to berthVisitsQuerySubject Observable
    berthVisitsQuerySubject.next([dispatch, from, token, action])
  })

export const getLayByBerthsThunk = (): VesselPlannerAction => withToken((token, dispatch) => layByBerthsQuerySubject.next([dispatch, token]))

// To prevent race conditions with handling response out of original request order, we use an Observable to only dispatch the fetched berth visits from the last query to the store
type BerthVisitsQuery = [VesselPlannerDispatch, Date, string, (bvs: IBerthVisit[]) => AppAction]
type BerthVisitsResult = [VesselPlannerDispatch, PromiseOfValidated<AppAction, AppAction>]
const berthVisitsQuerySubject = new Subject<BerthVisitsQuery>()

const layByBerthsQuerySubject = new Subject<[VesselPlannerDispatch, string]>()

// Map dispatch and query into Observable of fetched berth visits
const doFetchBerthVisits = ([dispatch, from, authToken, action]: BerthVisitsQuery): Observable<BerthVisitsResult> =>
  of(fetchBerthVisits(authToken, { from }).map(action)).pipe(map(action => [dispatch, action]))

// Filter out the latest result using switchMap
const latestBerthVisits = berthVisitsQuerySubject.pipe(switchMap(doFetchBerthVisits))

// Subscribe to latest berth visits and dispatch to store
latestBerthVisits.subscribe(([dispatch, berthVisitsAction]) => dispatchPromiseOfValidated(dispatch, berthVisitsAction))

const actionFromEither = <A>(result: Either<[number, Error], A>, createAction: (a: A) => AppAction): AppAction =>
  pipe(
    result,
    fold(
      ([status, error]): AppAction => setErrorAction(errorFromResponseStatus(status, error)),
      (data: A): AppAction => createAction(data)
    )
  )

layByBerthsQuerySubject
  .pipe(
    switchMap(([dispatch, authToken]) =>
      defer(() => fetchLayByBerths(authToken)).pipe(map((result): [VesselPlannerDispatch, FetchLayByBerthsResult] => [dispatch, result]))
    )
  )
  .subscribe(([dispatch, result]) => {
    dispatch(actionFromEither(result, addLayByBerths))
  })

export const getUserThunk = (authToken: string): VesselPlannerAction => async dispatch => {
  pipe(
    await fetchUser(authToken),
    fold(
      () =>
        toastrActions.add({
          type: 'error',
          title: 'Problem occurred when fetching user profile',
          message: `Please refresh the app or try again later`,
        }),
      user => setUserAction(user)
    ),
    action => dispatch(action)
  )
}

const getDateFromTimeFilter = (timeFilter: number): Date => {
  const date = new Date()
  date.setHours(date.getHours() - timeFilter)

  return date
}
