import { parseISO } from 'date-fns'
import { Validated } from '../Validated'
import { IDateTime } from './IDateTime'
import { TimeZone } from '../../state/TimeZone'
import { formatDateWithOptionalTimeZone } from './formatDateWithOptionalTimeZone'
import { dateFromSpecification } from './createDateWithOptionalTimeZone'
import { toDateSpecification } from './toDateSpecification'
import { None } from '../strictNull'
import { Option, fold, fromNullable } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'

export const TIME_FORMAT = 'HH:mm'

export function formatDate(date: Date, timeZone: Option<TimeZone>, dateFormat: string) {
  const dateFormatForDateFnsV2 = dateFormat.replace('DD', 'dd')
  return formatDateWithOptionalTimeZone(date, dateFormatForDateFnsV2, timeZone)
}

export function formatTime(date: Date, timeZone: Option<TimeZone>) {
  return formatDateWithOptionalTimeZone(date, TIME_FORMAT, timeZone)
}

export function toDateTime(date: Date, timeZone: Option<TimeZone>, dateFormat: string) {
  return { date: formatDate(date, timeZone, dateFormat), time: formatTime(date, timeZone) }
}

export function formatDateOrNull(date: Date | null, timeZone: Option<TimeZone>, dateFormat: string): string {
  return pipe(
    fromNullable(date),
    fold(
      () => '--/--',
      d => formatDate(d, timeZone, dateFormat)
    )
  )
}

export function formatTimeOrNull(date: Date | null, timeZone: Option<TimeZone>): string {
  return pipe(
    fromNullable(date),
    fold(
      () => '00:00',
      d => formatTime(d, timeZone)
    )
  )
}

export function toDateTimeOrNull(date: Date | null, timeZone: Option<TimeZone>, dateFormat: string): IDateTime {
  return pipe(
    fromNullable(date),
    fold(
      () => IDateTime.empty,
      d => toDateTime(d, timeZone, dateFormat)
    )
  )
}

export const DATE_WRONG_FORMAT = 'DATE_WRONG_FORMAT'
export const TIME_WRONG_FORMAT = 'TIME_WRONG_FORMAT'
export const INVALID_DATE = 'INVALID_DATE'
export type DateParseError = typeof DATE_WRONG_FORMAT | typeof TIME_WRONG_FORMAT | typeof INVALID_DATE

export function validateDateTimeComponent(splitChar: string, dateTimeComponent: string) {
  if (dateTimeComponent.length !== 5) {
    // Two digits for day, two digits for the month, with a slash or colon in between.
    return Validated.error<DateParseError, Date>(DATE_WRONG_FORMAT)
  }

  const dateTimeComponents = dateTimeComponent.split(splitChar)

  if (!dateTimeComponents.every((el: string) => /^\d\d$/.test(el))) {
    return Validated.error<DateParseError, Date>(DATE_WRONG_FORMAT)
  }

  return Validated.ok<DateParseError, number[]>(dateTimeComponents.map(val => parseInt(val, 10)))
}

export function parseLocalDateTimeString(
  date: string,
  time: string,
  now: Date,
  timeZone: TimeZone | None,
  dateFormat: string
): Validated<DateParseError, Date> {
  const validatedDate = validateDateTimeComponent('/', date)
  const validatedTime = validateDateTimeComponent(':', time)
  const timeZoneInfo = fromNullable(timeZone ? timeZone : null)

  if (!validatedDate.isValid()) {
    return validatedDate
  }

  if (!validatedTime.isValid()) {
    return validatedTime
  }

  const dateComponents = validatedDate.value
  const [day, month] = dateFormat === 'MM/DD' ? dateComponents.reverse() : dateComponents
  const timeComponents = validatedTime.value
  const [hours, minutes] = timeComponents

  const possibleYears = [now.getFullYear() - 1, now.getFullYear(), now.getFullYear() + 1]
  const possibleDateTimes = possibleYears.map(year => dateFromSpecification({ year, month, day, hours, minutes, seconds: 0, milliseconds: 0 }, timeZoneInfo))

  if (possibleDateTimes.some(dt => isNaN(dt.valueOf()))) {
    return Validated.error<DateParseError, Date>(INVALID_DATE)
  }

  const dateTime = getBestDateTime(possibleDateTimes, now)

  const dateTimeSpec = toDateSpecification(dateTime, timeZoneInfo)
  const beforeAndAfterCorrectionPairs: Array<[number, number]> = [
    [month, dateTimeSpec.month],
    [day, dateTimeSpec.day],
    [hours, dateTimeSpec.hours],
    [minutes, dateTimeSpec.minutes],
  ]

  const isConsistent = beforeAndAfterCorrectionPairs.every(([before, after]) => before === after)
  if (!isConsistent) {
    return Validated.error<DateParseError, Date>(INVALID_DATE)
  }

  return Validated.ok<DateParseError, Date>(dateTime)
}

// @TODO Might be replaced with `closestTo` from `date-fns`.
function getBestDateTime(dateTimes: Date[], now: Date): Date {
  if (dateTimes.length <= 0) {
    throw new Error('There should be at least one `Date` to choose from')
  }

  const sortedByDistanceToNow = dateTimes.sort((left: Date, right: Date) => {
    const leftDistance = left.valueOf() - now.valueOf()
    const rightDistance = right.valueOf() - now.valueOf()
    return Math.abs(leftDistance) - Math.abs(rightDistance)
  })
  return sortedByDistanceToNow[0]
}

export function parseBackendDateTime(backendDateTime: string): Date {
  // Because it's an ISO string, we can just use `date-fns`:
  return parseISO(backendDateTime)
}

export function formatBackendDateTime(dateTime: Date): string {
  return dateTime.toISOString()
}
