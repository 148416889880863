import React from 'react'
import { FieldAttributes, useField } from 'formik'
import { Select, SelectOption, SelectProps } from './Select'

export type FormikSelectProps<Value> = FieldAttributes<Omit<SelectProps<Value>, 'selectedOption' | 'errorMessage' | 'handleChange'>> &
  Readonly<{
    handleChange?: (option: SelectOption<Value>) => void
  }>
export const FormikSelect = <Value extends {}>(props: FormikSelectProps<Value>) => {
  const [field, meta, helpers] = useField<Value>(props.name)
  const errorMessage = meta.error && meta.touched ? meta.error : undefined
  const selectedOption: SelectOption<Value> | null = props.options.find(selectOption => selectOption.value === field.value) || null

  return (
    <Select
      {...props}
      selectedOption={selectedOption}
      handleChange={selectedOption => {
        helpers.setValue(selectedOption.value)
        if (props.handleChange) {
          props.handleChange(selectedOption)
        }
      }}
      handleBlur={() => helpers.setTouched(true)}
      errorMessage={errorMessage}
    />
  )
}
