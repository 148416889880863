import React from 'react'
import { IBerthVisitTableRow, ILayByBerthTableRow } from '../../selectors/berthVisitTableRow'
import { ShipIdView } from './ShipIdView'
import { LabelledCell } from './LabelledCell'
import { ActivityTimingView, ArrivalLabel, CompleteCargoLabel, DepartureLabel, DateOnlyActivityTimingView, StartCargoLabel } from './ActivityTimingView'
import styles from './BerthVisitTableRow.module.scss'
import { Checkbox } from '../Form/Checkbox/Checkbox'
import { Button } from '../Form/Button/Button'
import { LayByBerthStatus, LAY_BY_STATUS_TEXT, isBooked, isTentativelyBooked } from '../../Domain/LayByBerth'
import classnames from 'classnames'

type BerthVisitTableRowProps = Readonly<{
  berthVisit: IBerthVisitTableRow
  onClick: () => void
  onSelect: (selected: boolean) => void
  selected: boolean
}>

type LayBySlotTableRowProps = Readonly<{ layByBerth: ILayByBerthTableRow; onClick: () => void; onSelect: (selected: boolean) => void; selected: boolean }>

const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()

const LayByStatus: React.FC<{ status: LayByBerthStatus }> = ({ children, status }) => (
  <div className={styles.cellLayByStatus}>
    {children}
    <span className={styles.status}>
      <i
        className={classnames(styles.statusIcon, {
          [styles.statusIconBooked]: isBooked(status),
          [styles.statusIconTentativelyBooked]: isTentativelyBooked(status),
        })}></i>
      <div className={styles.statusText}>{`Lay-by ${LAY_BY_STATUS_TEXT[status]}`}</div>
    </span>
  </div>
)

export const LayBySlotTableRow: React.FC<LayBySlotTableRowProps> = ({ layByBerth, onClick, onSelect, selected }) => {
  const { id, status, berthName, arrivalBerth, berthTerminalName, bollardAft, bollardFore, departureBerth, timeZone } = layByBerth
  return (
    <tr className={styles.tableRow} onClick={onClick}>
      <td className={styles.cellShipName}>
        <div className={styles.cellShipNameInner} data-testid={id}>
          <span onClick={stopPropagation}>
            <LayByStatus status={status}>
              <Checkbox label="" checked={selected} onChange={onSelect} name={`layBySlot[${id}]`} />
            </LayByStatus>
          </span>
          <Button type="button" theme={{ button: styles.editButton, label: styles.editButtonText }} onClick={onClick} text="Edit" />
        </div>
      </td>
      <td>
        <LabelledCell label="IMO" value="--" />
      </td>
      <td>
        <LabelledCell label={'Terminal'} value={berthTerminalName} />
      </td>
      <td>
        <LabelledCell label={'Berth/Jetty'} value={berthName} />
      </td>
      <td>
        <LabelledCell label={'Bollard fore'} value={bollardFore} />
      </td>
      <td>
        <LabelledCell label={'Bollard aft'} value={bollardAft} />
      </td>
      <td>
        <LabelledCell label={'Orientation'} value="--" />
      </td>
      <td>
        <DateOnlyActivityTimingView date={arrivalBerth.date} activityLabel={ArrivalLabel} timeZone={timeZone} />
      </td>
      <td />
      <td />
      <td className={styles.cellDeparture}>
        <DateOnlyActivityTimingView date={new Date(departureBerth.date)} activityLabel={DepartureLabel} timeZone={timeZone} />
      </td>
    </tr>
  )
}

export const BerthVisitTableRow: React.FC<BerthVisitTableRowProps> = ({ berthVisit, onClick, onSelect, selected }) => {
  const {
    id,
    shipId,
    berthTerminalName,
    berthName,
    bollardFore,
    bollardAft,
    orientation,
    arrivalBerth,
    departureBerth,
    startCargo,
    completeCargo,
    timeZone,
    remark,
  } = berthVisit

  return (
    <tr className={styles.tableRow} onClick={onClick}>
      <td className={styles.cellShipName}>
        <div className={styles.cellShipNameInner} data-testid={id}>
          <span onClick={stopPropagation}>
            <Checkbox label={shipId.name || 'Unknown vessel name'} checked={selected} onChange={onSelect} name={`berthVisits[${id}]`} />
          </span>
          <Button type="button" theme={{ button: styles.editButton, label: styles.editButtonText }} onClick={onClick} text="Edit" />
        </div>
      </td>
      <td>
        <ShipIdView shipId={shipId} />
      </td>
      <td>
        <LabelledCell label={'Terminal'} value={berthTerminalName} />
      </td>
      <td>
        <LabelledCell label={'Berth/Jetty'} value={berthName} />
      </td>
      <td>
        <LabelledCell label={'Bollard fore'} value={bollardFore} />
      </td>
      <td>
        <LabelledCell label={'Bollard aft'} value={bollardAft} />
      </td>
      <td>
        <LabelledCell label={'Orientation'} value={orientation} />
      </td>
      <td>
        <ActivityTimingView activityTiming={arrivalBerth} activityLabel={ArrivalLabel} timeZone={timeZone} />
      </td>
      <td>
        <ActivityTimingView activityTiming={startCargo} activityLabel={StartCargoLabel} timeZone={timeZone} />
      </td>
      <td>
        <ActivityTimingView activityTiming={completeCargo} activityLabel={CompleteCargoLabel} timeZone={timeZone} />
      </td>
      <td className={styles.cellDeparture}>
        <ActivityTimingView activityTiming={departureBerth} activityLabel={DepartureLabel} timeZone={timeZone} />
      </td>
      {remark && (
        <td className={styles.cellRemark}>
          <LabelledCell theme={{ label: styles.cellRemarkHeader }} label={'Remark'} value={remark} />
        </td>
      )}
    </tr>
  )
}
