import { BerthVisitId } from './IBerthVisit'
import { Option, fromNullable, some, option, map, getOrElse } from 'fp-ts/es6/Option'
import { ITableRow, TableRowType } from '../selectors/berthVisitTableRow'
import { Ordering } from 'fp-ts/es6/Ordering'
import stringNaturalCompare from 'string-natural-compare'
import { pipe } from 'fp-ts/es6/pipeable'
import { sequenceT } from 'fp-ts/es6/Apply'
import { PrefilledFields } from '../components/BerthVisitForm/BerthVisitForm'

export interface IViewAgnosticUiState {
  showDeepsea: boolean
  showBarges: boolean
  showLayByBerths: boolean
  selectedTimeFilter: number
}

export enum BerthVisitEntryKind {
  New,
  Edit,
}

type NewBerthVisitEntryState = Readonly<{
  kind: BerthVisitEntryKind.New
  prefilledFields: Option<PrefilledFields>
}>

type EditBerthVisitEntryState = Readonly<{
  kind: BerthVisitEntryKind.Edit
  berthVisitId: BerthVisitId
}>

export type BerthVisitEntryState = NewBerthVisitEntryState | EditBerthVisitEntryState

export enum CommonSortMethodType {
  ByBerthNameAsc = 'ByBerthNameAsc',
  ByBerthNameDesc = 'ByBerthNameDesc',
  ByArrivalDateAsc = 'ByArrivalDateAsc',
  ByArrivalDateDesc = 'ByArrivalDateDesc',
}

export enum BerthVisitsSortMethodType {
  ByShipNameAsc = 'ByShipNameAsc',
  ByShipNameDesc = 'ByShipNameDesc',
}

export type AllSortMethodType = CommonSortMethodType | BerthVisitsSortMethodType

type BerthVisitSorter = (bvs: ITableRow[]) => ITableRow[]

export type BerthVisitSorterDescription = Readonly<{
  label: string
  sorter: BerthVisitSorter
}>

type SortComparableValues = Date | string | number
type SortGetter<A, B extends SortComparableValues> = (a: A) => Option<B>
type SortConfig = Readonly<{
  onABeforeB: Ordering
  onAAfterB: Ordering
}>
type Comparator<B> = (b1: B, b2: B) => boolean

// Property getters for ITableRow
const etaGetter: SortGetter<ITableRow, Date> = (a: ITableRow) => fromNullable(a?.arrivalBerth?.date)
const berthNameGetter: SortGetter<ITableRow, string> = (a: ITableRow) => some(a.berthName)
const shipNameGetter: SortGetter<ITableRow, string> = (a: ITableRow) => fromNullable(a.type === TableRowType.BERTH_VISIT ? a.shipId?.name : null)

// Comparators for different values of ITableRow
const stringSmallerThan: Comparator<string> = (a, b) => stringNaturalCompare(a, b) <= 0 // stringNaturalCompare returns a number
const dateSmallerThan: Comparator<Date> = (a, b) => a < b

const sequenceTOption = sequenceT(option)
const sorter = <A, B extends SortComparableValues>(sortGetter: SortGetter<A, B>, comparator: Comparator<B>, sortConfig: SortConfig) => (
  a: A,
  b: A
): Ordering => {
  const equal: Ordering = 0
  const optionA = sortGetter(a)
  const optionB = sortGetter(b)

  return pipe(
    sequenceTOption(optionA, optionB),
    map(([a, b]) => {
      if (a === b) {
        return equal
      }

      return comparator(a, b) ? sortConfig.onABeforeB : sortConfig.onAAfterB
    }),
    getOrElse((): Ordering => equal)
  )
}

const etaSorterNearFar = sorter(etaGetter, dateSmallerThan, { onABeforeB: -1, onAAfterB: 1 })
const etaSorterFarNear = sorter(etaGetter, dateSmallerThan, { onABeforeB: 1, onAAfterB: -1 })
const berthNameAZ = sorter(berthNameGetter, stringSmallerThan, { onABeforeB: -1, onAAfterB: 1 })
const berthNameZA = sorter(berthNameGetter, stringSmallerThan, { onABeforeB: 1, onAAfterB: -1 })
const shipNameAZ = sorter(shipNameGetter, stringSmallerThan, { onABeforeB: -1, onAAfterB: 1 })
const shipNameZA = sorter(shipNameGetter, stringSmallerThan, { onABeforeB: 1, onAAfterB: -1 })

export const CommonSortMethods: Record<CommonSortMethodType, BerthVisitSorterDescription> = {
  [CommonSortMethodType.ByBerthNameAsc]: {
    label: 'Berth name A-Z',
    sorter: (bvs: ITableRow[]) => bvs.sort(berthNameAZ),
  },
  [CommonSortMethodType.ByBerthNameDesc]: {
    label: 'Berth name Z-A',
    sorter: (bvs: ITableRow[]) => bvs.sort(berthNameZA),
  },
  [CommonSortMethodType.ByArrivalDateAsc]: {
    label: 'ETA closest to now',
    sorter: (bvs: ITableRow[]) => bvs.sort(etaSorterNearFar),
  },
  [CommonSortMethodType.ByArrivalDateDesc]: {
    label: 'ETA farthest from now',
    sorter: (bvs: ITableRow[]) => bvs.sort(etaSorterFarNear),
  },
}

export const BerthVisitsSortMethods: Record<BerthVisitsSortMethodType, BerthVisitSorterDescription> = {
  [BerthVisitsSortMethodType.ByShipNameAsc]: {
    label: 'Ship name A-Z',
    sorter: (bvs: ITableRow[]) => bvs.sort(shipNameAZ),
  },
  [BerthVisitsSortMethodType.ByShipNameDesc]: {
    label: 'Ship name Z-A',
    sorter: (bvs: ITableRow[]) => bvs.sort(shipNameZA),
  },
}

export const AllSortMethods: Record<AllSortMethodType, BerthVisitSorterDescription> = { ...CommonSortMethods, ...BerthVisitsSortMethods }

export interface IUIState {
  viewAgnostic: IViewAgnosticUiState
  berthVisitEntryModal: Option<BerthVisitEntryState>
  sortMethod: AllSortMethodType
}
