import React from 'react'
import { createContext, useContext } from 'react'
import { LocaleId } from '../state/User'
import { Option, fromNullable, getOrElse, chain } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'

type LocalisationProviderProps = Readonly<{
  locale: Option<string>
}>

const knownLocalesToDateFormat: Record<LocaleId, string> = {
  'en-US': 'MM/DD',
  default: 'DD/MM'
}

const LocalisationContext = createContext({
  dateFormat: knownLocalesToDateFormat.default
})

export const useLocalisation = () => useContext(LocalisationContext)

const getDateFormat = (locale: Option<string>): string => {
  return pipe(
    locale,
    chain(loc => fromNullable(knownLocalesToDateFormat[loc])),
    getOrElse(() => knownLocalesToDateFormat.default)
  )
}

export const LocalisationProvider: React.FC<LocalisationProviderProps> = ({ locale, children }) => (
  <LocalisationContext.Provider value={{ dateFormat: getDateFormat(locale) }}>{children}</LocalisationContext.Provider>
)
