export const LOGIN = 'LOGIN'

export interface ILoginAction {
  type: typeof LOGIN
  authToken: string
}

export function login(authToken: string): ILoginAction {
  return {
    type: LOGIN,
    authToken
  }
}

export const LOGOUT = 'LOGOUT'

export interface ILogoutAction {
  type: typeof LOGOUT
}

export function logout(): ILogoutAction {
  return {
    type: LOGOUT
  }
}
