import React from 'react'
import styles from './BerthVisitFormInner.module.scss'
import { renderEither } from '../../utils/rendering'
import { TextInput } from '../Form/TextInput/TextInput'
import { shipIdToDescription } from '../../state/IShip'
import { StandardOptionList } from '../Form/Select/StandardOptionList'
import { TimeInput } from '../Form/TimeInput/TimeInput'
import { ButtonContainer } from '../ButtonContainer/ButtonContainer'
import { useDuration } from './useDuration'
import { IBerthVisitFormState } from './BerthVisitForm'
import { ITerminalWithBerths, IBerth } from '../../state/IBerth'
import { SelectOption } from '../Form/Select/Select'
import { Orientation, orientations } from '../../state/Orientation'
import { Button } from '../Form/Button/Button'
import { getFormFieldsHelper } from '../Form/shared/getFormFieldsHelper'
import { getFormikShipSelectionFieldHelper } from '../ShipSelection/getFormikShipSelectionFieldHelper'
import { Checkbox } from '../Form/Checkbox/Checkbox'
import { TimeZone } from '../../state/TimeZone'
import { Option } from 'fp-ts/lib/Option'
import { Tooltip, PopupSide } from '../Tooltip/Tooltip'
import { useBatchEntry } from './useBatchEntry'
import { IStakeholder, StakeholderId } from '../../state/IStakeholder'
import { useMixpanel } from '../../context/MixpanelContext'
import { toOptions } from '../Form/Select/toOptions'
import { isAllowedToAssignStakeholders } from '../../utils/roles'
import { FeatureToggle } from '../FeatureToggle/FeatureToggle'
import { identity } from 'fp-ts/es6/function'

const { FormikSelect, FormikMultiSelect, FormikTimeInput, FormikTextAreaInput, FormikDateTimeInputWithActual, FormikTextInput } = getFormFieldsHelper<
  IBerthVisitFormState
>()
const { FormikShipSelection } = getFormikShipSelectionFieldHelper<IBerthVisitFormState>()

const REMARK_FIELD_ROWS = 4
const REMARK_FIELD_MAX_LENGTH = 280
const REMARK_FIELD_PLACEHOLDER_TEXT = 'Optional note for berth visit'

type BerthVisitFormInnerProps = Readonly<{
  isEditing: boolean
  values: IBerthVisitFormState
  terminalsWithBerths: ITerminalWithBerths[]
  stakeholders: IStakeholder[]
  timeZone: Option<TimeZone>
  dateFormat: string
  setFieldValue: <Key extends keyof IBerthVisitFormState, Value extends IBerthVisitFormState[Key]>(key: Key, value: Value) => void
}>

function BerthVisitFormInner({ isEditing, setFieldValue, terminalsWithBerths, stakeholders, values, dateFormat, timeZone }: BerthVisitFormInnerProps) {
  const { trackEvent } = useMixpanel()
  const { duration, setArrival, setDuration, setDeparture } = useDuration(values.arrival, values.departure, timeZone, dateFormat, setFieldValue)
  useBatchEntry(
    values.arrival,
    values.startCargo,
    values.completeCargo,
    values.departure,
    values.preStartCargoMargin,
    values.preDepartureMargin,
    timeZone,
    dateFormat,
    setFieldValue
  )

  const orientationOptions: SelectOption<Orientation>[] = toOptions(orientations, identity, identity)
  const terminalOptions: SelectOption<ITerminalWithBerths>[] = toOptions(terminalsWithBerths, identity, t => t.terminalName || 'No Terminal Name')
  const berthOptions: SelectOption<IBerth>[] = values.terminal ? toOptions(values.terminal.berths, identity, berth => berth.name) : []
  const stakeholderOptions: SelectOption<StakeholderId>[] = toOptions(
    stakeholders,
    s => s.id,
    s => s.name
  )

  return (
    <>
      <div className={styles.form}>
        <div className={styles.settings}>
          <div className={styles.title}>Settings</div>
          <Checkbox
            checked={values.isEtaShift}
            onChange={isEtaShift => {
              if (isEtaShift) {
                trackEvent('Insert vessel in existing planning - on')
              }
              setFieldValue('isEtaShift', isEtaShift)
            }}
            name="isEtaShift"
            label={
              <>
                Insert vessel in existing planning
                <Tooltip side={PopupSide.TopLeft}>All future planned berth visits will be automatically moved to avoid overlap.</Tooltip>
              </>
            }
          />
          {!isEditing && (
            <div className={styles.batchEntrySettings}>
              <Checkbox
                label={
                  <>
                    Allow batch entry
                    <Tooltip side={PopupSide.TopCenter}>
                      To help you with a faster batch entry, we can pre-fill the following fields. Please choose a time delta for each time event.
                    </Tooltip>
                  </>
                }
                name="isBatchEntry"
                checked={values.isBatchEntry}
                onChange={newValue => {
                  if (!newValue) {
                    setFieldValue('preStartCargoMargin', '')
                    setFieldValue('preDepartureMargin', '')
                  } else {
                    trackEvent('Allow batch entry - on')
                  }

                  setFieldValue('isBatchEntry', newValue)
                }}
              />
              <div className={styles.batchPreFillFields}>
                <FormikTimeInput label="Pre-fill ETS" leadingText="ETA +" name="preStartCargoMargin" isDisabled={!values.isBatchEntry} />
                <FormikTimeInput label="Pre-fill ETC" leadingText="ETD -" name="preDepartureMargin" isDisabled={!values.isBatchEntry} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.bertVisitEntry}>
          <div className={styles.formColumn}>
            {renderEither(
              isEditing,
              <TextInput label="Ship" value={values.vessel ? values.vessel.name : ''} isDisabled />,
              <FormikShipSelection name="vessel" />
            )}
            <TextInput label="IMO/Other" value={values.vessel ? shipIdToDescription(values.vessel) : ''} isDisabled />
            {/* Todo: when removing this feature toggle the tests needs to be updated: src/components/BerthVisitForm/BerthVisitForm.test.tsx -- mocked user role can be removed */}
            <FeatureToggle checks={[isAllowedToAssignStakeholders]}>
              <FormikMultiSelect name="stakeholderIds" label="Stakeholders" options={stakeholderOptions} getKey={option => option.value} />
            </FeatureToggle>
          </div>
          <div className={styles.formColumn}>
            <FormikSelect
              name="terminal"
              label="Terminal"
              options={terminalOptions}
              handleChange={() => {
                setFieldValue('berth', null)
              }}
              getKey={option => (option.value.terminalUuid ? option.value.terminalUuid : '')}>
              {props => <StandardOptionList {...props} />}
            </FormikSelect>
            <FormikSelect name="berth" label="Berth" options={berthOptions} getKey={option => option.value.uuid} isDisabled={!values.terminal}>
              {props => <StandardOptionList {...props} />}
            </FormikSelect>
            <FormikTextInput name="bollardsStart" label="Bollards fore" type="number" isDisabled={!values.berth?.bollards} />
            <FormikTextInput name="bollardsEnd" label="Bollards aft." type="number" isDisabled={!values.berth?.bollards} />
            <FormikSelect name="orientation" label="Orientation" options={orientationOptions} getKey={option => option.value}>
              {props => <StandardOptionList {...props} />}
            </FormikSelect>
          </div>
          <div className={styles.formColumn}>
            <FormikDateTimeInputWithActual
              name="arrival"
              label="Arrival"
              dateFormat={dateFormat}
              toggleLabelOffText="ETA"
              toggleLabelOnText="ATA"
              handleChange={setArrival}
            />
            <TimeInput handleChange={setDuration} value={duration} label="Alongside duration" />
            <FormikDateTimeInputWithActual name="startCargo" label="Start Cargo" dateFormat={dateFormat} toggleLabelOffText="ETS" toggleLabelOnText="ATS" />
            <FormikDateTimeInputWithActual
              name="completeCargo"
              label="Complete Cargo"
              dateFormat={dateFormat}
              toggleLabelOffText="ETC"
              toggleLabelOnText="ATC"
            />
            <FormikDateTimeInputWithActual
              name="departure"
              label="Departure"
              dateFormat={dateFormat}
              toggleLabelOffText="ETD"
              toggleLabelOnText="ATD"
              handleChange={setDeparture}
            />
          </div>
          <div className={styles.remarkField}>
            <FormikTextAreaInput
              name="remark"
              label="Remark"
              rows={REMARK_FIELD_ROWS}
              maxLength={REMARK_FIELD_MAX_LENGTH}
              placeholder={REMARK_FIELD_PLACEHOLDER_TEXT}
            />
          </div>
        </div>
      </div>
      <ButtonContainer>
        <Button text="Save berth visit" type="submit" />
      </ButtonContainer>
    </>
  )
}

export default BerthVisitFormInner
