import React from 'react'
import { Input } from '../Input/Input'
import { InputWrapper } from '../InputWrapper/InputWrapper'

export type TextInputProps = Readonly<{
  id?: string
  type?: string
  label: string
  name?: string
  defaultValue?: string
  value?: string
  placeholder?: string
  isRequired?: boolean
  isDisabled?: boolean
  errorMessage?: string
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}>

export const TextInput: React.FC<TextInputProps> = ({
  id,
  type,
  label,
  name,
  defaultValue,
  value,
  placeholder,
  isRequired,
  isDisabled,
  errorMessage,
  handleChange,
  handleBlur,
}) => (
  <InputWrapper label={label} isRequired={isRequired} isDisabled={isDisabled} errorMessage={errorMessage}>
    <Input
      id={id}
      type={type}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      name={name}
      isDisabled={isDisabled}
      hasError={!!errorMessage}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />
  </InputWrapper>
)
