import { useState, useEffect, useRef } from 'react'
import { DateTime } from '../Form/DateTimeInput/DateTimeInput'
import { IBerthVisitFormState } from './BerthVisitForm'
import { toDateTime } from '../../utils/dates/dates'
import { Option } from 'fp-ts/es6/Option'
import { isRight } from 'fp-ts/es6/Either'
import { TimeZone } from '../../state/TimeZone'
import { pipe } from 'fp-ts/es6/pipeable'
import { addHours, addMinutes, differenceInMinutes } from 'date-fns'
import differenceInHours from 'date-fns/differenceInHours'
import { toDate } from './toDate'
import { toTimeComponents } from './toTimeComponents'

const padLeft = (input: string | number, padding: string, minChars: number) => input.toString().padStart(minChars, padding)

export const useDuration = (
  arr: DateTime,
  dep: DateTime,
  timeZone: Option<TimeZone>,
  dateFormat: string,
  setFieldValue: <Key extends keyof IBerthVisitFormState, Value extends IBerthVisitFormState[Key]>(key: Key, value: Value) => void
) => {
  // Store previous values
  const prevArrival = useRef<DateTime | undefined>(undefined)
  const prevDuration = useRef<string | undefined>(undefined)
  const prevDeparture = useRef<DateTime | undefined>(undefined)

  // State for current values
  const [arrival, setArrival] = useState<DateTime>(arr)
  const [duration, setDuration] = useState<string | undefined>(undefined)
  const [departure, setDeparture] = useState<DateTime>(dep)

  useEffect(() => {
    const eitherArrivalDate = toDate(arrival, timeZone, dateFormat)
    const eitherDepartureDate = toDate(departure, timeZone, dateFormat)

    if (isRight(eitherArrivalDate) && duration && (prevDuration.current !== duration || prevArrival.current !== arrival)) {
      // Arrival or duration field was updated
      prevDuration.current = duration
      prevArrival.current = arrival

      // Parse both date/time components are valid for processing
      const durationTimeComponents = toTimeComponents(duration)

      // Check if both are valid for processing
      if (isRight(durationTimeComponents)) {
        const validDate = eitherArrivalDate.right
        // Calculate duration offset
        const newDate = pipe(
          validDate,
          date => addHours(date, durationTimeComponents.right.hours),
          date => addMinutes(date, durationTimeComponents.right.minutes)
        )

        // Set departure field with offset
        setFieldValue('departure', { ...toDateTime(newDate, timeZone, dateFormat), isActual: dep ? dep.isActual : false })
      }
    }

    if (isRight(eitherArrivalDate) && isRight(eitherDepartureDate) && prevDeparture.current !== departure) {
      // Departure field was updated
      prevDeparture.current = departure

      const hours = differenceInHours(eitherDepartureDate.right, eitherArrivalDate.right)
      const minutes = differenceInMinutes(eitherDepartureDate.right, eitherArrivalDate.right) % 60

      if (hours >= 0 && hours < 100 && minutes >= 0) {
        setDuration(`${padLeft(hours, '0', 2)}:${padLeft(minutes, '0', 2)}`)
      } else {
        setDuration(undefined)
      }
    }
  }, [arrival, duration, departure, dateFormat, dep, setFieldValue, timeZone])

  // Arrival and departure are handled by Formik state, only expose duration
  return { duration, setDuration, setArrival, setDeparture }
}
