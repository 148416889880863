import { getFieldDescription, ValidationField } from './ValidationField'
import { Bollards } from '../Bollards'

export const ERROR_NO_SHIP_SELECTED = 'ERROR_NO_SHIP_SELECTED'
export const ERROR_NO_BERTH_SELECTED = 'ERROR_NO_BERTH_SELECTED'
export const ERROR_BERTH_NOT_EMPTY = 'ERROR_BERTH_NOT_EMPTY'
export const ERROR_NO_TERMINAL_SELECTED = 'ERROR_NO_TERMINAL_SELECTED'
export const ERROR_TERMINAL_NOT_EMPTY = 'ERROR_TERMINAL_NOT_EMPTY'
export const ERROR_BOLLARD_ARE_EQUAL = 'ERROR_BOLLARD_ARE_EQUAL'
export const ERROR_BOLLARD_NOT_IN_BERTH_RANGE = 'ERROR_BOLLARD_NOT_IN_BERTH_RANGE'
export const ERROR_NOT_A_DATE = 'ERROR_NOT_A_DATE'
export const ERROR_FORMER_NOT_BEFORE_LATTER = 'ERROR_FORMER_NOT_BEFORE_LATTER'
export const ERROR_REQUIRED = 'ERROR_REQUIRED'
export const ERROR_REQUIRED_ALL_OF = 'ERROR_REQUIRED_ALL_OF'
export const ERROR_AT_LEAST_ONE_DATE_IS_REQUIRED = 'ERROR_AT_LEAST_ONE_DATE_IS_REQUIRED'

interface ISingleFieldValidationError {
  type:
    | typeof ERROR_NO_SHIP_SELECTED
    | typeof ERROR_NOT_A_DATE
    | typeof ERROR_NO_BERTH_SELECTED
    | typeof ERROR_BERTH_NOT_EMPTY
    | typeof ERROR_NO_TERMINAL_SELECTED
    | typeof ERROR_TERMINAL_NOT_EMPTY
    | typeof ERROR_REQUIRED
    | typeof ERROR_BOLLARD_ARE_EQUAL
  field: ValidationField
}

interface IFieldValidationErrorWithExtraValue<Value> {
  type: typeof ERROR_BOLLARD_NOT_IN_BERTH_RANGE
  field: ValidationField
  value: Value
}

interface IDoubleFieldValidationError {
  type: typeof ERROR_FORMER_NOT_BEFORE_LATTER
  former: ValidationField
  latter: ValidationField
}

interface IMultipleFieldValidationError {
  type: typeof ERROR_REQUIRED_ALL_OF | typeof ERROR_AT_LEAST_ONE_DATE_IS_REQUIRED
  fields: ValidationField[]
}

type BollardNotInBerthRange = IFieldValidationErrorWithExtraValue<Exclude<Bollards, null>>

export type ValidationError = ISingleFieldValidationError | IDoubleFieldValidationError | IMultipleFieldValidationError | BollardNotInBerthRange

export function getErrorDescription(error: ValidationError) {
  switch (error.type) {
    case ERROR_REQUIRED:
      return `${getFieldDescription(error.field)} is required`
    case ERROR_FORMER_NOT_BEFORE_LATTER:
      return `${getFieldDescription(error.former)} should come before ${getFieldDescription(error.latter)}`
    case ERROR_NO_SHIP_SELECTED:
      return `${getFieldDescription(error.field)}: no ship is selected`
    case ERROR_NO_BERTH_SELECTED:
    case ERROR_NO_TERMINAL_SELECTED:
      return `${getFieldDescription(error.field)}: no berth or terminal selected`
    case ERROR_BERTH_NOT_EMPTY:
      return `${getFieldDescription(error.field)}: a terminal was selected but berth is not empty`
    case ERROR_TERMINAL_NOT_EMPTY:
      return `${getFieldDescription(error.field)}: a berth was selected but terminal is not empty`
    case ERROR_BOLLARD_ARE_EQUAL:
      return `${getFieldDescription(error.field)}: start and end bollards can not be the same`
    case ERROR_BOLLARD_NOT_IN_BERTH_RANGE:
      return `${getFieldDescription(error.field)}: bollard should be betweeen ${error.value.start} and ${error.value.end}`
    case ERROR_NOT_A_DATE:
      return `${getFieldDescription(error.field)}: not a valid date`
    case ERROR_AT_LEAST_ONE_DATE_IS_REQUIRED:
      return `Either ${error.fields.map(getFieldDescription).join(' or ')} is required`
    case ERROR_REQUIRED_ALL_OF:
      return `When either ${error.fields.map(getFieldDescription).join(' or ')} is defined, the other field(s) need to be defined as well`
    default:
      const exhaustive: never = error
      throw new Error(exhaustive)
  }
}

export function fieldsInvolved(error: ValidationError): ValidationField[] {
  switch (error.type) {
    // ISingleFieldValidationError:
    case ERROR_NO_SHIP_SELECTED:
    case ERROR_NOT_A_DATE:
    case ERROR_NO_BERTH_SELECTED:
    case ERROR_NO_TERMINAL_SELECTED:
    case ERROR_REQUIRED:
    case ERROR_BOLLARD_ARE_EQUAL:
    case ERROR_BERTH_NOT_EMPTY:
    case ERROR_TERMINAL_NOT_EMPTY:
    case ERROR_BOLLARD_NOT_IN_BERTH_RANGE:
      return [error.field]

    // IMultipleFieldValidationError:
    case ERROR_FORMER_NOT_BEFORE_LATTER:
      return [error.former, error.latter]

    // IMultipleFieldValidationError:
    case ERROR_REQUIRED_ALL_OF:
    case ERROR_AT_LEAST_ONE_DATE_IS_REQUIRED:
      return error.fields

    default:
      const exhaustive: never = error
      throw new Error(exhaustive)
  }
}
