import { Bollards } from './Bollards'
import { TimeZone } from './TimeZone'
import { BrandedCovariant } from '../utils/branded'

export type BerthUuid = BrandedCovariant<string, 'berthUuid'>
export type TerminalUuid = BrandedCovariant<string, 'terminalUuid'>

export const BERTH_TYPE = 'BERTH_TYPE'
export const TERMINAL_TYPE = 'TERMINAL_TYPE'

export type IBerth = {
  type: typeof BERTH_TYPE
  uuid: BerthUuid
  name: string
  bollards: Bollards
  isJetty: boolean
  terminalName: string | null
  terminalUuid: TerminalUuid | null
  timeZone: TimeZone
}

export type ITerminalWithBerths = {
  type: typeof TERMINAL_TYPE
  terminalName: string | null
  terminalUuid: TerminalUuid | null
  timeZone: TimeZone
  berths: IBerth[]
}

export type ITerminal = Omit<ITerminalWithBerths, 'berths'>

export const getBerthByBerthUuid = (berthUuid: BerthUuid | null, terminalsWithBerths: ITerminalWithBerths[]): IBerth | null => {
  if (!berthUuid) {
    return null
  }

  const terminalWithMatchingBerth = terminalsWithBerths.find(terminal => findBerthByBerthUuid(berthUuid, terminal.berths))
  return terminalWithMatchingBerth ? findBerthByBerthUuid(berthUuid, terminalWithMatchingBerth.berths) : null
}

const findBerthByBerthUuid = (berthUuid: BerthUuid, berths: IBerth[]): IBerth | null => {
  return berths.find(berth => berth.uuid === berthUuid) || null
}
