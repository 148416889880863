import { IBerthVisitDate } from '../../state/IBerthVisit'
import { IDateTime } from './IDateTime'
import { formatDateOrNull, formatTimeOrNull } from './dates'
import { TimeZone } from '../../state/TimeZone'
import { Option } from 'fp-ts/es6/Option'

export function formatBerthVisitDate(berthVisitDate: IBerthVisitDate | null, timeZone: Option<TimeZone>, dateFormat: string): IDateTime {
  const date: Date | null = berthVisitDate && berthVisitDate.date

  return {
    date: formatDateOrNull(date, timeZone, dateFormat),
    time: formatTimeOrNull(date, timeZone)
  }
}
