import { ValidationField } from '../../state/Form/ValidationField'
import { DateTime } from '../Form/DateTimeInput/DateTimeInput'
import { IBerthVisitDate } from '../../state/IBerthVisit'
import { isValidBerthVisitDateOrEmpty } from '../../state/Form/validation'
import { TimeZone } from '../../state/TimeZone'
import { None } from '../../utils/strictNull'

export const dateTimeToBerthVisitDate = (
  field: ValidationField,
  { date, time, isActual }: DateTime,
  timeZone: TimeZone | None,
  dateFormat: string
): IBerthVisitDate | null => {
  const validation = isValidBerthVisitDateOrEmpty(field, { date, time }, isActual, new Date(), timeZone, dateFormat)

  return validation.type === 'ok' && validation.value ? validation.value : null
}
