import { IBerthVisit, IBerthVisitDate } from '../../state/IBerthVisit'
import { ITerminalWithBerths } from '../../state/IBerth'
import { DateTime, emptyDateTime } from '../Form/DateTimeInput/DateTimeInput'
import { IBerthVisitFormState } from './BerthVisitForm'
import { formatBerthVisitDate } from '../../utils/dates/berthVisitDate'
import { fromNullable, Option } from 'fp-ts/es6/Option'
import { TimeZone } from '../../state/TimeZone'

export const berthVisitToBerthVisitFormState = (berthVisit: IBerthVisit, terminals: ITerminalWithBerths[], dateFormat: string): IBerthVisitFormState => {
  const { berthUuid } = berthVisit
  const terminal: ITerminalWithBerths | undefined = terminals.find(t =>
    berthUuid ? t.berths.find(b => b.uuid === berthVisit.berthUuid) : t.terminalUuid === berthVisit.terminalUuid
  )
  const berth = berthUuid ? terminal?.berths.find(b => b.uuid === berthVisit.berthUuid) : null

  const timeZone: Option<TimeZone> = fromNullable(berth?.timeZone || terminal?.timeZone)
  const arrival = berthVisitDateToDateTime(berthVisit.arrivalBerth, timeZone, dateFormat)
  const startCargo = berthVisitDateToDateTime(berthVisit.startCargo, timeZone, dateFormat)
  const completeCargo = berthVisitDateToDateTime(berthVisit.completeCargo, timeZone, dateFormat)
  const departure = berthVisitDateToDateTime(berthVisit.departureBerth, timeZone, dateFormat)

  return {
    id: berthVisit.id,
    vessel: berthVisit.ship,
    stakeholderIds: berthVisit.stakeholderIds,
    terminal: terminal || null,
    berth: berth || null,
    bollardsStart: berthVisit.bollards?.start || null,
    bollardsEnd: berthVisit.bollards?.end || null,
    orientation: berthVisit.orientation,
    arrival,
    startCargo,
    completeCargo,
    departure,
    remark: berthVisit.remark,
    isBatchEntry: false,
    isEtaShift: false,
    preStartCargoMargin: '',
    preDepartureMargin: '',
  }
}

const berthVisitDateToDateTime = (berthVisitDate: IBerthVisitDate | null, timeZone: Option<TimeZone>, dateFormat: string): DateTime =>
  berthVisitDate ? { ...formatBerthVisitDate(berthVisitDate, timeZone, dateFormat), isActual: berthVisitDate.isActual } : emptyDateTime
