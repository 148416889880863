import * as React from 'react'
import { ErrorHeader } from '../Dialog/ErrorHeader'
import { ErrorFooter } from '../Dialog/ErrorFooter'
import { Button } from '../Form/Button/Button'
import { SUPPORT_EMAIL } from '../../constants'
import { useAuth } from '../../context/Auth0Context'

export const ForbiddenError = () => {
  const { authUtils } = useAuth()

  return (
    <>
      <ErrorHeader>Not allowed</ErrorHeader>
      <p>
        You don't have the right permissions to use this application. Your account may be configured incorrectly. To be able to solve this for you, we kindly
        ask you to contact us through <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
      </p>
      <ErrorFooter>
        <Button type="button" onClick={authUtils.logout} text="Logout" />
      </ErrorFooter>
    </>
  )
}
