export const UI_TOGGLE_SHOW_BARGES = 'UI_TOGGLE_SHOW_BARGES'
export const UI_TOGGLE_SHOW_DEEPSEA = 'UI_TOGGLE_SHOW_DEEPSEA'
export const UI_TOGGLE_SHOW_LAY_BY_BERTHS = 'UI_TOGGLE_SHOW_LAY_BY_BERTHS'
export const UI_CHANGE_SELECTED_TIME_FILTER = 'UI_CHANGE_SELECTED_TIME_FILTER'

export interface IToggleShowBargesAction {
  type: typeof UI_TOGGLE_SHOW_BARGES
}

export interface IToggleShowDeepseaAction {
  type: typeof UI_TOGGLE_SHOW_DEEPSEA
}

export interface IToggleShowLayByBerthsAction {
  type: typeof UI_TOGGLE_SHOW_LAY_BY_BERTHS
}
export interface IChangeSelectedTimeFilterAction {
  type: typeof UI_CHANGE_SELECTED_TIME_FILTER
  numberOfHours: number
}

export const toggleShowBarges = (): IToggleShowBargesAction => ({
  type: UI_TOGGLE_SHOW_BARGES,
})

export const toggleShowDeepsea = (): IToggleShowDeepseaAction => ({
  type: UI_TOGGLE_SHOW_DEEPSEA,
})

export const toggleShowLayByBerths = (): IToggleShowLayByBerthsAction => ({
  type: UI_TOGGLE_SHOW_LAY_BY_BERTHS,
})

export const changeSelectedTimeFilter = (numberOfHours: number): IChangeSelectedTimeFilterAction => ({
  type: UI_CHANGE_SELECTED_TIME_FILTER,
  numberOfHours,
})
