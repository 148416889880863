import { Auth0Client } from '@auth0/auth0-spa-js'
import { AuthToken, AuthUtils, HandleAuthCallbackResult, IdToken } from './auth'

// Extract auth0 helper utilities
export const getAuth0Utils = (client: Auth0Client): AuthUtils => {
  return {
    login: () =>
      client.loginWithRedirect({
        // force re-login
        max_age: 0,
      }),
    logout: () =>
      client.logout({
        returnTo: window.location.origin,
      }),
    getToken: async () => (await client.getTokenSilently()) as AuthToken,
    // eslint-disable-next-line no-underscore-dangle
    getIdToken: async () => (await client.getIdTokenClaims().then(token => token.__raw)) as IdToken,
    handleAuthCallback: async (url: string): Promise<HandleAuthCallbackResult> => {
      // Only handle callback when there are params to parse
      if (url.includes('code=') && url.includes('state=')) {
        const authResult = await client.handleRedirectCallback()

        return { type: 'RESULT', result: authResult }
      }

      return { type: 'NO_RESULT' }
    },
    isAuthenticated: () => client.isAuthenticated(),
  }
}
