import { IShipId } from '../IShip'
import { BerthVisitId, IBerthVisit } from '../IBerthVisit'
import { ValidationError } from './ValidationError'
import { FIELD_COMPLETE_CARGO, FIELD_START_CARGO, FIELD_ARRIVAL_BERTH, FIELD_DEPARTURE_BERTH } from './ValidationField'
import { Validated, ValidatedOf } from '../../utils/Validated'
import * as Validation from './validation'
import { IBerth, ITerminalWithBerths, getBerthByBerthUuid, TerminalUuid, BerthUuid } from '../IBerth'
import { IDateTime } from '../../utils/dates/IDateTime'
import { Orientation } from '../Orientation'
import { Bollards } from '../Bollards'
import { none } from '../../utils/strictNull'
import { StakeholderId } from '../IStakeholder'

/**
 * This interface represents the values in the form for a
 * new `IBerthVisit` when editing or creating.
 */
export interface IBerthVisitForm {
  id: BerthVisitId
  ship: IShipId | null
  berthUuid: BerthUuid | null
  terminalUuid: TerminalUuid | null
  orientation: Orientation
  bollards: Bollards
  arrivalBerth: IDateTime
  arrivalBerthIsActual: boolean
  startCargo: IDateTime
  startCargoIsActual: boolean
  completeCargo: IDateTime
  completeCargoIsActual: boolean
  departureBerth: IDateTime
  departureBerthIsActual: boolean
  stakeholderIds: StakeholderId[]
  remark: string | null
}

export function isValidBerthVisit(
  form: IBerthVisitForm,
  terminalsWithBerths: ITerminalWithBerths[],
  now: Date,
  dateFormat: string
): Validated<ValidationError, IBerthVisit> {
  const berth: IBerth | null = getBerthByBerthUuid(form.berthUuid, terminalsWithBerths)
  const timeZone = (berth && berth.timeZone) || none
  const validatedFields: ValidatedOf<ValidationError, IBerthVisit> = {
    id: Validation.ok('id', form.id),
    ship: Validation.isValidShip(form.ship),
    berthUuid: Validation.isValidBerthUuid(form.terminalUuid, berth),
    terminalUuid: Validation.isValidTerminalUuid(form.terminalUuid, form.berthUuid, terminalsWithBerths),
    bollards: Validation.hasValidBollards(form.bollards, berth),
    orientation: Validation.ok('orientation', form.orientation),
    arrivalBerth: Validation.isValidBerthVisitDateOrEmpty(FIELD_ARRIVAL_BERTH, form.arrivalBerth, form.arrivalBerthIsActual, now, timeZone, dateFormat),
    startCargo: Validation.isValidBerthVisitDateOrEmpty(FIELD_START_CARGO, form.startCargo, form.startCargoIsActual, now, timeZone, dateFormat),
    completeCargo: Validation.isValidBerthVisitDateOrEmpty(FIELD_COMPLETE_CARGO, form.completeCargo, form.completeCargoIsActual, now, timeZone, dateFormat),
    departureBerth: Validation.isValidBerthVisitDateOrEmpty(FIELD_DEPARTURE_BERTH, form.departureBerth, form.departureBerthIsActual, now, timeZone, dateFormat),
    stakeholderIds: Validation.ok('stakeholderIds', form.stakeholderIds),
    remark: Validation.ok('remark', form.remark),
  }

  // Get a validated `IBerthVisit` if all fields are valid:
  const validatedBerthVisit = Validated.combine(validatedFields)

  // Do the more complex validation with a `IBerthVisit` that has values `FIELD_COULD_NOT_BE_VALIDATED`
  // for every field that could not be validated (d'uh!)
  const validatedFieldsOrDefault = Validated.withDefault<IBerthVisit, Validation.FieldCouldNotBeValidated>(
    validatedFields,
    Validation.FIELD_COULD_NOT_BE_VALIDATED
  )

  // When the complex validation succeeds, return the result of the validated berth visit:
  return Validation.isValidBerthVisit(validatedFieldsOrDefault).withValueOf(validatedBerthVisit)
}
