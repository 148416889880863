import { EMPTY, Observable, merge, of, concat } from 'rxjs'
import { switchMap, delay } from 'rxjs/operators'
import { AppAction } from '../actions/AppAction'
import { PromiseOfValidated } from './PromiseOfValidated'

export const QUERY_STRING_EMPTY = 'QUERY_STRING_EMPTY'
export const LOADING = 'LOADING'
export const MORE_CHARACTERS_NEEDED = 'MORE_CHARACTERS_NEEDED'
export const ERROR = 'ERROR'
export const RESULT = 'RESULT'

type IncompleteResultTypes = typeof QUERY_STRING_EMPTY | typeof LOADING | typeof MORE_CHARACTERS_NEEDED | typeof ERROR
export type IncompleteResult<T extends IncompleteResultTypes> = Readonly<{
  type: T
  value: null
}>
export type CompleteResult<T> = Readonly<{
  type: typeof RESULT
  value: T
}>
export type WithResult<T> = IncompleteResult<IncompleteResultTypes> | CompleteResult<T>

export const MINIMUM_QUERY_STRING_LENGTH_FOR_REQUEST = 3
const DEBOUNCE_TIME_IN_MILLISECONDS = 300

type ResultObservableOptions<Result> = Readonly<{
  searchQueryObservable: Observable<string>
  fetcher: (queryString: string) => PromiseOfValidated<AppAction, Result>
  debounceTime?: number
  allowEmptyQuery?: boolean
}>

export function toResultObservable<Result extends any[]>({
  searchQueryObservable,
  fetcher,
  debounceTime = DEBOUNCE_TIME_IN_MILLISECONDS,
  allowEmptyQuery = false,
}: ResultObservableOptions<Result>): Observable<WithResult<Result>> {
  return searchQueryObservable.pipe(
    switchMap(
      (queryString): Observable<WithResult<Result>> => {
        if (allowEmptyQuery === false) {
          if (queryString.length === 0) {
            return of<IncompleteResult<typeof QUERY_STRING_EMPTY>>({
              type: QUERY_STRING_EMPTY,
              value: null,
            })
          }

          if (queryString.length < MINIMUM_QUERY_STRING_LENGTH_FOR_REQUEST) {
            return of<IncompleteResult<typeof MORE_CHARACTERS_NEEDED>>({
              type: MORE_CHARACTERS_NEEDED,
              value: null,
            })
          }
        }

        const startLoadingObservable = of<IncompleteResult<typeof LOADING>>({
          type: LOADING,
          value: null,
        })
        const debounceTimeObservable = EMPTY.pipe(delay(debounceTime))
        const fetchedVesselsObservable = new Observable<WithResult<Result>>(observer => {
          fetcher(queryString)
            .raw.then(validated => {
              if (validated.isValid()) {
                observer.next({
                  type: RESULT,
                  value: validated.value,
                })
                observer.complete()
              } else {
                observer.error(validated.errors)
              }
            })
            .catch(e => {
              observer.error({
                type: ERROR,
                value: e,
              })
            })
        })

        return merge(startLoadingObservable, concat(debounceTimeObservable, fetchedVesselsObservable))
      }
    )
  )
}

export const formatResultObservableError = (err: typeof MORE_CHARACTERS_NEEDED | typeof ERROR) => {
  switch (err) {
    case MORE_CHARACTERS_NEEDED:
      return `Please enter ${MINIMUM_QUERY_STRING_LENGTH_FOR_REQUEST} characters or more`
    case ERROR:
      return 'An error has occurred. Please change your query and try again.'
    default:
      const exhaustive: never = err
      throw new Error(exhaustive)
  }
}
