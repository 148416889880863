// libs
import { Dispatch, Middleware, MiddlewareAPI } from 'redux'
import createDebug from 'debug'
import { mapStackTrace } from 'sourcemapped-stacktrace'

// REST
import { logError } from '../rest/logError'
import { LOG_FRONTEND_ERRORS } from '../config'
import { AppAction } from '../actions/AppAction'

const debug = createDebug('vesselPlanner:errorLoggerMiddleware')

// save the last `ACTION_HISTORY_AMOUNT` actions for logging purposes
const ACTION_HISTORY_AMOUNT = 10
let ACTION_HISTORY: string[] = []

function stacktraceAsArray(error: Error): Promise<string[]> {
  const stack = error === undefined ? undefined : error.stack

  if (stack === undefined) {
    return Promise.resolve([])
  }

  return new Promise<string[]>(resolve => {
    mapStackTrace(stack, resolve)
  })
}

export const errorLoggerMiddleware: Middleware = <S>(store: MiddlewareAPI<Dispatch, S>) => (next: Dispatch<AppAction>) => (action: AppAction) => {
  // build up a history of previous redux actions to be able to log them later on
  ACTION_HISTORY = logAction(action.type, ACTION_HISTORY)

  // use try/catch to intercept errors from redux actions
  try {
    return next(action)
  } catch (e) {
    const errorMessage = e.message
    const stackTracePromise = stacktraceAsArray(e)

    stackTracePromise.then(stackTrace => errorLogger(errorMessage, stackTrace, window.navigator.userAgent, ACTION_HISTORY))

    // return the error to let the browser handle it as usual
    return e
  }
}

// remember the last <ACTION_HISTORY_AMOUNT> redux actions for debugging purposes
const logAction = (actionType: string, history: string[]) => {
  return [actionType, ...history].slice(0, ACTION_HISTORY_AMOUNT)
}

const onGlobalError = (errorMsg: Event | string, filename?: string, lineno?: number, colno?: number, error?: Error) => {
  if (error !== undefined) {
    const stackTracePromise = stacktraceAsArray(error)
    const errorAsString = `${(error && error.message) || JSON.stringify(errorMsg)}`

    stackTracePromise.then(stackTrace => errorLogger(errorAsString, stackTrace, window.navigator.userAgent, ACTION_HISTORY))
  }

  // reset action history on error to build up a clean history
  ACTION_HISTORY = []

  // return false to let the browser handle the error as usual
  return false
}

export const onFetchError = (reason: any) => {
  // Create a new error to have call stack:
  const error = new Error(reason)

  // Log the error the same was as global errors would:
  onGlobalError('Error while fetching: ' + error.message, undefined, undefined, undefined, error)
}

// on global js error -> log it

window.onerror = onGlobalError

const errorLogger = (message: string, stacktrace: string[], userAgent: string, actionHistory: string[]) => {
  if (LOG_FRONTEND_ERRORS) {
    debug('Log error to backend', { message, stacktrace, userAgent, actionHistory })
    logError({ message, stacktrace, userAgent, actionHistory })
  }
}
