import React from 'react'
import styles from './SelectOptionList.module.scss'
import classnames from 'classnames'

type SelectOptionListProps = Readonly<{
  isOpen: boolean
  className?: string
  menuProps: { [key: string]: any }
}>

export const SelectOptionList: React.FC<SelectOptionListProps> = ({ isOpen, className, children, menuProps }) => (
  <ul className={classnames(styles.menu, className, { [styles.open]: isOpen })} {...menuProps}>
    {isOpen && children}
  </ul>
)
