import { IAppState } from '../state/IAppState'
import { VesselPlannerAction, VesselPlannerDispatch } from '../thunk'
import { Action } from 'redux'

export const withToken = (
  doWithToken: (token: string, dispatch: VesselPlannerDispatch, getState: () => IAppState) => void | Promise<void>
): VesselPlannerAction => (dispatch, getState) => {
  const { authToken } = getState()

  if (!authToken) {
    throw new Error('No auth token available!')
  }

  doWithToken(authToken, dispatch, getState)
}

export const thunkFromAction = (action: Action): VesselPlannerAction => dispatch => dispatch(action)
