import { ILayByBerthTableRow, isBerthVisitRow, isLayByBerthRow, ITableRow } from '../../selectors/berthVisitTableRow'
import { useState } from 'react'
import { BerthVisitId } from '../../state/IBerthVisit'
import { pipe } from 'fp-ts/es6/pipeable'
import { useDispatch } from 'react-redux'
import { deleteBerthVisitsThunk, submitDeleteLayByBerthsThunk } from '../../actions/thunks'
import { some, none } from 'fp-ts/es6/Option'
import { filterMap } from 'fp-ts/es6/Array'
import { Either, left, mapLeft, right } from 'fp-ts/es6/Either'
import { flow } from 'fp-ts/es6/function'

const nonEmptyArray = <T>(arr: T[]): Either<T[], string> => (arr.length === 0 ? right('Array is empty') : left(arr))

export type UseSelectRows = ReturnType<typeof useSelectRows>

export const useSelectRows = (combinedRows: ITableRow[]) => {
  const dispatch = useDispatch()
  const [selectedRows, setSelectedRows] = useState<ITableRow[]>([])
  const allSelected = combinedRows.length === selectedRows.length && selectedRows.length > 0

  const selectSingle = (row: ITableRow, isChecked: boolean) => {
    const newSelection = isChecked ? [...selectedRows, row] : selectedRows.filter(({ id }) => id !== row.id)
    setSelectedRows(newSelection)
  }

  const selectAll = () => {
    setSelectedRows(combinedRows)
  }

  const deselectAll = () => {
    setSelectedRows([])
  }

  const deleteSelected = () => {
    pipe(
      selectedRows,
      filterMap<ITableRow, BerthVisitId>(row => (isBerthVisitRow(row) ? some(row.id) : none)),
      nonEmptyArray,
      mapLeft(flow(deleteBerthVisitsThunk, dispatch))
    )

    pipe(
      selectedRows,
      filterMap<ITableRow, ILayByBerthTableRow['id']>(row => (isLayByBerthRow(row) ? some(row.id) : none)),
      nonEmptyArray,
      mapLeft(flow(submitDeleteLayByBerthsThunk, dispatch))
    )

    deselectAll()
  }

  return { selectedRows, allSelected, selectSingle, selectAll, deselectAll, deleteSelected }
}
