import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { format, parse } from 'date-fns'
import { sequenceS } from 'fp-ts/es6/Apply'
import { constant } from 'fp-ts/es6/function'
import { fold, fromNullable, getOrElse, map, option } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'
import { BerthUuid, IBerth } from '../state/IBerth'
import { Asset } from './Asset'
import { TimeZone, timeZoneUTC } from '../state/TimeZone'
import { findFirst } from 'fp-ts/lib/Array'

export enum LayByBerthStatus {
  AVAILABLE = 'available',
  PENDING = 'tentatively',
  BOOKED = 'booked',
}

export const POSSIBLE_LAY_BY_STATUSES: LayByBerthStatus[] = [LayByBerthStatus.AVAILABLE, LayByBerthStatus.PENDING, LayByBerthStatus.BOOKED]

export const LAY_BY_STATUS_TEXT: { [key in LayByBerthStatus]: string } = {
  [LayByBerthStatus.AVAILABLE]: 'available',
  [LayByBerthStatus.PENDING]: 'tentatively booked',
  [LayByBerthStatus.BOOKED]: 'booked',
}

export interface ILayByBerth {
  uuid: string
  companyId: string
  asset: Asset
  startTime: string
  endTime: string
  bollardStart?: number
  bollardEnd?: number
  status: LayByBerthStatus
  note?: string
  created: string
  lastUpdated: string
}

export type LayByBerthRequestBody = Pick<ILayByBerth, 'startTime' | 'endTime' | 'status' | 'note'> & {
  berthUuid: BerthUuid
  bollards?: {
    start: number
    end: number
  }
}

export type DateTime = { date: string; time: string }
export interface LayByBerthFormState {
  asset: IBerth | null
  startTime: DateTime
  endTime: DateTime
  bollardStart?: string
  bollardEnd?: string
  status: LayByBerthStatus
  note?: string
}

export const isAvailable = (status: LayByBerthStatus): boolean => status === LayByBerthStatus.AVAILABLE
export const isTentativelyBooked = (status: LayByBerthStatus): boolean => status === LayByBerthStatus.PENDING
export const isBooked = (status: LayByBerthStatus): boolean => status === LayByBerthStatus.BOOKED

export const emptyDateTime = (): DateTime => ({ date: '', time: '' })
export const dateToDateTime = (date: Date): DateTime => ({ date: format(date, 'MM/dd'), time: format(date, 'HH:mm') })
export const dateStringToDateTime = (dateString: string, timeZone: TimeZone): DateTime =>
  pipe(
    new Date(dateString),
    date => utcToZonedTime(date, timeZone),
    date => ({
      date: format(date, 'MM/dd'),
      time: format(date, 'HH:mm'),
    })
  )

export const dateTimeToDate: (timeZone: TimeZone) => (dateTime: DateTime) => Date = timeZone => ({ date, time }) =>
  pipe(parse(`${date} ${time}`, 'MM/dd HH:mm', new Date()), date => zonedTimeToUtc(date, timeZone))

export const dateTimeToDateString = (dateTime: DateTime, timeZone: TimeZone): string =>
  pipe(dateTime, dateTimeToDate(timeZone), date => date.toISOString() /* https://github.com/date-fns/date-fns/discussions/2366#discussioncomment-532000 */)

export const dateTimeToDateUTC = dateTimeToDate(timeZoneUTC)

export const layByBerthToLayByBerthFormState = (
  { asset: { berthUuid }, startTime, endTime, bollardStart, bollardEnd, status, note }: ILayByBerth,
  berths: IBerth[]
): LayByBerthFormState => {
  const zoneTime = pipe(
    berths,
    findFirst(b => b.uuid === berthUuid),
    map(b => b.timeZone),
    getOrElse(() => timeZoneUTC)
  )

  return {
    asset: berths.find(berth => berth.uuid === berthUuid) || null,
    startTime: dateStringToDateTime(startTime, zoneTime),
    endTime: dateStringToDateTime(endTime, zoneTime),
    bollardStart: pipe(fromNullable(bollardStart), fold(constant(''), String)),
    bollardEnd: pipe(fromNullable(bollardEnd), fold(constant(''), String)),
    status,
    note: note || '',
  }
}

export const layByBerthFormStateToLayByBerthRequestBody = ({
  asset,
  startTime,
  endTime,
  bollardStart,
  bollardEnd,
  status,
  note,
}: LayByBerthFormState): LayByBerthRequestBody => ({
  berthUuid: asset!.uuid,
  startTime: dateTimeToDateString(startTime, asset!.timeZone),
  endTime: dateTimeToDateString(endTime, asset!.timeZone),
  status,
  ...pipe(
    fromNullable(note || null),
    fold(constant({}), note => ({ note }))
  ),
  ...pipe(
    sequenceS(option)({
      start: fromNullable(bollardStart || null),
      end: fromNullable(bollardEnd || null),
    }),
    map(({ start, end }) => ({ bollards: { start: Number(start), end: Number(end) } })),
    getOrElse(constant({}))
  ),
})
