import React from 'react'
import { Form, FormikConfig, FormikProps, Formik } from 'formik'

// Formik props this abstraction accepts
type OuterFormProps<FormState> = FormikConfig<FormState> & FormChildProps<FormState>

// Render props the inner form
type InnerFormProps<FormState> = Omit<FormikProps<FormState>, 'setFieldValue'> & {
  setFieldValue: <Key extends keyof FormState, Value extends FormState[Key]>(field: Key, value: Value) => void
}

// Child render function props
type FormChildProps<FormState> = { children: (props: InnerFormProps<FormState>) => React.ReactElement }

// Component to supply type-safe abstraction around Formik's API
const InnerForm = <FormState extends {}>({ children, ...restProps }: InnerFormProps<FormState> & FormChildProps<FormState>) => children(restProps)

export const FormikWrapper = <FormState extends {}>({ children, ...outerProps }: FormChildProps<FormState> & OuterFormProps<FormState>) => (
  <Formik<FormState> {...outerProps}>
    {(formProps: InnerFormProps<FormState>) => <InnerForm<FormState> {...formProps}>{innerProps => <Form>{children(innerProps)}</Form>}</InnerForm>}
  </Formik>
)
