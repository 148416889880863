import React, { createContext, useState, useEffect, useContext, useCallback } from 'react'
import { getKey } from '../utils/Object'
import { AuthUtils, getAuthClientUtils, AuthToken } from '../utils/auth/auth'
import { createAuthClient } from '../utils/auth/createAuth0Client'
import { getAuth0Utils } from '../utils/auth/getAuth0Utils'
import { HOUR } from '../constants'
import { RedirectLoginResult } from '@auth0/auth0-spa-js'
import useDeepCompareEffect from 'use-deep-compare-effect'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export enum AuthStateType {
  Unauthenticated = 'Unauthenticated',
  Authenticating = 'Authenticating',
  Authenticated = 'Authenticated',
  AuthenticationError = 'AuthenticationError',
}

type UnauthenticatedState = Readonly<{ type: AuthStateType.Unauthenticated }>
type AuthenticatingState = Readonly<{ type: AuthStateType.Authenticating }>
type AuthenticatedState = Readonly<{ type: AuthStateType.Authenticated; token: AuthToken }>
type AuthenticationErrorState = Readonly<{ type: AuthStateType.AuthenticationError; error: AuthErrorDescription }>

type AuthState = UnauthenticatedState | AuthenticatingState | AuthenticatedState | AuthenticationErrorState

type AuthProviderProps = Readonly<{
  children: React.ReactNode
  authUtils: AuthUtils
  urlQuery: string
  onRedirectCallback?: (result: RedirectLoginResult) => void
  onLogin: (token: AuthToken) => void
  onLogout: () => void
  onRefreshToken: (token: AuthToken) => void
}>

export type AuthContextProps = Readonly<{
  authState: AuthState
  authUtils: AuthUtils
}>

enum AuthErrorType {
  Generic = 'Generic',
  Unauthorized = 'Unauthorized',
  InvalidPassword = 'InvalidPassword',
  Timeout = 'Timeout',
}

type AuthErrorDescription = Readonly<{
  type: AuthErrorType
  description: string
}>

const isAuthenticated = (state: AuthState): state is AuthenticatedState => state.type === AuthStateType.Authenticated

// Load Auth0 client hook
export const useAuth0Utils = () => {
  const [authUtils, setAuthUtils] = useState<AuthUtils | undefined>(undefined)

  // Load auth client utilities
  useEffect(() => {
    getAuthClientUtils(createAuthClient, getAuth0Utils).then(setAuthUtils)
  }, [setAuthUtils])

  return authUtils
}

// Expose context
const AuthContext = createContext<AuthContextProps>({
  authState: { type: AuthStateType.Authenticating },
} as AuthContextProps)

// Auth Context hook
export const useAuth = () => useContext<AuthContextProps>(AuthContext)

// Refresh token hook
const useAuthTokenRefresh = (
  authUtils: AuthUtils,
  authState: AuthState,
  onRefreshToken: (token: AuthToken) => void,
  determineError: (err: any) => AuthenticationErrorState,
  setAuthError: (err: AuthenticationErrorState) => void
) => {
  useEffect(() => {
    let refreshInterval: undefined | ReturnType<typeof setInterval>

    if (authState.type === AuthStateType.Authenticated) {
      // We have a valid session, start token refresh timer
      refreshInterval = setInterval(async () => {
        try {
          const token = await authUtils.getToken()

          onRefreshToken(token)
        } catch (error) {
          setAuthError(determineError(error))
        }
      }, 1 * HOUR)
    }

    // Clear the interval when the hook re-runs
    return () => {
      if (refreshInterval !== undefined) {
        clearInterval(refreshInterval)
      }
    }
  }, [authUtils, authState, onRefreshToken, determineError, setAuthError])
}

export const AuthProvider = ({
  children,
  authUtils,
  urlQuery,
  onRefreshToken,
  onLogin,
  onLogout,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}: AuthProviderProps) => {
  // Set loading state while we're checking auth credentials
  const [authState, setAuthState] = useState<AuthState>({ type: AuthStateType.Authenticating })
  const setAuthError = useCallback(
    (errState: AuthenticationErrorState) => {
      setAuthState(errState)
    },
    [setAuthState]
  )
  const determineError = useCallback(determineErrorType, [])

  // Start token refresh when state changes to authenticated
  useAuthTokenRefresh(authUtils, authState, onRefreshToken, determineError, setAuthError)

  // Init auth
  useEffect(() => {
    const initAuth0 = async () => {
      // Handle url callback after login attempt
      try {
        const authResult = await authUtils.handleAuthCallback(urlQuery)

        if (authResult.type === 'RESULT') {
          onRedirectCallback(authResult.result.appState)
        }

        // Check if user is actually authenticated
        try {
          const token = await authUtils.getToken()
          setAuthState({ type: AuthStateType.Authenticated, token })
        } catch (error) {
          if (error.error === 'login_required') {
            setAuthState({ type: AuthStateType.Unauthenticated })
          } else {
            setAuthError(determineError(error))
          }
        }
      } catch (error) {
        setAuthError(determineError(error))
      }
    }
    initAuth0()
  }, [authUtils, onRedirectCallback, onLogin, determineError, setAuthError, urlQuery])

  useDeepCompareEffect(() => {
    if (isAuthenticated(authState)) {
      onLogin(authState.token)
    }
  }, [authState, onLogin])

  const wrappedAuthUtils: AuthUtils = {
    ...authUtils,
    logout: () => {
      onLogout()
      authUtils.logout()
      setAuthState({ type: AuthStateType.Unauthenticated })
    },
  }

  return (
    <AuthContext.Provider
      value={{
        authState,
        authUtils: wrappedAuthUtils,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

// Handle authentication errors
// error *can* really be anything as any random value can be thrown in JS
const determineErrorType = (error: any) => {
  const errorType: string = getKey(error, 'error') ? error.error : 'unexpected error'

  switch (errorType) {
    // Expected possible error cases
    case 'unauthorized':
      return createAuthError(AuthErrorType.Unauthorized, 'Unauthorized')
    case 'invalid_user_password':
      return createAuthError(AuthErrorType.InvalidPassword, 'Invalid password')
    case 'timeout':
      return createAuthError(AuthErrorType.Timeout, 'Authentication timed out, please try again')
    // Unidentified error cases
    default:
      return createAuthError(AuthErrorType.Generic, error.error_description ? error.error_description : JSON.stringify(error))
  }
}

const createAuthError = (type: AuthErrorType, description: string): AuthenticationErrorState => ({
  type: AuthStateType.AuthenticationError,
  error: { type, description },
})
