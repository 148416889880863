export const sortAlphabeticallyWithNullLast = (left: string | null, right: string | null) => {
  if (left === right) {
    return 0
  }

  if (!left) {
    return 1
  }

  if (!right) {
    return -1
  }

  return left.toLowerCase() > right.toLocaleLowerCase() ? 1 : -1
}
