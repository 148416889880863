import React, { InputHTMLAttributes } from 'react'
import classnames from 'classnames'
import { FormElementProps } from '../shared/FormTypes'
import styles from './Select.module.scss'
import Downshift, { DownshiftProps, GetItemPropsOptions } from 'downshift'
import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper'

export type SelectOption<Value> = Readonly<{
  label: string
  value: Value
}>

export type SelectProps<Value> = InputWrapperProps &
  FormElementProps &
  Readonly<{
    options: SelectOption<Value>[]
    selectedOption: SelectOption<Value> | null
    additionalProps?: Partial<DownshiftProps<SelectOption<Value>>>
    inputProps?: Partial<InputHTMLAttributes<HTMLInputElement>>
    getKey: (option: SelectOption<Value>) => string
    optionFilter?: (selectedOption: SelectOption<Value> | null, query: string | null, options: SelectOption<Value>[]) => SelectOption<Value>[]
    handleChange: (option: SelectOption<Value>) => void
    handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onInput?: (query: string) => void
    children: (childProps: SelectMenuChildProps<Value>) => React.ReactElement | null
  }>

export type SelectMenuChildProps<Value> = Readonly<{
  inputValue: string | null
  options: SelectOption<Value>[]
  selectedItem: SelectOption<Value> | null
  isOpen: boolean
  highlightedIndex: number | null
  getMenuProps: () => any
  getItemProps: (props: GetItemPropsOptions<SelectOption<Value>>) => any
  getKey: (item: SelectOption<Value>) => string
  setHighlightedIndex: (index: number) => void
  setItemCount: (index: number) => void
}>

type DownshiftKeyDownEvent = React.KeyboardEvent<HTMLInputElement> &
  Readonly<{
    nativeEvent: {
      preventDownshiftDefault: boolean
    }
  }>

const defaultInputProps = {
  readOnly: true,
}

export const Select = <Value extends {}>({
  label,
  isRequired,
  isDisabled,
  errorMessage,
  options,
  selectedOption,
  additionalProps,
  inputProps,
  optionFilter,
  getKey,
  handleChange,
  handleBlur,
  onInput,
  children,
}: SelectProps<Value>) => {
  const getOptions = (inputValue: string | null) => (optionFilter ? optionFilter(selectedOption, inputValue, options) : options)

  return (
    <InputWrapper label={label} isRequired={isRequired} isDisabled={isDisabled} errorMessage={errorMessage}>
      <Downshift<SelectOption<Value>>
        onChange={option => {
          if (option !== null) {
            handleChange(option)
          }
        }}
        itemToString={item => (item ? item.label : '')}
        selectedItem={selectedOption}
        defaultHighlightedIndex={selectedOption ? options.indexOf(selectedOption) : 0}
        {...additionalProps} // Allow for extra control of Downshift options
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem,
          openMenu,
          closeMenu,
          inputValue,
          setHighlightedIndex,
          setItemCount,
        }) => (
          <div className={styles.container}>
            <input
              disabled={isDisabled}
              {...getInputProps({
                onKeyDown: (event: DownshiftKeyDownEvent) => {
                  if (event.key === 'Escape') {
                    event.nativeEvent.preventDownshiftDefault = true
                    closeMenu()
                  }
                },
                onClick: () => (isOpen ? closeMenu() : openMenu()),
                onBlur: e => {
                  closeMenu()
                  if (handleBlur) {
                    handleBlur(e)
                  }
                },
                onInput: evt => onInput && onInput(evt.currentTarget.value),
              })}
              className={classnames(styles.input, { [styles.error]: !!errorMessage })}
              {...{ ...defaultInputProps, ...inputProps }} // Allow for extra control on the input
            />
            {children({
              isOpen,
              getMenuProps,
              getItemProps,
              getKey,
              highlightedIndex,
              selectedItem,
              options: getOptions(inputValue),
              inputValue,
              setHighlightedIndex,
              setItemCount,
            })}
          </div>
        )}
      </Downshift>
    </InputWrapper>
  )
}
