const getEnvironmentVariable = (name: string): string => {
  const value = process.env[name]

  if (value === undefined) {
    throw new Error(`Key ${name} is not defined in environment`)
  } else if (value === '') {
    throw new Error(`Key ${name} is defined in environment as an empty string`)
  }
  return value
}

export const BACKEND_URL = getEnvironmentVariable('REACT_APP_VESSEL_PLANNER_BACKEND_URL')
export const LOG_FRONTEND_ERRORS = getEnvironmentVariable('REACT_APP_VESSEL_PLANNER_LOG_FRONTEND_ERRORS')
export const AUTH0_DOMAIN = getEnvironmentVariable('REACT_APP_VESSEL_PLANNER_AUTH0_DOMAIN')
export const AUTH0_CLIENT_ID = getEnvironmentVariable('REACT_APP_VESSEL_PLANNER_AUTH0_SPA_CLIENT_ID')
export const PRONTO_URL = getEnvironmentVariable('REACT_APP_PRONTO_URL')
export const DEPLOYMENT_ENVIRONMENT = getEnvironmentVariable('REACT_APP_VESSEL_PLANNER_DEPLOYMENT_ENVIRONMENT') as DeploymentEnvironment
export const MIXPANEL_ID = getEnvironmentVariable('REACT_APP_MIXPANEL_ID')

export const __TEST__ = 'test'
export const __ACCEPTANCE__ = 'accp'
export const __PROD__ = 'prod'
export type DeploymentEnvironment = typeof __TEST__ | typeof __ACCEPTANCE__ | typeof __PROD__
