import { layByBerthsSelector } from './layByBerthsSelector'
import { createSelector } from 'reselect'
import { map, Option } from 'fp-ts/es6/Option'
import { showLayByBerthsSelector } from './showLayByBerthsSelector'
import { pipe } from 'fp-ts/es6/pipeable'
import { ILayByBerth } from '../Domain/LayByBerth'

export const layByBerthsWithFilterSelector = createSelector(
  layByBerthsSelector,
  showLayByBerthsSelector,
  (layByBerths: Option<ILayByBerth[]>, showLayByBerths: boolean) =>
    pipe(
      layByBerths,
      map(lbbs => (showLayByBerths ? lbbs : []))
    )
)
