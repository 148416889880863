import React from 'react'
import styles from './DropoutMenuDropper.module.scss'
import classnames from 'classnames'

type DropoutMenuDropperProps = Readonly<{
  isOpen: boolean
}>

export const DropoutMenuDropper: React.FC<DropoutMenuDropperProps> = ({ children, isOpen }) => (
  <div className={classnames(styles.dropoutDropper, { [styles.isOpen]: isOpen })}>{children}</div>
)
