import { DATE_MASK } from '../../../constants'

const DATE_LENGTH = DATE_MASK.length

type InputTypes = HTMLInputElement | HTMLTextAreaElement

export const useJumpToNextInput = () => {
  function moveFocusToNextInput(el: HTMLInputElement) {
    const cursorAtEndOfDateInput = isAtEndOfInput(el)
    if (!cursorAtEndOfDateInput) {
      return
    }

    const form = el.form
    if (!form) {
      return
    }

    const formElements = Array.from(form.querySelectorAll<InputTypes>('input:not([type="checkbox"]):not([type="radio"]), textarea')).filter(isNotDisabled)
    const currentElementIndex = formElements.indexOf(el)
    const nextElement = currentElementIndex !== -1 && formElements[currentElementIndex + 1]

    if (nextElement) {
      nextElement.focus()

      // Set selection at start of next field to overwrite existing input
      nextElement.selectionStart = 0
      nextElement.selectionEnd = 0
    }
  }

  return { moveFocusToNextInput }
}

function isNotDisabled(el: InputTypes): boolean {
  return !el.disabled
}

function isAtEndOfInput(el: HTMLInputElement): boolean {
  return el.selectionStart === el.selectionEnd && el.selectionEnd === DATE_LENGTH
}
