import { IShipId } from '../../state/IShip'
import { BerthUuid, TerminalUuid } from '../../state/IBerth'
import { IBerthVisit } from '../../state/IBerthVisit'
import { BerthVisitId } from '../../state/IBerthVisit'
import { formatBackendDateTime, parseBackendDateTime } from '../../utils/dates/dates'
import { IMooringJson } from '../../state/IMooring'
import { orientationJsonToOrientation, orientationToOrientationJson } from '../../state/Orientation'
import { StakeholderId } from '../../state/IStakeholder'

interface IBerthVisitDateJson {
  eventTime: string
  isActual: boolean
}

export interface IBerthVisitJson {
  berthVisitId: BerthVisitId
  ship: IShipId
  berthUuid: BerthUuid | null
  terminalUuid: TerminalUuid | null
  mooringInfo: IMooringJson
  arrivalBerth: IBerthVisitDateJson | undefined
  departureBerth: IBerthVisitDateJson | undefined
  startCargo: IBerthVisitDateJson | undefined
  completeCargo: IBerthVisitDateJson | undefined
  stakeholders: StakeholderId[] | undefined
  remark: string | undefined
}

export function toBerthVisit(json: IBerthVisitJson): IBerthVisit {
  return {
    orientation: orientationJsonToOrientation(json.mooringInfo.orientation),
    bollards: json.mooringInfo.bollardFore && json.mooringInfo.bollardAft ? { start: json.mooringInfo.bollardFore, end: json.mooringInfo.bollardAft } : null,
    ship: json.ship,
    arrivalBerth:
      json.arrivalBerth === undefined
        ? null
        : {
            date: parseBackendDateTime(json.arrivalBerth.eventTime),
            isActual: json.arrivalBerth.isActual,
          },
    departureBerth:
      json.departureBerth === undefined
        ? null
        : {
            date: parseBackendDateTime(json.departureBerth.eventTime),
            isActual: json.departureBerth.isActual,
          },
    startCargo:
      json.startCargo === undefined
        ? null
        : {
            date: parseBackendDateTime(json.startCargo.eventTime),
            isActual: json.startCargo.isActual,
          },
    completeCargo:
      json.completeCargo === undefined
        ? null
        : {
            date: parseBackendDateTime(json.completeCargo.eventTime),
            isActual: json.completeCargo.isActual,
          },
    remark: json.remark === undefined ? null : json.remark,
    id: json.berthVisitId,
    berthUuid: json.berthUuid || null,
    terminalUuid: json.terminalUuid || null,
    stakeholderIds: json.stakeholders || [],
  }
}

export function toBerthVisitJson(berthVisit: IBerthVisit): IBerthVisitJson {
  return {
    berthVisitId: berthVisit.id,
    mooringInfo: berthVisitToMooringJson(berthVisit),
    ship: berthVisit.ship,
    arrivalBerth:
      berthVisit.arrivalBerth === null
        ? undefined
        : {
            eventTime: formatBackendDateTime(berthVisit.arrivalBerth.date),
            isActual: berthVisit.arrivalBerth.isActual,
          },
    departureBerth:
      berthVisit.departureBerth === null
        ? undefined
        : {
            eventTime: formatBackendDateTime(berthVisit.departureBerth.date),
            isActual: berthVisit.departureBerth.isActual,
          },
    startCargo:
      berthVisit.startCargo === null
        ? undefined
        : {
            eventTime: formatBackendDateTime(berthVisit.startCargo.date),
            isActual: berthVisit.startCargo.isActual,
          },
    completeCargo:
      berthVisit.completeCargo === null
        ? undefined
        : {
            eventTime: formatBackendDateTime(berthVisit.completeCargo.date),
            isActual: berthVisit.completeCargo.isActual,
          },
    remark: berthVisit.remark === null ? undefined : berthVisit.remark,
    berthUuid: berthVisit.berthUuid || null,
    terminalUuid: berthVisit.terminalUuid || null,
    stakeholders: berthVisit.stakeholderIds === null ? undefined : berthVisit.stakeholderIds,
  }
}

export function toBerthVisits(json: IBerthVisitJson[]): IBerthVisit[] {
  return json.map(toBerthVisit)
}

const berthVisitToMooringJson = (berthVisit: IBerthVisit): IMooringJson => {
  return {
    orientation: orientationToOrientationJson(berthVisit.orientation),
    bollardFore: berthVisit.bollards !== null && berthVisit.bollards.start !== null ? berthVisit.bollards.start : undefined,
    bollardAft: berthVisit.bollards !== null && berthVisit.bollards.end !== null ? berthVisit.bollards.end : undefined,
  }
}
