import React from 'react'
import styles from './InputErrorMessage.module.scss'

type InputErrorMessageProps = Readonly<{
  text: string
  className?: string
}>

export const InputErrorMessage: React.FC<InputErrorMessageProps> = ({ text, className }) => (
  <div className={className}>
    <span className={styles.asterisk}>*</span>
    <span className={styles.errorMessage}>{text}</span>
  </div>
)
