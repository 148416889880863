import classnames from 'classnames'
import React, { useState, useRef, useEffect } from 'react'
import { renderIf } from '../../utils/rendering'
import styles from './DropoutMenu.module.scss'
import { useClickOutside } from './useClickOutside'

export enum DropoutStateType {
  Open = 'DROPOUT_OPEN',
  Closed = 'DROPOUT_CLOSED',
}

type DropoutState = Readonly<{
  state: DropoutStateType
}>

const isDropoutOpened = ({ state }: DropoutState) => state === DropoutStateType.Open

type UseDropoutMenu = ReturnType<typeof useDropoutMenu>

const useDropoutMenu = () => {
  const [dropoutState, setDropoutState] = useState<DropoutState>({ state: DropoutStateType.Closed })

  const openDropout = () => setDropoutState({ state: DropoutStateType.Open })
  const closeDropout = () => setDropoutState({ state: DropoutStateType.Closed })

  return { dropoutState, openDropout, closeDropout }
}

type DropoutMenuProps = Readonly<{
  children: (props: UseDropoutMenu) => React.ReactElement
}>

export const DropoutMenu = ({ children }: DropoutMenuProps) => {
  const props = useDropoutMenu()
  const { closeDropout } = props
  const ref = useRef<HTMLDivElement>(null)

  const { onRef } = useClickOutside(closeDropout)
  useEffect(() => {
    onRef(ref)
  }, [ref, onRef])

  return (
    <>
      {renderIf(isDropoutOpened(props.dropoutState), <div className={styles.dropoutMenuOverlay} />)}
      <div ref={ref} className={classnames(styles.dropoutMenu, { [styles.active]: isDropoutOpened(props.dropoutState) })}>
        {children(props)}
      </div>
    </>
  )
}
