import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { AuthGate } from './containers/AuthGate'
import { Provider } from 'react-redux'
import { store } from './store'
import './index.scss'
import ReduxToastr from 'react-redux-toastr'
import { Router } from './containers/Router'
import { MixpanelProvider } from './context/MixpanelContext'

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <MixpanelProvider>
        <AuthGate>
          <Router />
          <ReduxToastr position={'bottom-center'} closeOnToastrClick={true} className="vp-toaster" transitionIn={'fadeIn'} transitionOut={'fadeOut'} />
        </AuthGate>
      </MixpanelProvider>
    </Provider>,
    document.getElementById('root')
  )
}

render()
