import { createHeaders } from '../createHeaders'
import { fetchJsonWithErrorHandling } from '../fetchJsonWithErrorHandling'
import { BACKEND_URL } from '../../config'
import { IStakeholderJson, toStakeholders } from './IStakeholderJson'

export function fetchStakeholders(authToken: string) {
  const request: RequestInit = {
    method: 'GET',
    headers: createHeaders({ authToken }),
    cache: 'default'
  }

  return fetchJsonWithErrorHandling<IStakeholderJson[]>(`${BACKEND_URL}/api/stakeholders`, request).map(toStakeholders)
}
