import { LocaleId } from '../state/User'
import { createSelector } from 'reselect'
import { userLocaleSelector } from './userLocaleSelector'
import { Option, fold } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'

const knownLocalesToDateFormat: Record<LocaleId, string> = {
  'en-US': 'MM/DD',
  default: 'DD/MM',
}

const getDateFormat = (locale: Option<string>): string =>
  pipe(
    locale,
    fold(
      () => knownLocalesToDateFormat.default, // No locale
      loc => knownLocalesToDateFormat[loc] || knownLocalesToDateFormat.default // Has potentially unknown locale
    )
  )

export const dateFormatSelector = createSelector(userLocaleSelector, getDateFormat)
