import { IBerth } from '../state/IBerth'
import { AppAction } from '../actions/AppAction'
import { SET_BERTHS } from '../actions/berthsActions'

export function berthsReducer(berths: IBerth[] = [], action: AppAction): IBerth[] {
  switch (action.type) {
    case SET_BERTHS:
      return action.berths
    default:
      return berths
  }
}
