import { isLaybyBerthPlanner } from './../../utils/roles'
import { useDispatch, useSelector } from 'react-redux'
import { showDeepseaSelector } from '../../selectors/showDeepseaSelector'
import { showBargesSelector } from '../../selectors/showBargesSelector'
import { showLayByBerthsSelector } from '../../selectors/showLayByBerthsSelector'
import { AppAction } from '../../actions/AppAction'
import { toggleShowBarges, toggleShowDeepsea, toggleShowLayByBerths } from '../../actions/viewAgnosticActions'
import { useFeatureToggle } from '../FeatureToggle/FeatureToggle'

enum VesselTypes {
  DEEP_SEA = 'DEEP_SEA',
  BARGE = 'BARGE',
  LAY_BY_BERTH = 'LAY_BY_BERTH',
}

export const useSelectedVesselTypes = () => {
  const dispatch = useDispatch()
  const showDeepSea = useSelector(showDeepseaSelector)
  const showBarges = useSelector(showBargesSelector)
  const showLayByBerths = useSelector(showLayByBerthsSelector)
  const checkIfLaybyBerthPlanner = useFeatureToggle([isLaybyBerthPlanner])

  const vesselTypes: Record<VesselTypes, { label: string; selected: boolean; action: () => AppAction; isDisabled?: boolean }> = {
    [VesselTypes.BARGE]: { label: 'Barges', selected: showBarges, action: toggleShowBarges },
    [VesselTypes.DEEP_SEA]: { label: 'Deep sea', selected: showDeepSea, action: toggleShowDeepsea },
    [VesselTypes.LAY_BY_BERTH]: { label: 'Lay-by berths', selected: showLayByBerths, action: toggleShowLayByBerths, isDisabled: !checkIfLaybyBerthPlanner },
  }

  const toggleVesselType = (vesselType: VesselTypes) => {
    dispatch(vesselTypes[vesselType]?.action())
  }

  return { vesselTypes, toggleVesselType }
}
