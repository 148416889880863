import React, { useCallback } from 'react'
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal'
import { usePortalModal } from '../PortalModal/PortalModal'
import { flow } from 'fp-ts/es6/function'

export const useRowsDeleteModal = () => {
  const { ModalContainer, showModal, hideModal } = usePortalModal()

  const askForDelete = useCallback(
    (count: number, onDelete: () => void) => {
      showModal(
        <ConfirmationModal
          title="Cancel selected entries"
          body={
            <p>
              Are you sure you want to cancel {count} selected {count === 1 ? 'entry' : 'entries'}?
            </p>
          }
          cancelText="No"
          confirmText="Yes"
          onClose={hideModal}
          onConfirm={flow(onDelete, hideModal)}
        />
      )
    },
    [hideModal, showModal]
  )

  return { RowsDeleteModalContainer: ModalContainer, askForDelete }
}
