import React from 'react'
import styles, { Styles } from './Button.module.scss'
import classnames from 'classnames'
import MaterialIcon from '../../MaterialIcon/MaterialIcon'

export type ButtonTheme = Partial<Styles>

export const buttonSecondaryTheme: ButtonTheme = {
  button: styles.buttonSecondary,
}

type ButtonProps = {
  text: React.ReactNode
  type: 'button' | 'submit' | 'reset'
  materialIconName?: string
  disabled?: boolean
  theme?: ButtonTheme
  onClick?: () => void
}

export const Button: React.FC<ButtonProps> = ({ text, type, materialIconName, disabled, theme, onClick }) => (
  <button type={type} className={classnames(styles.button, theme?.button)} disabled={disabled} onClick={onClick}>
    {text && <div className={classnames(styles.label, theme?.label)}>{text}</div>}
    {materialIconName !== undefined && <MaterialIcon type={materialIconName} className={classnames(styles.icon, theme?.icon)} />}
  </button>
)
