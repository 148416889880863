import React from 'react'
import classnames from 'classnames'
import ReactMaskedInput from 'react-maskedinput'
import styles, { Styles } from './MaskedInput.module.scss'
import { useJumpToNextInput } from '../shared/useJumpToNextInput'

export type MaskedInputTheme = Partial<Styles>

export type MaskedInputProps = {
  mask: string
  placeholder?: string
  value?: string
  isDisabled?: boolean
  hasError?: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur?: (e: React.FocusEvent) => void
  theme?: MaskedInputTheme
}

export const MaskedInput: React.FC<MaskedInputProps> = ({ mask, placeholder, value, isDisabled, hasError, handleChange, handleBlur, theme }) => {
  const { moveFocusToNextInput } = useJumpToNextInput()

  return (
    <ReactMaskedInput
      mask={mask}
      placeholder={placeholder}
      value={value}
      disabled={isDisabled}
      onChange={e => {
        handleChange(e)
        moveFocusToNextInput(e.currentTarget)
      }}
      onBlur={handleBlur}
      className={classnames(styles.maskedInput, { [styles.error]: hasError }, theme?.maskedInput)}
    />
  )
}
