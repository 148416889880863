import { createSelector } from 'reselect'
import { IAppState } from '../state/IAppState'
import { IBerth } from '../state/IBerth'
import { sortAlphabeticallyWithNullLast } from '../utils/ordering'

const sort = (berths: IBerth[]): IBerth[] => berths.sort((a, b) => sortAlphabeticallyWithNullLast(a.name, b.name))

export const berthsSelector = (appState: IAppState): IBerth[] => appState.berths

export const orderedBerthsSelector = createSelector(berthsSelector, berths => {
  const groupedByTerminal = berths.reduce((acc: { [key: string]: IBerth[] }, val: IBerth) => {
    const groupName = val.terminalUuid || 'no-terminal'

    return {
      ...acc,
      [groupName]: [...(acc[groupName] || []), val],
    }
  }, {})

  const sortedBerths = Object.values(groupedByTerminal).reduce((acc: IBerth[], val: IBerth[]) => [...acc, ...sort(val)], [])

  return sortedBerths
})
