// Libraries
import * as React from 'react'
import styles from './LoginButton.module.scss'
import { useAuth } from '../../context/Auth0Context'

export const LoginButton: React.FC = () => {
  const { authUtils } = useAuth()

  return (
    <button onClick={authUtils.login} className={styles.button}>
      SIGN IN
    </button>
  )
}
