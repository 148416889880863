import * as React from 'react'

export const Logo: () => JSX.Element = () => (
  <svg width='89px' height='88px' viewBox='0 0 89 88' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <title>Group 5</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='linearGradient-1'>
        <stop stopColor='#0A4691' offset='0%'></stop>
        <stop stopColor='#0693CA' offset='100%'></stop>
      </linearGradient>
    </defs>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='00-00-Pronto---Login-1' transform='translate(-855.000000, -706.000000)'>
        <g id='Group-3' transform='translate(707.000000, 666.000000)'>
          <g id='PRONTO-COMBINATIE' transform='translate(122.000000, 40.000000)'>
            <g id='Group-5' transform='translate(26.988636, 0.000000)'>
              <path
                d='M43.8876316,0 C26.332579,0 15.3606711,2.19438158 8.77752632,8.77752632 C2.19438158,15.3606711 0,26.332579 0,43.8876316 C0,61.4426843 2.19438158,72.4145922 8.77752632,78.9977369 C15.3606711,85.5808816 26.332579,87.7752632 43.8876316,87.7752632 C61.4426843,87.7752632 72.4145922,85.5808816 78.9977369,78.9977369 C85.5808816,72.4145922 87.7752632,61.4426843 87.7752632,43.8876316 C87.7752632,26.332579 85.5808816,15.3606711 78.9977369,8.77752632 C72.4145922,2.19438158 61.4426843,0 43.8876316,0 Z'
                id='Shape' fill='url(#linearGradient-1)'></path>
              <path
                d='M43.8205362,87.7752632 C71.6155333,87.7752632 82.8583879,82.2460432 86.3123422,62.4086512 C84.1514132,67.0527937 79.058773,69.5682201 74.0656006,68.457729 C69.0724283,67.3472379 65.5207025,62.909288 65.5244382,57.7853816 C65.5244381,63.821773 60.6397327,68.7152349 54.6141434,68.7152349 C48.588554,68.7152349 43.7038487,63.821773 43.7038486,57.7853816 C43.7038486,63.8217731 38.8191432,68.7152352 32.7935538,68.7152352 C26.7679643,68.7152352 21.8832589,63.8217731 21.8832589,57.7853816 C21.8765979,62.7526872 18.5272059,67.0908198 13.7296723,68.3459074 C8.93213875,69.600995 3.89310993,67.4573656 1.46292105,63.1275667 C5.05690052,82.3804745 16.3639333,87.7752632 43.8205362,87.7752632 Z'
                id='Shape' fill='#002E5C' fillRule='nonzero'></path>
              <rect id='Rectangle-path' fill='#FFFFFF' fillRule='nonzero'
                    transform='translate(50.470776, 20.115164) rotate(-45.000000) translate(-50.470776, -20.115164) '
                    x='48.2763948' y='15.3606711' width='4.38876316' height='9.50898685'></rect>
              <polygon id='Shape' fill='#FFFFFF' fillRule='nonzero'
                       points='30.2653964 46.6500175 49.0078553 27.8828853 42.3460951 21.2123553 23.6036362 39.9794875 18.6028984 34.9721665 13.1662895 57.0539211 35.219013 51.6101552'></polygon>
              <rect id='Rectangle-path' fill='#FFD800' fillRule='nonzero'
                    transform='translate(35.110105, 67.660099) rotate(-45.000000) translate(-35.110105, -67.660099) '
                    x='32.9157237' y='62.9056053' width='4.38876316' height='9.50898685'></rect>
              <polygon id='Shape' fill='#FFD800' fillRule='nonzero'
                       points='71.6831316 30.7213421 49.8290611 36.117051 54.738055 41.0275552 36.5730263 59.2198042 43.1806139 65.8314474 61.3398055 47.6450442 66.2954962 52.6023152'></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)