import { User } from '../state/User'
import { backend, handleRequest, HandleRequestReturn } from './backend'

export const fetchUser = async (authToken: string): HandleRequestReturn<User> =>
  handleRequest(() =>
    backend
      .get('user', {
        headers: {
          'X-Auth-Token': authToken,
        },
      })
      .json<User>()
  )
