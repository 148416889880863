import { BACKEND_URL } from '../config'
import { createHeaders } from './createHeaders'

interface IErrorLog {
  message: string
  stacktrace: string[]
  userAgent: string
  actionHistory: string[] // history of dispatched redux actions before the error happened
}

export function logError(errorLog: IErrorLog): Promise<any> {
  const url = `${BACKEND_URL}/api/logging`
  const options = {
    method: 'POST',
    headers: createHeaders({ contentType: 'json' }),
    body: JSON.stringify(errorLog),
  }

  return fetch(url, options)
}
