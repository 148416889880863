import React, { useCallback, useRef } from 'react'
import { DateTimeInput, DateTimeInputProps, DateTimeInputWithActual, DateTimeInputWithActualProps } from './DateTimeInput'
import { FieldAttributes, FieldHelperProps, FieldMetaProps, useField } from 'formik'

const useInputUtils = <T,>(meta: FieldMetaProps<T>, helpers: FieldHelperProps<T>) => {
  const errorMessage = meta.error && meta.touched ? meta.error : undefined

  const helpersRef = useRef(helpers)
  const handleBlur = useCallback(() => helpersRef.current.setTouched(true), [helpersRef])

  return { handleBlur, errorMessage }
}

export type FormikDateTimeInputWithActualProps = FieldAttributes<Omit<DateTimeInputWithActualProps, 'value' | 'handleChange' | 'errorMessage'>> &
  Partial<Pick<DateTimeInputWithActualProps, 'handleChange'>>
export const FormikDateTimeInputWithActual: React.FC<FormikDateTimeInputWithActualProps> = props => {
  const [field, meta, helpers] = useField<FormikDateTimeInputWithActualProps['value']>(props)
  const { handleBlur, errorMessage } = useInputUtils(meta, helpers)
  return (
    <DateTimeInputWithActual
      {...props}
      value={field.value}
      handleChange={value => {
        helpers.setValue(value)

        if (props.handleChange) {
          props.handleChange(value)
        }
      }}
      handleBlur={handleBlur}
      errorMessage={errorMessage}
    />
  )
}

export type FormikDateTimeInputProps = FieldAttributes<Omit<DateTimeInputProps, 'value' | 'handleChange' | 'errorMessage'>> &
  Partial<Pick<DateTimeInputProps, 'handleChange'>>
export const FormikDateTimeInput: React.FC<FormikDateTimeInputProps> = props => {
  const [field, meta, helpers] = useField<FormikDateTimeInputProps['value']>(props)
  const { handleBlur, errorMessage } = useInputUtils(meta, helpers)

  return (
    <DateTimeInput
      {...props}
      value={field.value}
      handleChange={value => {
        helpers.setValue(value)

        if (props.handleChange) {
          props.handleChange(value)
        }
      }}
      handleBlur={handleBlur}
      errorMessage={errorMessage}
    />
  )
}
