import { useEffect, useState } from 'react'

export const useClickOutside = (onClickOutside: () => void) => {
  const [currentRef, setCurrentRef] = useState<React.RefObject<HTMLElement> | null>(null)
  const onRef = (ref: React.RefObject<HTMLElement>) => setCurrentRef(ref)

  useEffect(() => {
    if (currentRef !== null && currentRef.current !== null) {
      const el = currentRef.current
      const closeHandler = ({ target }: Event) => {
        if (document.contains(target as Node) && !el.contains(target as Node)) {
          onClickOutside()
        }
      }

      document.addEventListener('click', closeHandler)

      return () => {
        document.removeEventListener('click', closeHandler)
      }
    }
  }, [currentRef, onClickOutside])

  return { onRef }
}
