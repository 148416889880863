import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { Toggle, TOGGLE_THEME_GREEN, ToggleTheme } from '../Toggle/Toggle'
import styles from './DateTimeInput.module.scss'
import { MaskedInput, MaskedInputTheme } from '../MaskedInput/MaskedInput'
import { InputWrapper } from '../InputWrapper/InputWrapper'
import { filterNull } from '../../../utils/predicates'
import { useJumpToNextInput } from '../shared/useJumpToNextInput'
import { DATE_MASK, TIME_MASK } from '../../../constants'

export type DateTime = {
  date: string
  time: string
  isActual: boolean
}

export const emptyDateTime: DateTime = {
  date: '',
  time: '',
  isActual: false,
}

export type CommonDateTimeInputProps = {
  label: string
  dateFormat: string
  isRequired?: boolean
  isDisabled?: boolean
  errorMessage?: string
  handleBlur?: (e: React.FocusEvent[]) => void
}

export type DateTimeInputProps = CommonDateTimeInputProps & {
  value: Omit<DateTime, 'isActual'>
  handleChange: (dateTime: Omit<DateTime, 'isActual'>) => void
}

export const dateInputTheme: MaskedInputTheme = {
  maskedInput: styles.dateInput,
}

export const timeInputTheme: MaskedInputTheme = {
  maskedInput: styles.timeInput,
}

const toggleTheme: ToggleTheme = {
  ...TOGGLE_THEME_GREEN,
  toggleContainer: styles.toggle,
}

export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  label,
  dateFormat,
  value,
  isRequired,
  isDisabled,
  errorMessage,
  handleChange,
  handleBlur,
  children,
}) => {
  const [dateFocusEvent, setDateFocusEvent] = useState<React.FocusEvent | null>(null)
  const [timeFocusEvent, setTimeFocusEvent] = useState<React.FocusEvent | null>(null)
  const { moveFocusToNextInput } = useJumpToNextInput()
  const valueRef = useRef(value)

  useEffect(() => {
    if (handleBlur) {
      if ((dateFocusEvent && valueRef.current.time) || (timeFocusEvent && valueRef.current.date) || (dateFocusEvent && timeFocusEvent)) {
        const events: React.FocusEvent[] = [dateFocusEvent, timeFocusEvent].filter(filterNull)
        handleBlur(events)
      }
    }
  }, [dateFocusEvent, timeFocusEvent, handleBlur, valueRef])

  const hasError = !!errorMessage
  const formInputWrapperTheme = {
    childContainer: classnames(styles.dateTimeInputWithToggle, { [styles.error]: hasError }),
  }

  return (
    <InputWrapper label={label} isDisabled={isDisabled} isRequired={isRequired} errorMessage={errorMessage} theme={formInputWrapperTheme}>
      <div
        onClick={e => {
          // Prevent clicks on the time input from accidentally triggering a label element click and setting focus on the first element in older iOS verions
          e.stopPropagation()
          e.preventDefault()
        }}>
        <MaskedInput
          mask={DATE_MASK}
          placeholder={dateFormat}
          value={value.date}
          isDisabled={isDisabled}
          hasError={hasError}
          handleChange={e => {
            const newDate = e.currentTarget.value
            handleChange({ ...value, date: newDate })
            moveFocusToNextInput(e.currentTarget)
          }}
          handleBlur={setDateFocusEvent}
          theme={dateInputTheme}
        />
        <MaskedInput
          mask={TIME_MASK}
          placeholder="HH:MM"
          value={value.time}
          isDisabled={isDisabled}
          hasError={hasError}
          handleChange={e => {
            const newTime = e.currentTarget.value
            handleChange({ ...value, time: newTime })
            moveFocusToNextInput(e.currentTarget)
          }}
          handleBlur={setTimeFocusEvent}
          theme={timeInputTheme}
        />
      </div>
      {children}
    </InputWrapper>
  )
}

export type DateTimeInputWithActualProps = CommonDateTimeInputProps & {
  toggleLabelOffText?: string
  toggleLabelOnText?: string
  value: DateTime
  handleChange: (dateTimeWithToggle: DateTime) => void
}

export const DateTimeInputWithActual: React.FC<DateTimeInputWithActualProps> = props => {
  const { date, time, isActual } = props.value
  return (
    <DateTimeInput {...props} value={{ date, time }} handleChange={dateTime => props.handleChange({ ...dateTime, isActual })}>
      <Toggle
        isChecked={props.value.isActual}
        labelOff={props.toggleLabelOffText}
        labelOn={props.toggleLabelOnText}
        disabled={props.isDisabled}
        handleToggle={isActual => props.handleChange({ ...props.value, isActual })}
        theme={toggleTheme}
      />
    </DateTimeInput>
  )
}
