import { DateSpecification } from './DateSpecification'
import { TimeZone, timeZoneUTC } from '../../state/TimeZone'
import { pipe } from 'fp-ts/es6/pipeable'
import { getOrElse, Option } from 'fp-ts/es6/Option'
import { zonedTimeToUtc } from 'date-fns-tz'

export function dateFromSpecification(spec: DateSpecification, timeZone: Option<TimeZone>): Date {
  const timeZoneInfo = pipe(
    timeZone,
    getOrElse<TimeZone>(() => timeZoneUTC)
  )
  const dateFromSpec = new Date(spec.year, spec.month - 1, spec.day, spec.hours, spec.minutes, spec.seconds, spec.milliseconds)

  return zonedTimeToUtc(dateFromSpec, timeZoneInfo)
}
