import React from 'react'
import styles from './SelectOptionItem.module.scss'
import classnames from 'classnames'

export type SelectOptionItemProps = Readonly<{
  isSelected: boolean
  isHighlighted: boolean
  itemProps: { [key: string]: any }
  children?: React.ReactNode
}>

export const SelectOptionItem = ({ isSelected, isHighlighted, itemProps, children }: SelectOptionItemProps) => (
  <li
    onClick={evt => evt.preventDefault()}
    className={classnames(styles.option, {
      [styles.highlight]: isHighlighted,
      [styles.selected]: isSelected,
    })}
    {...itemProps}>
    {children}
  </li>
)
