import React from 'react'
import styles, { Styles } from './TooltipMenu.module.scss'
import classnames from 'classnames'
import { triangleWidth } from './variables'
import { renderIf } from '../../utils/rendering'

export type TooltipMenuTheme = Partial<Styles>

type TooltipMenuProps = Readonly<{
  theme?: TooltipMenuTheme
  children?: React.ReactNode
  triangleOffsetLeft?: number
  triangleOffsetRight?: number
  showTriangle?: boolean
}>

export const TooltipMenu = (props: TooltipMenuProps) => {
  const theme = props.theme || {}
  const triangleOffsetLeft: 'auto' | number = props.triangleOffsetLeft !== undefined ? props.triangleOffsetLeft - triangleWidth / 2 : 'auto'
  const triangleOffsetRight: 'auto' | number = props.triangleOffsetRight !== undefined ? props.triangleOffsetRight - triangleWidth / 2 : 'auto'
  const { showTriangle = true } = props

  return (
    <div className={classnames(styles.tooltipMenu, theme.tooltipMenu)}>
      {renderIf(
        showTriangle,
        <div className={classnames(styles.triangleClip, theme.triangleClip)} style={{ marginLeft: triangleOffsetLeft, marginRight: triangleOffsetRight }}>
          <div className={classnames(styles.triangle, theme.triangle)} />
        </div>
      )}
      <div className={classnames(styles.content, theme.content)}>{props.children}</div>
    </div>
  )
}
