import { BerthVisitId } from '../state/IBerthVisit'
import { AllSortMethodType } from '../state/IUIState'
import { PrefilledFields } from '../components/BerthVisitForm/BerthVisitForm'
import { none, Option } from 'fp-ts/es6/Option'

export const OPEN_NEW_BERTH_VISIT_MODAL = 'OPEN_NEW_BERTH_VISIT_MODAL'
export interface IOpenNewBerthVisitModal {
  type: typeof OPEN_NEW_BERTH_VISIT_MODAL
  prefilledFields: Option<PrefilledFields>
}
export function openNewBerthVisitModal(prefilledFields: Option<PrefilledFields> = none): IOpenNewBerthVisitModal {
  return {
    type: OPEN_NEW_BERTH_VISIT_MODAL,
    prefilledFields,
  }
}

export const OPEN_EDIT_BERTH_VISIT_MODAL = 'OPEN_EDIT_BERTH_VISIT_MODAL'
export interface IOpenEditBerthVisitModal {
  type: typeof OPEN_EDIT_BERTH_VISIT_MODAL
  berthVisitId: BerthVisitId
}
export function openEditBerthVisitModal(berthVisitId: BerthVisitId): IOpenEditBerthVisitModal {
  return {
    type: OPEN_EDIT_BERTH_VISIT_MODAL,
    berthVisitId,
  }
}

export const CLOSE_BERTH_VISIT_ENTRY_MODAL = 'CLOSE_BERTH_VISIT_ENTRY_MODAL'
export interface ICloseBerthVisitEntryModal {
  type: typeof CLOSE_BERTH_VISIT_ENTRY_MODAL
}
export function closeBerthVisitEntryModal(): ICloseBerthVisitEntryModal {
  return {
    type: CLOSE_BERTH_VISIT_ENTRY_MODAL,
  }
}

export const CHANGE_BERTH_VISITS_SORT_METHOD = 'CHANGE_BERTH_VISITS_SORT_METHOD'
export interface IChangeBerthVisitSortMethod {
  type: typeof CHANGE_BERTH_VISITS_SORT_METHOD
  sortMethod: AllSortMethodType
}
export function changeBerthVisitSortMethod(sortMethod: AllSortMethodType): IChangeBerthVisitSortMethod {
  return {
    type: CHANGE_BERTH_VISITS_SORT_METHOD,
    sortMethod,
  }
}
