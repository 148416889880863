import { IStakeholder } from '../state/IStakeholder'

export const SET_STAKEHOLDERS = 'SET_STAKEHOLDERS'

export interface ISetStakeholdersAction {
  type: typeof SET_STAKEHOLDERS
  stakeholders: IStakeholder[]
}

export function setStakeholders(stakeholders: IStakeholder[]): ISetStakeholdersAction {
  return {
    type: SET_STAKEHOLDERS,
    stakeholders
  }
}
