import React, { useState } from 'react'
import classnames from 'classnames'
import styles from './Select.module.scss'
import { useCombobox, useMultipleSelection } from 'downshift'
import { MultiSelectOptionList } from './MultiSelectOptionList'
import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper'
import { FormElementProps } from '../shared/FormTypes'
import { SelectOption } from './Select'
import { Arrays } from '../../../utils/Arrays'

export type MultiSelectOption<Value> = Readonly<{
  label: string
  value: Value
  isSelected: boolean
}>

export type MultiSelectProps<Value> = InputWrapperProps &
  FormElementProps &
  Readonly<{
    options: SelectOption<Value>[]
    selectedOptions: SelectOption<Value>[]
    getKey: (item: SelectOption<Value>) => string
    handleChange: (options: SelectOption<Value>[]) => void
    handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  }>

export function MultiSelect<Value>(props: MultiSelectProps<Value>) {
  const itemsAreEqual = (left: SelectOption<Value>, right: SelectOption<Value>) => {
    return props.getKey(left).valueOf() === props.getKey(right).valueOf()
  }

  const sortBySelected = (left: MultiSelectOption<Value>, right: MultiSelectOption<Value>) => {
    if (left.isSelected === right.isSelected) {
      return 0
    }

    return left.isSelected ? -1 : 1
  }

  const [options, setOptions] = useState(
    props.options.map(option => ({ ...option, isSelected: Arrays.includes(props.selectedOptions, option) })).sort(sortBySelected)
  )
  const [dropdownItems, setDropdownItems] = useState<MultiSelectOption<Value>[]>(options)
  const { getDropdownProps, selectedItems, setSelectedItems } = useMultipleSelection<MultiSelectOption<Value>>({
    initialSelectedItems: props.selectedOptions.map(option => ({ ...option, isSelected: true })),
  })

  const { isOpen, getMenuProps, getInputProps, getComboboxProps, inputValue, setInputValue, highlightedIndex, getItemProps, toggleMenu } = useCombobox<
    MultiSelectOption<Value>
  >({
    items: dropdownItems,
    itemToString: item => (item ? item.label : ''),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          }
      }
      return {
        ...changes,
        selectedItem: null,
      }
    },
    onStateChange: ({ inputValue, selectedItem, type, highlightedIndex, isOpen }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          const filteredUpdatedDropdownItems = options.filter(item =>
            inputValue && inputValue !== '' ? item.label.toLowerCase().startsWith(inputValue.toLowerCase()) : true
          )
          inputValue && setInputValue(inputValue)
          setDropdownItems(filteredUpdatedDropdownItems)
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            const updatedOptions = [...options.map(option => (itemsAreEqual(option, selectedItem) ? { ...option, isSelected: !option.isSelected } : option))]
            setOptions(updatedOptions)
            const updatedDropdownItems = [...updatedOptions].sort(sortBySelected)
            setDropdownItems(updatedDropdownItems)
            setSelectedItems(updatedOptions.filter(option => option.isSelected))
            props.handleChange(updatedOptions.filter(option => option.isSelected))
            setInputValue('')
          }
          break
        default:
          break
      }
    },
  })

  return (
    <InputWrapper label={props.label} isRequired={props.isRequired} isDisabled={props.isDisabled} errorMessage={props.errorMessage}>
      <div className={styles.container} {...getComboboxProps()}>
        <input
          className={classnames(styles.input, { [styles.error]: !!props.errorMessage })}
          {...getInputProps(
            getDropdownProps({
              onClick: () => toggleMenu(),
              placeholder: !isOpen ? `${selectedItems.length} selected` : '',
              value: isOpen ? inputValue : '',
            })
          )}
        />

        <MultiSelectOptionList
          getKey={props.getKey}
          options={dropdownItems}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedIndex={highlightedIndex}
          isOpen={isOpen}
        />
      </div>
    </InputWrapper>
  )
}
