import React from 'react'
import classnames from 'classnames'
import styles, { Styles } from './Input.module.scss'

type InputTheme = Partial<Styles>

type InputProps = Readonly<{
  id?: string
  type?: string
  name?: string
  defaultValue?: string
  value?: string
  placeholder?: string
  isDisabled?: boolean
  hasError?: boolean
  theme?: InputTheme
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}>

export const Input: React.FC<InputProps> = ({ id, type, name, defaultValue, value, placeholder, isDisabled, hasError, theme, handleChange, handleBlur }) => (
  <input
    className={classnames(styles.input, {[styles.error]: hasError}, theme?.input)}
    id={id}
    type={type}
    name={name}
    defaultValue={defaultValue}
    value={value}
    placeholder={placeholder}
    disabled={isDisabled}
    onChange={handleChange}
    onBlur={handleBlur}
  />
)
