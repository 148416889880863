import React from 'react'
import { Modal } from '../Modal/Modal'
import { useSelector } from 'react-redux'
import { berthVisitsSelector } from '../../selectors/berthVisitsSelector'
import { pipe } from 'fp-ts/lib/pipeable'
import { mapNullable, toUndefined } from 'fp-ts/lib/Option'
import { ModalHeader } from '../Modal/Header/ModalHeader'
import { BerthVisitForm, PrefilledFields } from '../BerthVisitForm/BerthVisitForm'
import { IBerthVisit } from '../../state/IBerthVisit'
import { BerthVisitEntryState, BerthVisitEntryKind } from '../../state/IUIState'

type BerthVisitEntryModalProps = Readonly<{
  berthVisitEntryState: BerthVisitEntryState
  onClose: () => void
}>

type BerthVisitModalContentProps = Readonly<{
  onClose: () => void
  prefilledFields?: PrefilledFields
  berthVisit?: IBerthVisit
}>

const BerthVisitModalContent = ({ onClose, berthVisit, prefilledFields }: BerthVisitModalContentProps) => (
  <>
    <ModalHeader title={berthVisit ? 'Edit berth visit' : 'New berth visit'} onCloseButtonClick={onClose} />
    <BerthVisitForm berthVisit={berthVisit} prefilledFields={prefilledFields} />
  </>
)

export const BerthVisitEntryModal: React.FC<BerthVisitEntryModalProps> = ({ berthVisitEntryState, onClose }) => {
  const allBerthVisits = useSelector(berthVisitsSelector)

  const renderModalContent = () => {
    switch (berthVisitEntryState.kind) {
      case BerthVisitEntryKind.New:
        return <BerthVisitModalContent onClose={onClose} prefilledFields={toUndefined(berthVisitEntryState.prefilledFields)} />
      case BerthVisitEntryKind.Edit:
        const berthVisitToEdit = pipe(
          allBerthVisits,
          mapNullable(berthVisits => berthVisits.find(berthVisit => berthVisit.id === berthVisitEntryState.berthVisitId))
        )

        return <BerthVisitModalContent onClose={onClose} berthVisit={toUndefined(berthVisitToEdit)} />
      default:
        const exhaustive: never = berthVisitEntryState
        throw new Error(exhaustive)
    }
  }

  return (
    <Modal onClose={onClose} closeOnClickOutside={false}>
      {renderModalContent()}
    </Modal>
  )
}
