import React, { useState } from 'react'
import { renderIf } from '../../utils/rendering'
import { ErrorHeader } from '../Dialog/ErrorHeader'
import { Dialog } from '../Dialog/Dialog'
import { ErrorFooter } from '../Dialog/ErrorFooter'
import { Button } from '../Form/Button/Button'

type AuthenticationErrorProps = Readonly<{ error: { description: string } }>

export const AuthenticationError = ({ error }: AuthenticationErrorProps) => {
  const [errorDialogOpen, setErrorDialogOpen] = useState(true)

  return renderIf(
    errorDialogOpen,
    <Dialog>
      <ErrorHeader>Authentication failed</ErrorHeader>
      <p>{error.description}</p>
      <p>Try logging in again.</p>
      <ErrorFooter>
        <Button
          text="close"
          type="button"
          onClick={() => {
            setErrorDialogOpen(false)
          }}
        />
      </ErrorFooter>
    </Dialog>
  )
}
