import React, { useCallback } from 'react'
import { AuthProvider, useAuth0Utils } from '../context/Auth0Context'
import { connect } from 'react-redux'
import { VesselPlannerDispatch } from '../thunk'
import { login, logout } from '../actions/authActions'
import { initializeThunk } from '../actions/thunks'
import { Loader } from '../components/Loader/Loader'
import { LOADING } from '../components/Loader/ILoaderState'
import { useMixpanel } from '../context/MixpanelContext'

type AuthGateProps = Readonly<{
  onLogin: (token: string) => void
  onLogout: () => void
  onRefreshToken: (token: string) => void
}>

const AuthGateComponent: React.FC<AuthGateProps> = ({ onLogin, onLogout, onRefreshToken, children }) => {
  const authUtils = useAuth0Utils()
  const { resetTracking } = useMixpanel()

  const handleLogout = useCallback(() => {
    resetTracking()
    onLogout()
  }, [resetTracking, onLogout])

  if (authUtils) {
    return (
      <AuthProvider authUtils={authUtils} urlQuery={window.location.search} onLogin={onLogin} onLogout={handleLogout} onRefreshToken={onRefreshToken}>
        {children}
      </AuthProvider>
    )
  } else {
    return <Loader state={{ type: LOADING }} />
  }
}

const mapDispatchToProps = (dispatch: VesselPlannerDispatch): AuthGateProps => {
  return {
    onLogin: (token: string) => {
      dispatch(login(token))
      dispatch(initializeThunk(token))
    },
    onLogout: () => {
      dispatch(logout())
    },
    onRefreshToken: (token: string) => dispatch(login(token)),
  }
}

export const AuthGate = connect(null, mapDispatchToProps)(AuthGateComponent)
