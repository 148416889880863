import { useMixpanel } from './../../context/MixpanelContext'
import { useDispatch, useSelector } from 'react-redux'
import { selectedTimeFilterSelector } from '../../selectors/selectedTimeFilterSelector'
import { changeSelectedTimeFilter } from '../../actions/viewAgnosticActions'
import { getBerthVisitsThunk } from '../../actions/thunks'
import { updateBerthVisitsAction } from '../../actions/berthVisitsActions'

enum TimeFilter {
  FILTER_24_HOURS = '24',
  FILTER_48_HOURS = '48',
  FILTER_72_HOURS = '72',
  FILTER_1_WEEK = '168',
  FILTER_2_WEEKS = '336',
  FILTER_3_WEEKS = '504',
}

export type TimeRangeOptions = Record<TimeFilter, string>

const timeRanges: TimeRangeOptions = {
  [TimeFilter.FILTER_24_HOURS]: '-24 hours',
  [TimeFilter.FILTER_48_HOURS]: '-48 hours',
  [TimeFilter.FILTER_72_HOURS]: '-72 hours',
  [TimeFilter.FILTER_1_WEEK]: '-1 week',
  [TimeFilter.FILTER_2_WEEKS]: '-2 weeks',
  [TimeFilter.FILTER_3_WEEKS]: '-3 weeks',
}

export const useSelectedTimeRange = () => {
  const dispatch = useDispatch()
  const { trackEvent } = useMixpanel()
  const selectedTimeRange = useSelector(selectedTimeFilterSelector)

  const changeTimeRange = ({ label: filterString, value: numberOfHours }: { label: string; value: number }) => {
    dispatch(changeSelectedTimeFilter(numberOfHours))
    dispatch(getBerthVisitsThunk(numberOfHours, updateBerthVisitsAction))
    trackEvent('Time range', { filter: filterString })
  }

  return { selectedTimeRange, changeTimeRange, timeRanges }
}
