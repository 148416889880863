import React, { useState, useCallback } from 'react'
import { constNull } from 'fp-ts/es6/function'
import styles from './PortalModal.module.scss'
import { none, Option, fold, some } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/lib/pipeable'
import { ModalHeader } from '../Modal/Header/ModalHeader'

type ModalProps = { onClose: () => void; title: string }
export const PortalModal: React.FC<ModalProps> = ({ onClose, title, children }) => (
  <div className={styles.modal}>
    <ModalHeader title={title} onCloseButtonClick={onClose} />
    {children}
  </div>
)

function useContent() {
  const [content, setContent] = useState<Option<React.ReactElement>>(none)

  const clearContent = useCallback(() => {
    setContent(none)
  }, [])

  return { content, clearContent, showContent: setContent }
}

export function usePortalModal() {
  const { content: contentOption, clearContent: hideModal, showContent } = useContent()

  const Container: React.FC = useCallback(
    () =>
      pipe(
        contentOption,
        fold(constNull, content => <ModalContainer handleClose={hideModal}>{content}</ModalContainer>)
      ),
    [contentOption, hideModal]
  )

  return {
    showModal: (el: React.ReactElement) => showContent(some(el)),
    ModalContainer: Container,
    hideModal,
  }
}

const ModalContainer: React.FC<{ handleClose: () => void }> = ({ children, handleClose }) => (
  <div className={styles.modalParent} onClick={e => e.stopPropagation()} aria-hidden="true">
    <div className={styles.backdrop} onClick={handleClose} aria-hidden="true" />
    {children}
  </div>
)
