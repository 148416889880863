import debounce from 'redux-storage-decorator-debounce'
import * as storage from 'redux-storage'
import filter from 'redux-storage-decorator-filter'
import createEngine, { LocalStorageEngine } from 'redux-storage-engine-localstorage'
import createDebug from 'debug'
import { IAppState } from '../state/IAppState'

export const CURRENT_VERSION = 1

export const debugVersioning = createDebug('vesselPlanner:versioning')

// Configure Storage to load the following properties from storage and apply as middleware
function checkPathExists<K1 extends keyof IAppState>(k1: K1): K1
function checkPathExists<K1 extends keyof IAppState, K2 extends keyof IAppState[K1]>(k1: K1, k2: K2): [K1, K2]
function checkPathExists(...ks: string[]): any {
  if (ks.length === 1) {
    return ks[0]
  } else {
    return ks
  }
}

const stateKeysToSaveInStorage = [
  checkPathExists('authToken'),
  checkPathExists('version')
]

export const storageEngine = filter(versionedEngine(createEngine('vesselPlanner')), stateKeysToSaveInStorage)

function versionedEngine(toWrap: LocalStorageEngine): LocalStorageEngine {

  return {
    load(): PromiseLike<any> {
      return toWrap.load().then((stateFromLocalStorage: IAppState) => {
        const versionInLocalStorage = stateFromLocalStorage && stateFromLocalStorage.version
        if (versionInLocalStorage === undefined || versionInLocalStorage < CURRENT_VERSION) {
          debugVersioning('Ignoring state in local storage, because the state shape has changed since then')
          return {}
        } else {
          // It's the current version.
          return stateFromLocalStorage
        }
      })
    },

    save(state: any): PromiseLike<any> {
      return toWrap.save(state)
    }
  }
}

export const storageMiddleWare = storage.createMiddleware(debounce(storageEngine, 1500))
