import React from 'react'
import { IBerthVisitDate } from '../../state/IBerthVisit'
import { TimeZone } from '../../state/TimeZone'
import { formatBerthVisitDate } from '../../utils/dates/berthVisitDate'
import { LabelledCell, LabelledCellTheme } from './LabelledCell'
import { useLocalisation } from '../../context/LocalisationContext'
import { Option, fold, fromNullable, none } from 'fp-ts/es6/Option'
import styles from './ActivityTimingView.module.scss'
import classnames from 'classnames'
import { pipe } from 'fp-ts/es6/pipeable'
import { constFalse } from 'fp-ts/es6/function'

export const ArrivalLabel = 'A'
export const DepartureLabel = 'D'
export const StartCargoLabel = 'S'
export const CompleteCargoLabel = 'C'

const activityLabelTheme = (timing: Option<IBerthVisitDate>): LabelledCellTheme => ({
  value: classnames(styles.value, { [styles.valueUnknown]: timing === none }),
  label: classnames(styles.label, {
    [styles.labelActual]: pipe(
      timing,
      fold(constFalse, t => t.isActual)
    ),
  }),
})

type ActivityLabel = typeof ArrivalLabel | typeof DepartureLabel | typeof StartCargoLabel | typeof CompleteCargoLabel

type ActivityTimingViewProps = Readonly<{
  activityTiming: IBerthVisitDate | null
  timeZone: Option<TimeZone>
  activityLabel: ActivityLabel
}>

const activityLabelToTiming = (timing: Option<IBerthVisitDate>, label: ActivityLabel) => {
  const isActual = pipe(
    timing,
    fold(constFalse, t => t.isActual)
  )

  return isActual ? `AT${label}` : `ET${label}`
}

export const ActivityTimingView = ({ activityTiming, timeZone, activityLabel }: ActivityTimingViewProps) => {
  const { dateFormat } = useLocalisation()
  const formattedDate = formatBerthVisitDate(activityTiming, timeZone, dateFormat)
  const timingOption = fromNullable(activityTiming)
  const timing = activityLabelToTiming(timingOption, activityLabel)

  return <LabelledCell label={timing} value={`${formattedDate.date} ${formattedDate.time}`} theme={activityLabelTheme(timingOption)} />
}

export const DateOnlyActivityTimingView = ({ date, timeZone, activityLabel }: { date: Date; timeZone: Option<TimeZone>; activityLabel: ActivityLabel }) => {
  const { dateFormat } = useLocalisation()
  const formattedDate = pipe(formatBerthVisitDate({ isActual: false, date }, timeZone, dateFormat), ({ date, time }) => `${date} ${time}`)

  return (
    <LabelledCell
      label={`ET${activityLabel}`}
      value={formattedDate}
      theme={{ value: classnames(styles.value), label: classnames(styles.label, styles.labelDate) }}
    />
  )
}
