import React from 'react'
import { notFalse } from '../../utils/predicates'
import { DeploymentEnvironment, DEPLOYMENT_ENVIRONMENT } from '../../config'
import { IAppState } from '../../state/IAppState'
import { connect, useSelector } from 'react-redux'
import { Option, fold } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'
import { decodedTokenSelector } from '../../selectors/tokenSelector'
import { decodeAuthToken, DecodedToken } from '../../utils/tokenUtils'
import { renderIf } from '../../utils/rendering'
import { constant } from 'fp-ts/es6/function'

export type FeatureToggleCheck = (env: DeploymentEnvironment, decodedToken: DecodedToken) => boolean

interface IFeatureToggle {
  decodedToken: Option<DecodedToken>
  checks: FeatureToggleCheck[]
  children: React.ReactNode
}

const resolveChecks = (token: DecodedToken, checks: FeatureToggleCheck[]) => checks.map(check => check(DEPLOYMENT_ENVIRONMENT, token)).every(notFalse)

const FeatureToggleComponent: React.FC<IFeatureToggle> = ({ decodedToken, checks, children }) => {
  return renderIf(
    pipe(
      decodedToken,
      fold(constant(false), token => resolveChecks(token, checks))
    ),
    <>{children}</>
  )
}

const mapStateToProps = (state: IAppState) => ({
  decodedToken: decodedTokenSelector(state),
})

export const FeatureToggle = connect(mapStateToProps)(FeatureToggleComponent)

export const useFeatureToggle = (checks: FeatureToggleCheck[]): boolean => {
  const decodedToken = useSelector(decodedTokenSelector)
  return pipe(
    decodedToken,
    fold(constant(false), token => resolveChecks(token, checks))
  )
}

export const getFeatureToggleFromAuthToken = (authToken: string) => (checks: FeatureToggleCheck[]): boolean => {
  const decodedToken = decodeAuthToken(authToken)
  return resolveChecks(decodedToken, checks)
}
