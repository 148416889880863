import React from 'react'
import styles, { Styles } from './LabelledCell.module.scss'
import classnames from 'classnames'

export type LabelledCellTheme = Partial<Styles>

type LabelledCellProps = Readonly<{
  label: string
  value: string
  theme?: LabelledCellTheme
}>

export const LabelledCell: React.FC<LabelledCellProps> = ({ label, value, theme }) => (
  <div className={classnames(styles.wrapper, theme?.wrapper)}>
    <div className={classnames(styles.label, theme?.label)}>{label}</div>
    <div className={classnames(styles.value, theme?.value)}>{value}</div>
  </div>
)
