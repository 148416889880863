import React from 'react'

const HamburgerIcon = () => (
  <svg viewBox="0 0 21 20" width="1em" height="1em">
    <title>Menu</title>
    <path d="M0 0h21v2H0zM0 9h21v2H0zM0 18h21v2H0z" fill="#001F4B" />
  </svg>
)

export default HamburgerIcon
