import { usePostStartSession } from './../../rest/postStartSession'
import { pipe } from 'fp-ts/lib/pipeable'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useMixpanel } from './../../context/MixpanelContext'
import { userSelector } from '../../selectors/userSelector'
import { COMPANY_PROP, VESSELS_ADDED_PROP } from '../../utils/mixpanel/mixpanelSuperPropConstants'
import { map } from 'fp-ts/lib/Option'

export const useTrackUser = () => {
  const user = useSelector(userSelector)
  const { setupUser, trackEvent, setTrackingSuperProperties } = useMixpanel()
  const [isSessionStarted, setIsSessionStarted] = useState(false)
  const postStartSession = usePostStartSession()

  useEffect(() => {
    pipe(
      user,
      map(({ email, company }) => {
        setupUser(email, {}, () => {
          setTrackingSuperProperties({
            [COMPANY_PROP]: company.name,
            [VESSELS_ADDED_PROP]: 0,
          })
          trackEvent('User login')
        })
      })
    )
  }, [user, setupUser, trackEvent, setTrackingSuperProperties])

  useEffect(() => {
    if (user && !isSessionStarted) {
      postStartSession().then(setIsSessionStarted)
    }
  }, [isSessionStarted, user, postStartSession])
}
