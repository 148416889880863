import { DateSpecification } from './DateSpecification'
import { TimeZone, timeZoneUTC } from '../../state/TimeZone'
import { pipe } from 'fp-ts/es6/pipeable'
import { getOrElse, Option } from 'fp-ts/es6/Option'
import { utcToZonedTime } from 'date-fns-tz'

export function toDateSpecification(date: Date, timeZone: Option<TimeZone>): DateSpecification {
  const timeZoneInfo = pipe(
    timeZone,
    getOrElse<TimeZone>(() => timeZoneUTC)
  )
  const zonedTime = utcToZonedTime(date, timeZoneInfo)

  return {
    year: zonedTime.getFullYear(),
    month: zonedTime.getMonth() + 1,
    day: zonedTime.getDate(),
    hours: zonedTime.getHours(),
    minutes: zonedTime.getMinutes(),
    seconds: zonedTime.getSeconds() ?? 0,
    milliseconds: zonedTime.getMilliseconds() ?? 0,
  }
}
