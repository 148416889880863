import { AllSortMethods, AllSortMethodType } from './../state/IUIState'
import { ILayByBerth, LayByBerthStatus } from '../Domain/LayByBerth'
import { sequenceT } from 'fp-ts/es6/Apply'
import { IShipId } from '../state/IShip'
import { BerthVisitId, IBerthVisit, IBerthVisitDate } from '../state/IBerthVisit'
import { IBerth, ITerminal } from '../state/IBerth'
import { getStartBollardOrElse, getEndBollardOrElse } from '../utils/Bollards'
import { TimeZone } from '../state/TimeZone'
import { Option, map, fromNullable, option } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'
import { Bollards } from '../state/Bollards'

export enum TableRowType {
  BERTH_VISIT = 'berth-visit',
  LAY_BY_BERTH = 'lay-by-berth',
}
export interface IBerthVisitTableRow {
  type: TableRowType.BERTH_VISIT
  id: BerthVisitId
  shipId: IShipId
  berthName: string
  berthTerminalName: string
  bollardFore: string
  bollardAft: string
  orientation: string
  arrivalBerth: IBerthVisitDate | null
  startCargo: IBerthVisitDate | null
  completeCargo: IBerthVisitDate | null
  departureBerth: IBerthVisitDate | null
  timeZone: Option<TimeZone>
  remark: string | null
}

export interface ILayByBerthTableRow {
  type: TableRowType.LAY_BY_BERTH
  id: string
  status: LayByBerthStatus
  berthName: string
  berthTerminalName: string
  bollardFore: string
  bollardAft: string
  arrivalBerth: IBerthVisitDate
  departureBerth: IBerthVisitDate
  timeZone: Option<TimeZone>
}

export type ITableRow = IBerthVisitTableRow | ILayByBerthTableRow

export const isBerthVisitRow = (row: ITableRow): row is IBerthVisitTableRow => row.type === TableRowType.BERTH_VISIT
export const isLayByBerthRow = (row: ITableRow): row is ILayByBerthTableRow => row.type === TableRowType.LAY_BY_BERTH

function toBerthVisitTableRow(berthVisit: IBerthVisit, berths: IBerth[], terminals: ITerminal[]): IBerthVisitTableRow {
  const berth = berths.find(b => b.uuid === berthVisit.berthUuid)
  const terminal = terminals.find(t => t.terminalUuid === berthVisit.terminalUuid)
  const terminalName = (berth && berth.terminalName) || (terminal && terminal.terminalName)
  const timeZone = fromNullable(berth?.timeZone || terminal?.timeZone)

  return {
    type: TableRowType.BERTH_VISIT,
    id: berthVisit.id,
    shipId: berthVisit.ship,
    berthName: berth === undefined ? 'Unknown berth' : berth.name,
    berthTerminalName: terminalName ? terminalName : 'Unknown terminal name',
    bollardFore: getStartBollardOrElse(berthVisit.bollards, '...').toString(),
    bollardAft: getEndBollardOrElse(berthVisit.bollards, '...').toString(),
    orientation: berthVisit.orientation,
    arrivalBerth: berthVisit.arrivalBerth,
    startCargo: berthVisit.startCargo,
    completeCargo: berthVisit.completeCargo,
    departureBerth: berthVisit.departureBerth,
    timeZone,
    remark: berthVisit.remark,
  }
}

function toLayByBerthTableRow(layByBerth: ILayByBerth, terminals: ITerminal[], berths: IBerth[]): ILayByBerthTableRow {
  const bollards: Bollards = {
    start: layByBerth.bollardStart || null,
    end: layByBerth.bollardEnd || null,
  }
  const terminalName = terminals.find(t => t.terminalUuid === layByBerth.asset.terminalUuid)?.terminalName ?? '--'
  const berth = berths.find(b => b.uuid === layByBerth.asset.berthUuid)

  return {
    type: TableRowType.LAY_BY_BERTH,
    id: layByBerth.uuid,
    status: layByBerth.status,
    berthName: layByBerth.asset.name,
    berthTerminalName: terminalName,
    bollardFore: getStartBollardOrElse(bollards, '--').toString(),
    bollardAft: getEndBollardOrElse(bollards, '--').toString(),
    arrivalBerth: { date: new Date(layByBerth.startTime), isActual: false },
    departureBerth: { date: new Date(layByBerth.endTime), isActual: false },
    timeZone: fromNullable(berth?.timeZone),
  }
}

export function toBerthVisitTableRows(
  berthVisits: Option<IBerthVisit[]>,
  layByBerths: Option<ILayByBerth[]>,
  berths: IBerth[],
  terminals: ITerminal[],
  sortMethod: AllSortMethodType
): Option<{ berthVisits: IBerthVisitTableRow[]; layByBerths: ILayByBerthTableRow[]; combinedRows: ITableRow[] }> {
  return pipe(
    sequenceT(option)(berthVisits, layByBerths),
    map(([bvs, lbbs]) => {
      const bvRows: IBerthVisitTableRow[] = bvs.map(bv => toBerthVisitTableRow(bv, berths, terminals))
      const lbbRows: ILayByBerthTableRow[] = lbbs.map(lbb => toLayByBerthTableRow(lbb, terminals, berths))

      return { berthVisits: bvRows, layByBerths: lbbRows, combinedRows: [...bvRows, ...lbbRows] }
    }),
    map(({ combinedRows, ...rest }) => ({
      ...rest,
      combinedRows: AllSortMethods[sortMethod].sorter(combinedRows),
    }))
  )
}
