import React, { useCallback } from 'react'
import { PortalModal, usePortalModal } from '../PortalModal/PortalModal'
import { CreateLayByBerthForm, EditLayByBerthForm } from './LayByBerthForm'
import { useSelector } from 'react-redux'
import { layByBerthsSelector } from '../../selectors/layByBerthsSelector'
import { pipe } from 'fp-ts/es6/pipeable'
import { fromNullable, map } from 'fp-ts/es6/Option'
import { chain } from 'fp-ts/lib/Option'

export const useLayByBerthEntryModal = () => {
  const { ModalContainer, showModal, hideModal } = usePortalModal()
  const layByBerths = useSelector(layByBerthsSelector)

  const createLayByBerthHandler = useCallback(() => {
    showModal(
      <PortalModal title="Create Lay-by berth" onClose={hideModal}>
        <CreateLayByBerthForm onClose={hideModal} />
      </PortalModal>
    )
  }, [hideModal, showModal])

  const editLayByBerthHandler = useCallback(
    (id: string) => {
      pipe(
        layByBerths,
        chain(lbbs => fromNullable(lbbs.find(({ uuid }) => uuid === id))),
        map(layByBerth => {
          showModal(
            <PortalModal title="Edit Lay-by berth" onClose={hideModal}>
              <EditLayByBerthForm layByBerth={layByBerth} onClose={hideModal} />
            </PortalModal>
          )
        })
      )
    },
    [hideModal, showModal, layByBerths]
  )

  return { LayByBerthModalContainer: ModalContainer, createLayByBerthHandler, editLayByBerthHandler }
}
