import * as React from 'react'
import styles, { Styles } from './Checkbox.module.scss'
import { ReactComponent as CheckIcon } from './icons/check.svg'
import classnames from 'classnames'
import { FormElementProps } from '../shared/FormTypes'
import { Toggleable, ToggleableTheme } from '../shared/Toggleable'

export type CheckboxTheme = Partial<Styles>

const checkboxTheme = (theme: CheckboxTheme | undefined): ToggleableTheme => ({
  toggle: classnames(styles.checkBox, theme?.checkBox),
  toggleLabel: classnames(styles.checkBoxLabel, theme?.checkBoxLabel),
})

type CheckboxProps = FormElementProps &
  Readonly<{
    checked: boolean
    theme?: CheckboxTheme
    onChange: (checked: boolean) => void
  }>

export const Checkbox: React.FC<CheckboxProps> = ({ checked, label, onChange, name, theme }): JSX.Element => {
  return (
    <Toggleable type="checkbox" checked={checked} name={name} label={label} onChange={onChange} theme={checkboxTheme(theme)}>
      {() => (
        <>
          {checked && <CheckIcon className={classnames(styles.checkboxIcon, theme?.checkboxIcon)} />}
          <span className={classnames(styles.checkBoxLabelInner, theme?.checkBoxLabelInner)}>{label}</span>
        </>
      )}
    </Toggleable>
  )
}
