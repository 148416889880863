import React from 'react'
import menuStyles from './SelectOptionList.module.scss'
import optionStyles from './SelectOptionItem.module.scss'
import classnames from 'classnames'

export const SelectFeedback: React.FC = ({ children }) => {
  return (
    <div className={classnames(menuStyles.menu, menuStyles.open)} aria-live="polite">
      <div className={optionStyles.option}>{children}</div>
    </div>
  )
}
