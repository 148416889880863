import React from 'react'
import styles from './ModalHeader.module.scss'
import { CloseButton } from '../../CloseButton/CloseButton'

type ModalHeaderProps = Readonly<{
  onCloseButtonClick: () => void
  title: string
}>

export const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onCloseButtonClick }) => (
  <header className={styles.modalHeader}>
    <h2 className={styles.title}>{title}</h2>
    <CloseButton onClick={onCloseButtonClick} />
  </header>
)
