import { DateTime } from '../Form/DateTimeInput/DateTimeInput'
import { getOrElse, Option } from 'fp-ts/es6/Option'
import { TimeZone } from '../../state/TimeZone'
import { Either, left, right } from 'fp-ts/es6/Either'
import { pipe } from 'fp-ts/es6/pipeable'
import { none, None } from '../../utils/strictNull'
import { parseLocalDateTimeString } from '../../utils/dates/dates'

export const toDate = (dateTime: DateTime, timeZone: Option<TimeZone>, dateFormat: string): Either<null, Date> => {
  const tz = pipe(
    timeZone,
    getOrElse<TimeZone | None>(() => none)
  )
  const validatedDate = parseLocalDateTimeString(dateTime.date, dateTime.time, new Date(), tz, dateFormat)

  return validatedDate.isValid() ? right(validatedDate.value) : left(null)
}
