import { IError } from '../state/IError'

export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export interface ISetErrorAction {
  type: typeof SET_ERROR
  error: IError
}

export const setError = (error: IError): ISetErrorAction => ({
  type: SET_ERROR,
  error,
})

export interface IClearErrorAction {
  type: typeof CLEAR_ERROR
}
export const clearError = (): IClearErrorAction => ({
  type: CLEAR_ERROR,
})
