import React from 'react'
import { InputLabel } from '../InputLabel/InputLabel'
import { InputErrorMessage } from '../InputErrorMessage/InputErrorMessage'

export type InputWrapperProps = {
  label: string
  isRequired?: boolean
  isDisabled?: boolean
  errorMessage?: string
  theme?: InputWrapperTheme
}

type InputWrapperTheme = Readonly<{
  childContainer: string
}>

export const InputWrapper: React.FC<InputWrapperProps> = ({ label, isRequired, isDisabled, errorMessage, theme, children }) => (
  <div>
    <InputLabel text={label} isRequired={isRequired} isDisabled={isDisabled}>
      <div className={theme?.childContainer}>{children}</div>
    </InputLabel>
    {errorMessage && <InputErrorMessage text={errorMessage} />}
  </div>
)
