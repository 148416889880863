import React from 'react'
import { SelectOptionList } from './SelectOptionList'
import { SelectOptionItem } from './SelectOptionItem'
import { SelectMenuChildProps } from './Select'

export const StandardOptionList = <Value extends {}>({
  isOpen,
  getMenuProps,
  getItemProps,
  getKey,
  highlightedIndex,
  selectedItem,
  options,
}: SelectMenuChildProps<Value>) => (
  <SelectOptionList isOpen={isOpen} menuProps={getMenuProps()}>
    {options.map((item, index) => (
      <SelectOptionItem
        itemProps={getItemProps({
          item: item,
          index: index,
          onClick: evt => evt.preventDefault(),
        })}
        key={getKey(item)}
        isHighlighted={index === highlightedIndex}
        isSelected={item === selectedItem}>
        {item.label}
      </SelectOptionItem>
    ))}
  </SelectOptionList>
)
