import { AppAction } from '../actions/AppAction'
import { IStakeholder } from '../state/IStakeholder'
import { SET_STAKEHOLDERS } from '../actions/stakeholdersActions'

export function stakeholdersReducer(stakeholders: IStakeholder[] = [], action: AppAction): IStakeholder[] {
  switch (action.type) {
    case SET_STAKEHOLDERS:
      return action.stakeholders
    default:
      return stakeholders
  }
}
