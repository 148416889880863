import { User } from '../state/User'
import { AppAction } from '../actions/AppAction'
import { SET_USER } from '../actions/setUserAction'
import { LOGOUT } from '../actions/authActions'

export function userReducer(user: User | undefined = undefined, action: AppAction): User | undefined {
  switch (action.type) {
    case SET_USER:
      return action.user
    case LOGOUT:
      return undefined
    default:
      return user
  }
}
