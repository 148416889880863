export type WithDefault<O, Default> = {
  [K in keyof O]: O[K] | Default
}

export function keys<O extends object>(o: O): Array<keyof O> {
  return Object.keys(o) as Array<keyof O>
}

export function values<O extends object>(o: O): Array<O[keyof O]> {
  return keys(o).map(key => o[key])
}

export function entries<O extends object>(o: O): Array<[keyof O, O[keyof O]]> {
  return keys(o).map((key: keyof O) => [key, o[key]])
}

export function omitKey<O extends object, K extends keyof O>(object: O, prop: K): Omit<O, K> {
  const { [prop]: removed, ...rest } = object

  return rest
}

export function getKey<O extends object, K extends keyof O>(o: O | undefined | null, k: K) {
  return o && k in o ? o[k] : null
}
