import React from 'react'
import { TextInput, TextInputProps } from './TextInput'
import { FieldAttributes, useField } from 'formik'

export type FormikTextInputProps = FieldAttributes<Omit<TextInputProps, 'name' | 'value' | 'handleChange' | 'handleBlur' | 'errorMessage'>>
export const FormikTextInput: React.FC<FormikTextInputProps> = props => {
  const [field, meta] = useField<string>(props)
  const errorMessage = meta.error && meta.touched ? meta.error : undefined

  return (
    <TextInput
      name={field.name}
      value={field.value ? field.value : ''}
      handleChange={field.onChange}
      handleBlur={field.onBlur}
      errorMessage={errorMessage}
      {...props}
    />
  )
}
