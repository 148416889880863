import React, { useState, useEffect, useRef } from 'react'
import styles from './Tooltip.module.scss'
import classnames from 'classnames'
import { renderIf } from '../../utils/rendering'
import { tooltipHelpIconSize } from './variables'
import { TooltipMenu, TooltipMenuTheme } from '../TooltipMenu/TooltipMenu'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { useClickOutside } from '../DropoutMenu/useClickOutside'

export enum PopupSide {
  TopCenter,
  TopRight,
  BottomRight,
  BottomLeft,
  TopLeft,
}

type ExplanationTooltip = Readonly<{
  children?: React.ReactNode
  side: PopupSide
  isActive?: boolean
  openByDefault?: boolean
  customToggleElement?: React.ReactElement
  noCloseIcon?: boolean
}>

export const Tooltip = ({ isActive = false, openByDefault, side, customToggleElement, noCloseIcon, children }: ExplanationTooltip) => {
  const wasOpenByDefault = useRef(openByDefault)
  const [isOpen, setIsOpen] = useState(isActive)
  const { onRef } = useClickOutside(() => setIsOpen(false))
  const tooltipRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (tooltipRef.current) {
      onRef(tooltipRef)
    }
  }, [tooltipRef, onRef])

  // Facilitate the option to open/close by manipulating `props.isActive`
  useEffect(() => {
    setIsOpen(isActive)
  }, [isActive])

  // Facilitate the option to open (once) by default by setting `props.openByDefault`
  useEffect(() => {
    if (wasOpenByDefault.current) {
      setIsOpen(wasOpenByDefault.current)
    }
  }, [])

  // Check if triangle should offset from left.
  const isOffsetLeft = side === PopupSide.TopRight || side === PopupSide.BottomRight
  const tooltipMenuTheme: TooltipMenuTheme = {
    tooltipMenu: classnames(styles.popup, {
      [styles.topCenter]: side === PopupSide.TopCenter,
      [styles.topRight]: side === PopupSide.TopRight,
      [styles.bottomRight]: side === PopupSide.BottomRight,
      [styles.bottomLeft]: side === PopupSide.BottomLeft,
      [styles.topLeft]: side === PopupSide.TopLeft,
    }),
    triangleClip: classnames(styles.triangleClip, {
      [styles.topCenter]: side === PopupSide.TopCenter,
      [styles.topRight]: side === PopupSide.TopRight,
      [styles.topLeft]: side === PopupSide.TopLeft,
    }),
    triangle: classnames(styles.triangle, {
      [styles.topCenter]: side === PopupSide.TopCenter,
      [styles.topRight]: side === PopupSide.TopRight,
      [styles.topLeft]: side === PopupSide.TopLeft,
    }),
  }

  return (
    <div ref={tooltipRef} className={styles.tooltip} onClick={e => e.preventDefault()}>
      <div className={styles.helpIconWrapper}>
        {customToggleElement ? customToggleElement : <MaterialIcon className={styles.helpIcon} type="help" onClick={() => setIsOpen(!isOpen)} />}
      </div>

      {renderIf(
        isOpen,
        <TooltipMenu
          triangleOffsetLeft={isOffsetLeft ? tooltipHelpIconSize : undefined}
          triangleOffsetRight={isOffsetLeft ? undefined : tooltipHelpIconSize}
          theme={tooltipMenuTheme}>
          {noCloseIcon || (
            <div className={styles.closeIconWrapper}>
              <MaterialIcon className={styles.closeIcon} type="close" onClick={() => setIsOpen(false)} />
            </div>
          )}
          {children}
        </TooltipMenu>
      )}
    </div>
  )
}
