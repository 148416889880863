import React from 'react'
import styles from './SideMenuItem.module.scss'
import { ReactComponent as ChevronIcon } from './icons/chevron-icon.svg'

type ButtonItemProps = Readonly<{ onClick: () => void }>
type LinkItemProps = Readonly<{ href: string }>
type SideMenuTextProps = Readonly<{ text: string }>

type SideMenuLinkProps = LinkItemProps & SideMenuTextProps
type SideMenuButtonProps = ButtonItemProps & SideMenuTextProps

// Absolute URLs that include the protocol will open in a new tab
const determineTargetFromHref = (href: string) => (/http(s)?:\/\//.test(href) ? '_blank' : '_self')

const SideMenuText = ({ text }: SideMenuTextProps) => {
  return (
    <>
      <span className={styles.sideMenuText}>{text}</span>
      <ChevronIcon className={styles.sideMenuIcon} />
    </>
  )
}

export const SideMenuItemLink = (props: SideMenuLinkProps) => (
  <a className={styles.sideMenuItem} href={props.href} target={determineTargetFromHref(props.href)}>
    <SideMenuText text={props.text} />
  </a>
)

export const SideMenuItemButton = (props: SideMenuButtonProps) => (
  <button className={styles.sideMenuItem} onClick={props.onClick}>
    <SideMenuText text={props.text} />
  </button>
)
