import { useState } from 'react'

export type UseSideMenu = ReturnType<typeof useSideMenu>

export const useSideMenu = () => {
  const [sideMenuIsActive, setSideMenuIsActive] = useState<boolean>(false)
  const openSideMenu = () => setSideMenuIsActive(true)
  const closeSideMenu = () => setSideMenuIsActive(false)

  return { sideMenuIsActive, openSideMenu, closeSideMenu }
}
