interface IHeaderOptions {
  authToken?: string
  contentType?: 'json'
}

export function createHeaders(options: IHeaderOptions): Headers {
  const headers = new Headers()
  headers.append('X-Requested-With', 'Pronto')

  if (options.authToken) {
    headers.append('X-Auth-Token', options.authToken)
  }
  if (options.contentType) {
    headers.append('Content-Type', 'application/json')
  }

  return headers
}
