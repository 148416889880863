export type Predicate<A> = (a: A) => boolean

// A type guard is a special kind of predicate that indicates
// (by returning `true` or `false`) whether the `A` is actually a `B`.
export type TypeGuard<A, B extends A> = (a: A) => a is B

export function not<A>(p: Predicate<A>): Predicate<A> {
  return (a: A) => !p(a)
}

export function and<A>(p1: Predicate<A>, p2: Predicate<A>): Predicate<A> {
  return (a: A) => p1(a) && p2(a)
}

export function notFalse<A>(a: A | false): a is A {
  return a !== false
}

export const filterNull = <A extends {}>(item: A | null): item is A => item !== null

export function areEqual<A>(left: A, right: A) {
  return left === right
}
