import { ValidationField } from '../../state/Form/ValidationField'
import { IBerthVisitFormState } from './BerthVisitForm'

export const validationMapping: Record<ValidationField, (keyof IBerthVisitFormState)[]> = {
  FIELD_NAME: ['vessel'],
  FIELD_SHIP_ID: ['vessel'],
  FIELD_BERTH: ['terminal'],
  FIELD_ARRIVAL_BERTH: ['arrival'],
  FIELD_DEPARTURE_BERTH: ['departure'],
  FIELD_START_CARGO: ['startCargo'],
  FIELD_BOLLARDS: ['bollardsStart', 'bollardsEnd'],
  FIELD_BOLLARDS_AFT: ['bollardsEnd'],
  FIELD_BOLLARDS_FORE: ['bollardsStart'],
  FIELD_COMPLETE_CARGO: ['completeCargo'],
  FIELD_ORIENTATION: ['orientation'],
}
