import * as React from 'react'
import styles, { Styles } from './Toggleable.module.scss'
import classnames from 'classnames'
import { FormElementProps } from './FormTypes'

export type ToggleableTheme = Partial<Styles>

type ToggleableProps = FormElementProps &
  Readonly<{
    type: 'checkbox' | 'radio'
    checked: boolean
    theme?: ToggleableTheme
    children: (checked: boolean, label: string | React.ReactElement) => React.ReactElement
    onChange: (checked: boolean) => void
  }>

export const Toggleable = ({ checked, label, onChange, name, theme, type, children }: ToggleableProps): JSX.Element => {
  return (
    <div>
      <label className={classnames(styles.toggleContainer, theme?.toggleContainer)}>
        <input
          type={type}
          checked={checked}
          name={name}
          onChange={e => onChange(e.currentTarget.checked)}
          className={classnames(styles.toggle, theme?.toggle)}
        />
        <span className={classnames(styles.toggleLabel, theme?.toggleLabel)}>{children(checked, label)}</span>
      </label>
    </div>
  )
}
