import { IBerth } from '../../state/IBerth'
import { BACKEND_URL } from '../../config'
import { IBerthJson, toBerths } from './IBerthJson'
import { createHeaders } from '../createHeaders'
import { PromiseOfValidated } from '../../utils/PromiseOfValidated'
import { AppAction } from '../../actions/AppAction'
import { fetchJsonWithErrorHandling } from '../fetchJsonWithErrorHandling'

export function fetchBerths(authToken: string): PromiseOfValidated<AppAction, IBerth[]> {
  const request: RequestInit = {
    method: 'GET',
    headers: createHeaders({ authToken }),
    cache: 'default'
  }
  return fetchJsonWithErrorHandling<IBerthJson[]>(`${BACKEND_URL}/api/berths`, request).map(toBerths)
}
