import { BerthVisitsContent } from './BerthVisitsContent'
import React from 'react'
import { useSelector } from 'react-redux'
import { berthVisitTableRowsSelector } from '../../selectors/berthVisitTableRowsSelector'
import { fold as foldOption } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'
import { Loader } from '../Loader/Loader'

export const BerthVisits = () => {
  const rows = useSelector(berthVisitTableRowsSelector)

  return pipe(
    rows,
    foldOption(
      () => <Loader state={{ type: 'LOADING' }} />,
      ({ berthVisits, layByBerths, combinedRows }) => <BerthVisitsContent berthVisits={berthVisits} layByBerths={layByBerths} rows={combinedRows} />
    )
  )
}
