/**
 * This class wraps a `Promise<Validated<E, A>>` so we can
 * apply `map` and `flatMap` to `A` instead of having to do
 * `promise.then(validated => validated.map(value => ...))`.
 */
import { Validated } from './Validated'

export class PromiseOfValidated<E, A> {
  public readonly raw: Promise<Validated<E, A>>

  constructor(raw: Promise<Validated<E, A>>, private readonly recoverWith?: (reason: any) => E) {
    if (recoverWith === undefined) {
      this.raw = raw
    } else {
      this.raw = raw.catch(reason => Validated.error<E, A>(recoverWith(reason)))
    }
  }

  public static errors<E, A>(errors: E[]): PromiseOfValidated<E, A> {
    return new PromiseOfValidated(Promise.resolve(Validated.errors<E, A>(errors)))
  }

  public static fromPromise<E, A>(p: Promise<A>, recoverWith?: (reason: any) => E): PromiseOfValidated<E, A> {
    return new PromiseOfValidated(p.then(a => Validated.ok<E, A>(a)), recoverWith)
  }

  public static fromValidated<E, A>(validated: Validated<E, A>): PromiseOfValidated<E, A> {
    return new PromiseOfValidated(Promise.resolve(validated))
  }

  public static seq<E, A>(ps: PromiseOfValidated<E, A>[]): PromiseOfValidated<E, A[]> {
    // Move the array progressively inwards...
    const promisesOfValidateds: Promise<Validated<E, A>>[] = ps.map(p => p.raw)
    const promiseOfValidateds: Promise<Validated<E, A>[]> = Promise.all(promisesOfValidateds)
    const promiseOfValidated: Promise<Validated<E, A[]>> = promiseOfValidateds.then(Validated.seq)

    // ...until we get what we want:
    return new PromiseOfValidated<E, A[]>(promiseOfValidated)
  }

  public map<B>(fn: (a: A) => B): PromiseOfValidated<E, B> {
    return new PromiseOfValidated<E, B>(this.raw.then(validated => validated.map(fn)), this.recoverWith)
  }

  public flatMap<B>(fn: (a: A) => PromiseOfValidated<E, B>): PromiseOfValidated<E, B> {
    const rawFlatMapped = this.raw
      .then(validated => validated.fold(v => fn(v.value), v => PromiseOfValidated.errors<E, B>(v.errors)))
      .then(promiseOfValidated => promiseOfValidated.raw)

    return new PromiseOfValidated<E, B>(rawFlatMapped, this.recoverWith)
  }

  public fromEither<A>(this: PromiseOfValidated<A, A[]>): Promise<A[]> {
    return this.raw.then(v => v.fromEither())
  }
}
