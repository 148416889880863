import { notFalse } from './predicates'

export const Arrays = {
  pairs<A>(as: A[]): Array<[A, A]> {
    if (as.length === 0) {
      return []
    } else {
      return as.reduce<Array<[A, A]>>((acc, curr, idx, arr) => {
        if (idx < arr.length - 1) {
          acc.push([arr[idx], arr[idx + 1]])
        }
        return acc
      }, [])
    }
  },

  filterTypeGuard<A, B>(as: A[], predicate: (a: A) => B | false): B[] {
    return as.map(predicate).filter(notFalse)
  },

  getFirst<A>(arr: A[]): A | false {
    return Arrays.isEmpty(arr) ? false : arr[0]
  },

  isEmpty<A>(arr: A[]): arr is [] {
    return arr.length === 0
  },

  isNotEmpty<A>(arr: A[]): arr is A[] {
    return !Arrays.isEmpty(arr)
  },

  includes<A extends B, B>(haystack: A[], needle: B, eq: (left: A, right: B) => boolean = (left, right) => left === right): needle is A {
    return haystack.some(a => eq(a, needle))
  },

  // Checks if all needles exist in the haystack.
  // Returns false when it encounters the first needle that doesn't exist
  allValuesExistIn<A>(needles: A[], haystack: A[]): boolean {
    return needles.map(aVal => haystack.indexOf(aVal) > -1).every(notFalse)
  },

  every<A>(a: A[], predicate: (val: A) => boolean): boolean {
    return a.every(predicate)
  },

  some<A>(a: A[], predicate: (val: A) => boolean): boolean {
    return a.some(predicate)
  },
}
