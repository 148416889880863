import React from 'react'
import { ErrorHeader } from '../Dialog/ErrorHeader'
import { useAuth } from '../../context/Auth0Context'
import { ErrorFooter } from '../Dialog/ErrorFooter'
import { Button } from '../Form/Button/Button'

export const UnauthorisedError = () => {
  const { authUtils } = useAuth()

  return (
    <>
      <ErrorHeader>Session has expired</ErrorHeader>
      <p>Your session has expired. Please log in again.</p>
      <ErrorFooter>
        <Button type="button" onClick={authUtils.logout} text="Go to log in screen" />
      </ErrorFooter>
    </>
  )
}
