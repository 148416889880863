import { IBerthVisitJson, toBerthVisit, toBerthVisitJson, toBerthVisits } from './IBerthVisitJson'
import { BACKEND_URL } from '../../config'
import { BerthVisitId, IBerthVisit } from '../../state/IBerthVisit'
import { createHeaders } from '../createHeaders'
import { PromiseOfValidated } from '../../utils/PromiseOfValidated'
import { AppAction } from '../../actions/AppAction'
import { fetchJsonWithErrorHandling } from '../fetchJsonWithErrorHandling'
import { fetchEmptyWithErrorHandling } from '../fetchEmptyWithErrorHandling'
import { constant } from 'fp-ts/es6/function'

type BerthVisitsQueryParameters = Readonly<{
  from?: Date
  to?: Date
}>

export function addBerthVisit(authToken: string, berthVisit: IBerthVisit, etaShift: boolean = false): PromiseOfValidated<AppAction, IBerthVisit> {
  const url = new URL(`${BACKEND_URL}/api/terminals/visits`)
  url.searchParams.append('etaShift', etaShift ? 'true' : 'false')

  const options = {
    method: 'POST',
    headers: createHeaders({ authToken, contentType: 'json' }),
    body: JSON.stringify(toBerthVisitJson(berthVisit)),
  }
  return fetchJsonWithErrorHandling<IBerthVisitJson>(url.href, options).map(toBerthVisit)
}

export function updateBerthVisitAsync(authToken: string, berthVisit: IBerthVisit, isEtaShift: boolean = false): PromiseOfValidated<AppAction, IBerthVisit> {
  const url = new URL(`${BACKEND_URL}/api/terminals/visits/${berthVisit.id}`)
  url.searchParams.append('etaShift', isEtaShift ? 'true' : 'false')

  const options = {
    method: 'PUT',
    headers: createHeaders({ authToken, contentType: 'json' }),
    body: JSON.stringify(toBerthVisitJson(berthVisit)),
  }
  return fetchJsonWithErrorHandling<IBerthVisitJson>(url.href, options).map(toBerthVisit)
}

export function deleteBerthVisitsAsync(authToken: string, berthVisitIds: BerthVisitId[]): PromiseOfValidated<AppAction, BerthVisitId[]> {
  const url = `${BACKEND_URL}/api/terminals/visits`
  const options = {
    method: 'DELETE',
    headers: createHeaders({ authToken, contentType: 'json' }),
    body: JSON.stringify({ berthVisitIds }),
  }
  return fetchEmptyWithErrorHandling(url, options).map(constant(berthVisitIds))
}

export function fetchBerthVisits(authToken: string, parameters?: BerthVisitsQueryParameters): PromiseOfValidated<AppAction, Array<IBerthVisit>> {
  const request: RequestInit = {
    method: 'GET',
    headers: createHeaders({ authToken }),
    cache: 'default',
  }

  const url = new URL(`${BACKEND_URL}/api/terminals/visits`)
  if (parameters) {
    if (parameters.from) url.searchParams.append('from', parameters.from.toISOString())
    if (parameters.to) url.searchParams.append('to', parameters.to.toISOString())
  }
  return fetchJsonWithErrorHandling<IBerthVisitJson[]>(url.href, request).map(toBerthVisits)
}
