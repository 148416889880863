import * as React from 'react'
import styles from './Loaders.module.scss'
import classnames from 'classnames'
import { ILoaderState, LOADING, LOADING_ERROR, LOADING_FINSIHED } from './ILoaderState'

interface ILoaderProps {
  containerClassName?: string
  state: ILoaderState
  loaderVisible?: boolean
}

function getMessage(state: ILoaderState): string {
  const defaultMessages: Record<ILoaderState['type'], string> = {
    [LOADING]: 'LOADING',
    [LOADING_FINSIHED]: 'DONE',
    [LOADING_ERROR]: 'ERROR',
  }

  return state.customMessage || defaultMessages[state.type]
}

export const Loader = (props: ILoaderProps) => {
  // The `=== false` makes sure that the component is also rendered
  // when no `loaderVisible` is specified (`loaderVisible === undefined`)
  if (props.loaderVisible === false) {
    return null
  }

  const className = classnames(styles.loader, { [styles.error]: props.state.type === LOADING_ERROR, [styles.finished]: props.state.type === LOADING_FINSIHED })
  return (
    <div className={props.containerClassName}>
      <div className={className}>
        <div className={styles.spinnerWrapper}>
          <div className={styles.spinner}></div>
          <div>{getMessage(props.state)}</div>
        </div>
      </div>
    </div>
  )
}
