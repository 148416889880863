import { Auth0Client } from '@auth0/auth0-spa-js'
import { BrandedCovariant } from '../branded'

export type AuthToken = BrandedCovariant<string, 'auth-token'>
export type IdToken = BrandedCovariant<string, 'id-token'>
export type AuthResult = Readonly<{ appState?: any }>
export type HandleAuthCallbackResult = { type: 'RESULT'; result: AuthResult } | { type: 'NO_RESULT' }

export type AuthUtils = Readonly<{
  login: () => Promise<void>
  logout: () => void
  handleAuthCallback: (url: string) => Promise<HandleAuthCallbackResult>
  isAuthenticated: () => Promise<boolean>
  getToken: () => Promise<AuthToken>
  getIdToken: () => Promise<IdToken>
}>

// Get utils from auth client
export const getAuthClientUtils = async (createClient: () => Promise<Auth0Client>, getAuthUtils: (client: Auth0Client) => AuthUtils) => {
  const authClient = await createClient()

  return getAuthUtils(authClient)
}
