import * as React from 'react'
import { IShipId } from '../IShip'
import { Validated } from '../../utils/Validated'
import {
  FIELD_COMPLETE_CARGO,
  FIELD_START_CARGO,
  FIELD_SHIP_ID,
  FIELD_ARRIVAL_BERTH,
  FIELD_DEPARTURE_BERTH,
  ValidationField,
  FIELD_BERTH,
  FIELD_BOLLARDS,
  FIELD_BOLLARDS_FORE,
  FIELD_BOLLARDS_AFT,
} from './ValidationField'
import {
  ERROR_FORMER_NOT_BEFORE_LATTER,
  ERROR_NO_BERTH_SELECTED,
  ERROR_NO_SHIP_SELECTED,
  ERROR_NOT_A_DATE,
  fieldsInvolved,
  ValidationError,
  ERROR_AT_LEAST_ONE_DATE_IS_REQUIRED,
  ERROR_BOLLARD_ARE_EQUAL,
  ERROR_NO_TERMINAL_SELECTED,
  ERROR_BERTH_NOT_EMPTY,
  ERROR_TERMINAL_NOT_EMPTY,
  ERROR_BOLLARD_NOT_IN_BERTH_RANGE,
} from './ValidationError'
import { IBerthVisit, IBerthVisitDate } from '../IBerthVisit'
import { parseLocalDateTimeString } from '../../utils/dates/dates'
import { Arrays } from '../../utils/Arrays'
import { flatMap } from 'lodash'
import { IDateTime } from '../../utils/dates/IDateTime'
import { IBerth, ITerminal, TerminalUuid, BerthUuid } from '../IBerth'
import { WithDefault } from '../../utils/Object'
import { Bollards } from '../Bollards'
import { TimeZone } from '../TimeZone'
import { None } from '../../utils/strictNull'

export const FIELD_COULD_NOT_BE_VALIDATED = 'FIELD_COULD_NOT_BE_VALIDATED'
export type FieldCouldNotBeValidated = typeof FIELD_COULD_NOT_BE_VALIDATED

export function isValidShip(shipId: IShipId | null): Validated<ValidationError, IShipId> {
  if (shipId === null) {
    return Validated.error<ValidationError, IShipId>({
      type: ERROR_NO_SHIP_SELECTED,
      field: FIELD_SHIP_ID,
    })
  }

  return Validated.ok(shipId)
}

export function isValidBerth(terminalUuid: TerminalUuid | null, berth: IBerth | null): Validated<ValidationError, BerthUuid | null> {
  // Only valid when only terminal is filled OR if only berth is filled
  // Both filled or both empty is not valid

  if (berth === null) {
    if (terminalUuid === null) {
      // Both are empty, error
      return Validated.error<ValidationError, BerthUuid>({
        type: ERROR_NO_BERTH_SELECTED,
        field: FIELD_BERTH,
      })
    }
    // Berth is empty, terminal is filled, ok
    return Validated.ok<ValidationError, null>(null)
  }

  // Both are filled, error
  if (terminalUuid !== null) {
    return Validated.error<ValidationError, BerthUuid>({
      type: ERROR_TERMINAL_NOT_EMPTY,
      field: FIELD_BERTH,
    })
  }

  // Berth is filled, terminal is empty, ok
  return Validated.ok<ValidationError, BerthUuid>(berth.uuid)
}

export function isValidBerthUuid(terminalUuid: TerminalUuid | null, berth: IBerth | null): Validated<ValidationError, BerthUuid | null> {
  // Only valid when only terminal is filled OR if only berth is filled
  // Both filled or both empty is not valid

  if (berth === null) {
    if (terminalUuid === null) {
      // Both are empty, error
      return Validated.error<ValidationError, BerthUuid>({
        type: ERROR_NO_BERTH_SELECTED,
        field: FIELD_BERTH,
      })
    }
    // Berth is empty, terminal is filled, ok
    return Validated.ok<ValidationError, null>(null)
  }

  // Both are filled, error
  if (terminalUuid !== null) {
    return Validated.error<ValidationError, BerthUuid>({
      type: ERROR_TERMINAL_NOT_EMPTY,
      field: FIELD_BERTH,
    })
  }

  // Berth is filled, terminal is empty, ok
  return Validated.ok<ValidationError, BerthUuid>(berth.uuid)
}

export function isValidTerminal(
  terminalUuid: TerminalUuid | null,
  berthUuid: BerthUuid | null,
  allTerminals: ITerminal[]
): Validated<ValidationError, TerminalUuid | null> {
  // Both are empty, error
  if (terminalUuid === null && berthUuid === null) {
    return Validated.error<ValidationError, TerminalUuid>({
      type: ERROR_NO_TERMINAL_SELECTED,
      field: FIELD_BERTH,
    })
  }

  // Both are filled, error
  if (terminalUuid !== null && berthUuid !== null) {
    return Validated.error<ValidationError, TerminalUuid>({
      type: ERROR_BERTH_NOT_EMPTY,
      field: FIELD_BERTH,
    })
  }

  // Terminal is empty, berth is filled, ok
  if (berthUuid !== null && terminalUuid === null) {
    return Validated.ok<ValidationError, null>(null)
  }

  if (terminalUuid === null || allTerminals.find(b => b.terminalUuid === terminalUuid) === undefined) {
    return Validated.error<ValidationError, TerminalUuid>({
      type: ERROR_NO_TERMINAL_SELECTED,
      field: FIELD_BERTH,
    })
  }

  return Validated.ok<ValidationError, TerminalUuid>(terminalUuid)
}
export function isValidTerminalUuid(
  terminalUuid: TerminalUuid | null,
  berthUuid: BerthUuid | null,
  allTerminals: ITerminal[]
): Validated<ValidationError, TerminalUuid | null> {
  // Both are empty, error
  if (terminalUuid === null && berthUuid === null) {
    return Validated.error<ValidationError, TerminalUuid>({
      type: ERROR_NO_TERMINAL_SELECTED,
      field: FIELD_BERTH,
    })
  }

  // Both are filled, error
  if (terminalUuid !== null && berthUuid !== null) {
    return Validated.error<ValidationError, TerminalUuid>({
      type: ERROR_BERTH_NOT_EMPTY,
      field: FIELD_BERTH,
    })
  }

  // Terminal is empty, berth is filled, ok
  if (berthUuid !== null && terminalUuid === null) {
    return Validated.ok<ValidationError, null>(null)
  }

  if (terminalUuid === null || allTerminals.find(b => b.terminalUuid === terminalUuid) === undefined) {
    return Validated.error<ValidationError, TerminalUuid>({
      type: ERROR_NO_TERMINAL_SELECTED,
      field: FIELD_BERTH,
    })
  }

  return Validated.ok<ValidationError, TerminalUuid>(terminalUuid)
}

function isBollardInBerthsRange(bollard: number | null, berthsBollards: Bollards): boolean {
  return bollard !== null &&
    berthsBollards !== null &&
    berthsBollards.start !== null &&
    berthsBollards.end !== null &&
    (bollard < berthsBollards.start || bollard > berthsBollards.end)
    ? false
    : true
}

export function hasValidBollards(bollards: Bollards, berth: IBerth | null): Validated<ValidationError, Bollards> {
  if (bollards !== null) {
    if (bollards.start === bollards.end) {
      return Validated.error<ValidationError, Bollards>({
        type: ERROR_BOLLARD_ARE_EQUAL,
        field: FIELD_BOLLARDS,
      })
    }

    if (berth !== null && berth.bollards !== null) {
      if (!isBollardInBerthsRange(bollards.start, berth.bollards)) {
        return Validated.error<ValidationError, Bollards>({
          type: ERROR_BOLLARD_NOT_IN_BERTH_RANGE,
          field: FIELD_BOLLARDS_FORE,
          value: berth.bollards,
        })
      }

      if (!isBollardInBerthsRange(bollards.end, berth.bollards)) {
        return Validated.error<ValidationError, Bollards>({
          type: ERROR_BOLLARD_NOT_IN_BERTH_RANGE,
          field: FIELD_BOLLARDS_AFT,
          value: berth.bollards,
        })
      }
    }
  }

  return Validated.ok(bollards)
}

export function isValidBerthVisitDateOrEmpty(
  field: ValidationField,
  dateTime: IDateTime,
  isActual: boolean,
  now: Date,
  timeZone: TimeZone | None,
  dateFormat: string
): Validated<ValidationError, IBerthVisitDate | null> {
  if (dateTime.date === '' && dateTime.time === '') {
    return Validated.ok(null)
  }

  return isValidBerthVisitDate(field, dateTime, isActual, now, timeZone, dateFormat)
}

export function isValidBerthVisitDate(
  field: ValidationField,
  dateTime: IDateTime,
  isActual: boolean,
  now: Date,
  timeZone: TimeZone | None,
  dateFormat: string
): Validated<ValidationError, IBerthVisitDate> {
  const validatedDate = isValidDate(field, dateTime.date, dateTime.time, now, timeZone, dateFormat)

  return validatedDate.map(date => ({ date, isActual }))
}

export function isValidDate(
  field: ValidationField,
  date: string,
  time: string,
  now: Date,
  timeZone: TimeZone | None,
  dateFormat: string
): Validated<ValidationError, Date> {
  return parseLocalDateTimeString(date, time, now, timeZone, dateFormat).mapError<ValidationError>(() => ({
    type: ERROR_NOT_A_DATE,
    field,
  }))
}

export function hasError(field: ValidationField, validated: Validated<ValidationError, any>): boolean {
  if (validated.isValid()) {
    return false
  } else {
    return flatMap(validated.errors, fieldsInvolved).some(fieldInvolved => fieldInvolved === field)
  }
}

export function getErrors(
  validated: Validated<ValidationError, any>,
  errorFormatter: (o: ValidationError, index: number) => React.ReactNode
): React.ReactNode[] {
  if (validated.isValid()) {
    return []
  } else {
    return validated.errors.map(errorFormatter)
  }
}

export function isValidBerthVisit(validBerthVisitFields: WithDefault<IBerthVisit, FieldCouldNotBeValidated>): Validated<ValidationError, false> {
  return Validated.seq([
    datesAreOrderedCorrectly(validBerthVisitFields),
    anyDefined([validBerthVisitFields.arrivalBerth, validBerthVisitFields.departureBerth], [FIELD_ARRIVAL_BERTH, FIELD_DEPARTURE_BERTH]),
  ]).map((): false => false)
}

function anyDefined(values: Array<IBerthVisitDate | null | FieldCouldNotBeValidated>, fields: ValidationField[]): Validated<ValidationError, false> {
  // `FIELD_COULD_NOT_BE_VALIDATED` means the user provided an invalid value, so no need to proceed with further validation.
  if (values.some(value => value === FIELD_COULD_NOT_BE_VALIDATED)) {
    return Validated.ok<ValidationError, false>(false)
  }

  if (values.every(value => !value)) {
    return Validated.error<ValidationError, false>({
      type: ERROR_AT_LEAST_ONE_DATE_IS_REQUIRED,
      fields,
    })
  }

  return Validated.ok<ValidationError, false>(false)
}

function datesAreOrderedCorrectly(p: WithDefault<IBerthVisit, FieldCouldNotBeValidated>): Validated<ValidationError, false> {
  const ptaBerth: FieldAndDateOption = { field: FIELD_ARRIVAL_BERTH, berthVisitDate: p.arrivalBerth }
  const etsCargo: FieldAndDateOption = { field: FIELD_START_CARGO, berthVisitDate: p.startCargo }
  const etcCargo: FieldAndDateOption = { field: FIELD_COMPLETE_CARGO, berthVisitDate: p.completeCargo }
  const ptdBerth: FieldAndDateOption = { field: FIELD_DEPARTURE_BERTH, berthVisitDate: p.departureBerth }

  return hasOrder([ptaBerth, etsCargo, etcCargo, ptdBerth])
}

type FieldAndDate = { field: ValidationField; berthVisitDate: IBerthVisitDate }

// The `null` indicates no value was provided (like the start cargo date, which can be empty).
// The `undefined` indicates that we have an undefined field in `Partial<IBerthVisit>` which indicates that validation failed for this field.
type FieldAndDateOption = { field: ValidationField; berthVisitDate: IBerthVisitDate | null | FieldCouldNotBeValidated }

function hasOrder(fieldAndDateOptions: Array<FieldAndDateOption>): Validated<ValidationError, false> {
  const fieldsAndDates = Arrays.filterTypeGuard<FieldAndDateOption, FieldAndDate>(fieldAndDateOptions, ({ field, berthVisitDate }) =>
    berthVisitDate !== null && berthVisitDate !== FIELD_COULD_NOT_BE_VALIDATED ? { field, berthVisitDate } : false
  )

  const validateds = Arrays.pairs(fieldsAndDates).map(([left, right]) => {
    if (left.berthVisitDate.date.valueOf() >= right.berthVisitDate.date.valueOf()) {
      return Validated.error<ValidationError, false>({
        type: ERROR_FORMER_NOT_BEFORE_LATTER,
        former: left.field,
        latter: right.field,
      })
    } else {
      return Validated.ok<ValidationError, false>(false)
    }
  })

  return Validated.seq(validateds).map<false>(() => false)
}

export function ok<K extends keyof IBerthVisit>(key: K, value: IBerthVisit[K]): Validated<ValidationError, IBerthVisit[K]> {
  return Validated.ok<ValidationError, IBerthVisit[K]>(value)
}
