import { IError } from '../state/IError'
import { AppAction } from '../actions/AppAction'
import { SET_ERROR, CLEAR_ERROR } from '../actions/errorActions'

export function errorReducer(error: IError | null = null, action: AppAction): IError | null {
  switch (action.type) {
    case CLEAR_ERROR:
      return null
    case SET_ERROR:
      return action.error
    default:
      return error
  }
}
