import React from 'react'
import { FieldAttributes, useField } from 'formik'
import { ShipSelection, ShipSelectionProps } from './ShipSelection'
import { IShipId } from '../../state/IShip'

export type FormikShipSelectionProps = FieldAttributes<ShipSelectionProps>
export const FormikShipSelection: React.FC<FormikShipSelectionProps> = props => {
  const [field, meta, helpers] = useField<IShipId>(props)
  const errorMessage = meta.error && meta.touched ? meta.error : undefined

  return (
    <ShipSelection
      name={field.name}
      handleChange={selectedOption => {
        helpers.setValue(selectedOption)
        if (props.handleChange) {
          props.handleChange(selectedOption)
        }
      }}
      handleBlur={() => helpers.setTouched(true)}
      errorMessage={errorMessage}
    />
  )
}
