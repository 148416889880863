import { applyMiddleware, createStore, compose, StoreEnhancer, Reducer } from 'redux'
import { reducer } from './reducers/reducer'
import thunk from 'redux-thunk'
import { errorLoggerMiddleware } from './middleware/errorLoggerMiddleware'
import { storageMiddleWare } from './utils/storage'
import * as storage from 'redux-storage'
import { IAppState } from './state/IAppState'
import { AppAction } from './actions/AppAction'

const composeEnhancers = (process.env.NODE_ENV === 'development' && (window as any) && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

// TODO this storage module is a bit outdated, we need to find a fresh one
const reducers = storage.reducer(reducer) as Reducer<IAppState, AppAction>
const middlewares = [thunk, errorLoggerMiddleware, storageMiddleWare]
const enhancer = composeEnhancers(applyMiddleware(...middlewares))

export const store = createStore<IAppState, AppAction, StoreEnhancer<any>, any>(reducers, enhancer)
