import { useSelector, useDispatch } from 'react-redux'
import { openNewBerthVisitModal, closeBerthVisitEntryModal as closeModal, openEditBerthVisitModal } from '../../actions/uiActions'
import { BerthVisitId } from '../../state/IBerthVisit'
import { berthVisitEntryModalSelector } from '../../selectors/berthVisitEntryModalSelector'

export type UseBerthVisitEntryModal = ReturnType<typeof useBerthVisitEntryModal>

export const useBerthVisitEntryModal = () => {
  const berthVisitEntryModal = useSelector(berthVisitEntryModalSelector)
  const dispatch = useDispatch()
  const newBerthVisitHandler = () => {
    dispatch(openNewBerthVisitModal())
  }
  const editBerthVisitHandler = (berthVisitId: BerthVisitId) => {
    dispatch(openEditBerthVisitModal(berthVisitId))
  }
  const closeBerthVisitEntryModal = () => {
    dispatch(closeModal())
  }

  return { berthVisitEntryModal, newBerthVisitHandler, editBerthVisitHandler, closeBerthVisitEntryModal }
}
