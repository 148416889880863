import { User } from '../state/User'

export const SET_USER = 'SET_USER'

export type SetUserAction = Readonly<{
  type: typeof SET_USER
  user: User
}>

export function setUserAction(user: User): SetUserAction {
  return { type: SET_USER, user }
}
