import React from 'react'
import styles from './HiddenCheckboxWrapper.module.scss'

type HiddenCheckboxWrapperProps = Readonly<{
  isChecked: boolean
  disabled?: boolean
  handleToggle: (checked: boolean) => void
}>

export const HiddenCheckboxWrapper: React.FC<HiddenCheckboxWrapperProps> = ({ isChecked, disabled, handleToggle, children }) => (
  <label>
    <input checked={isChecked} onChange={() => handleToggle(!isChecked)} disabled={disabled} className={styles.hiddenInput} type="checkbox" />
    {children}
  </label>
)
