import { berthsSelector } from './berthsSelector'
import { createSelector } from 'reselect'
import { toBerthVisitTableRows } from './berthVisitTableRow'
import { berthVisitsForVesselTypeSelector } from './berthVisitsForVesselTypeSelector'
import { terminalsSelector } from './terminalsSelector'
import { IAppState } from '../state/IAppState'
import { layByBerthsWithFilterSelector } from './layByBerthsWithFilterSelector'

export const berthVisitsSortMethodSelector = (state: IAppState) => state.ui.sortMethod

export const berthVisitTableRowsSelector = createSelector(
  berthVisitsForVesselTypeSelector,
  layByBerthsWithFilterSelector,
  berthsSelector,
  terminalsSelector,
  berthVisitsSortMethodSelector,
  toBerthVisitTableRows
)
