import React from 'react'
import styles from './NoBerthVisits.module.scss'
import { useDispatch } from 'react-redux'
import { openNewBerthVisitModal } from '../../actions/uiActions'

export const NoBerthVisits = () => {
  const dispatch = useDispatch()
  const addVessel = () => dispatch(openNewBerthVisitModal())

  return (
    <div className={styles.noVisitsContainer}>
      <h1>No berth visits found</h1>
      <p>
        You can add a berth visit to your terminal planning by clicking on the "
        <button className={styles.addVesselButton} onClick={addVessel}>
          Add vessel
        </button>
        " button.
      </p>
    </div>
  )
}
