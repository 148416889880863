import React from 'react'
import { PlanningHeader } from '../Header/Header'
import { SideMenu } from '../SideMenu/SideMenu'
import { renderNoneable } from '../../utils/rendering'
import { BerthVisitEntryModal } from '../BerthVisitEntryModal/BerthVisitEntryModal'
import { UseSideMenu } from '../SideMenu/useSideMenu'
import { UseBerthVisitEntryModal } from '../BerthVisitEntryModal/useBerthVisitEntryModal'
import { UserProfile } from '../UserProfile/UserProfile'
import { SideMenuItemLink } from '../SideMenu/SideMenuItem'
import { PRONTO_URL } from '../../config'

export const PageWrapper: React.FC<UseSideMenu & UseBerthVisitEntryModal> = ({
  children,
  openSideMenu,
  sideMenuIsActive,
  closeSideMenu,
  berthVisitEntryModal,
  closeBerthVisitEntryModal,
  newBerthVisitHandler,
}) => (
  <>
    <PlanningHeader onSideMenuButtonClick={openSideMenu} onAddVesselButtonClick={newBerthVisitHandler} />
    <SideMenu isActive={sideMenuIsActive} onClose={closeSideMenu}>
      <SideMenuItemLink href={PRONTO_URL} text="Synchronizer" />
      <UserProfile />
    </SideMenu>

    {renderNoneable(berthVisitEntryModal, modal => (
      <BerthVisitEntryModal onClose={closeBerthVisitEntryModal} berthVisitEntryState={modal} />
    ))}

    {children}
  </>
)
