import { Option, fromNullable, fold } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'
import { constNull } from 'fp-ts/es6/function'

// In some cases this function is preferable to doing a ternary operator with `null`.
export function renderIf(condition: boolean, node: React.ReactElement) {
  return renderEither(condition, node, null)
}

// In some cases this function is preferable to doing a ternary operator.
export function renderEither(condition: boolean, node: React.ReactElement, alternative: React.ReactElement | null) {
  return condition ? node : alternative
}

export function renderUndefinable<A>(a: A | undefined, fn: (a: A) => React.ReactNode) {
  return pipe(fromNullable(a), fold(constNull, fn))
}

export function renderNoneable<A>(a: Option<A>, fn: (a: A) => React.ReactNode) {
  return pipe(a, fold(constNull, fn))
}
