import React from 'react'
import { BerthVisitId, IBerthVisit } from '../../state/IBerthVisit'
import { DateTime, emptyDateTime } from '../Form/DateTimeInput/DateTimeInput'
import { Orientation, ORIENTATION_DEFAULT } from '../../state/Orientation'
import { IBerth, ITerminalWithBerths } from '../../state/IBerth'
import { IShipId } from '../../state/IShip'
import { useSelector, useDispatch } from 'react-redux'
import { sortedTerminalsWithSortedBerthsSelector } from '../../selectors/sortedTerminalsWithSortedBerthsSelector'
import { berthVisitToBerthVisitFormState } from './berthVisitToBerthVisitFormState'
import { berthVisitFormStateToBerthVisit } from './berthVisitFormStateToBerthVisit'
import { dateFormatSelector } from '../../selectors/dateFormatSelector'
import { uniqueId } from 'lodash'
import { IBerthVisitForm, isValidBerthVisit } from '../../state/Form/IBerthVisitForm'
import { berthVisitFormStateToBerthVisitForm } from './berthVisitFormStateToBerthVisitForm'
import { fieldsInvolved, getErrorDescription } from '../../state/Form/ValidationError'
import { submitEditBerthVisitThunk, submitNewBerthVisitThunk } from '../../actions/thunks'
import { validationMapping } from './validationMapping'
import { FormikWrapper } from '../Form/FormikWrapper/FormikWrapper'
import BerthVisitFormInner from './BerthVisitFormInner'
import { fromNullable, Option, isSome, none, some, fold } from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/pipeable'
import { constant } from 'fp-ts/lib/function'
import { stakeholdersSelector } from '../../selectors/stakeholdersSelector'
import { StakeholderId } from '../../state/IStakeholder'
import { useMixpanel } from '../../context/MixpanelContext'
import { VESSELS_ADDED_PROP } from '../../utils/mixpanel/mixpanelSuperPropConstants'

export type BerthVisitFormProps = {
  berthVisit?: IBerthVisit
  prefilledFields?: PrefilledFields
}

export type BatchSettings = Pick<IBerthVisitFormState, 'preStartCargoMargin' | 'preDepartureMargin'>

export type PrefilledFields = Pick<IBerthVisitFormState, 'preStartCargoMargin' | 'preDepartureMargin' | 'isEtaShift'>

export type IBerthVisitFormState = {
  id: BerthVisitId
  vessel: IShipId | null
  stakeholderIds: StakeholderId[]
  terminal: ITerminalWithBerths | null
  berth: IBerth | null
  bollardsStart: number | null
  bollardsEnd: number | null
  orientation: Orientation
  arrival: DateTime
  startCargo: DateTime
  completeCargo: DateTime
  departure: DateTime
  remark: string | null
  isEtaShift: boolean
  isBatchEntry: boolean
  preStartCargoMargin: string
  preDepartureMargin: string
}

type FormErrors = Partial<Record<keyof IBerthVisitFormState, string>>

const createEmptyBerthVisitForm = (terminalsWithBerths: ITerminalWithBerths[], prefilledFields: Option<PrefilledFields>): IBerthVisitFormState => ({
  id: uniqueId() as BerthVisitId,
  vessel: null,
  stakeholderIds: [],
  terminal: terminalsWithBerths.length === 1 ? terminalsWithBerths[0] : null,
  berth: null,
  bollardsStart: null,
  bollardsEnd: null,
  orientation: ORIENTATION_DEFAULT,
  arrival: emptyDateTime,
  startCargo: emptyDateTime,
  completeCargo: emptyDateTime,
  departure: emptyDateTime,
  remark: null,
  isEtaShift: isSome(prefilledFields) && prefilledFields.value.isEtaShift,
  isBatchEntry: isSome(prefilledFields),
  preStartCargoMargin: pipe(
    prefilledFields,
    fold(constant(''), p => p.preStartCargoMargin)
  ),
  preDepartureMargin: pipe(
    prefilledFields,
    fold(constant(''), p => p.preDepartureMargin)
  ),
})

export const BerthVisitForm: React.FC<BerthVisitFormProps> = ({ berthVisit, prefilledFields }) => {
  const { trackEvent, incrementSuperProperty } = useMixpanel()
  const dispatch = useDispatch()
  const terminalsWithBerths = useSelector(sortedTerminalsWithSortedBerthsSelector)
  const stakeholders = useSelector(stakeholdersSelector)
  const dateFormat = useSelector(dateFormatSelector)

  const initialFormValues = berthVisit
    ? berthVisitToBerthVisitFormState(berthVisit, terminalsWithBerths, dateFormat)
    : createEmptyBerthVisitForm(terminalsWithBerths, fromNullable(prefilledFields))

  const editingBerthVisit = !!berthVisit

  const handleValidation = (values: IBerthVisitFormState): FormErrors => {
    const errors: FormErrors = {}
    const berthVisitForm: IBerthVisitForm = berthVisitFormStateToBerthVisitForm(values)
    const validationResult = isValidBerthVisit(berthVisitForm, terminalsWithBerths, new Date(), dateFormat)
    if (validationResult.type === 'error') {
      validationResult.errors.forEach(validationError =>
        fieldsInvolved(validationError)
          .flatMap(field => validationMapping[field])
          .forEach(key => (errors[key] = getErrorDescription(validationError)))
      )
    }
    return errors
  }

  return (
    <FormikWrapper
      initialValues={initialFormValues}
      validate={handleValidation}
      onSubmit={values => {
        const berthVisitToSubmit = berthVisitFormStateToBerthVisit(values, dateFormat)

        const { isBatchEntry, isEtaShift, preStartCargoMargin, preDepartureMargin } = values
        const batchSettings: Option<BatchSettings> = isBatchEntry
          ? some({
              preStartCargoMargin,
              preDepartureMargin,
            })
          : none

        if (editingBerthVisit) {
          dispatch(submitEditBerthVisitThunk(berthVisitToSubmit, isEtaShift))
        } else {
          dispatch(submitNewBerthVisitThunk(berthVisitToSubmit, isEtaShift, batchSettings))
        }
        incrementSuperProperty(VESSELS_ADDED_PROP, 1)
        trackEvent('Add vessel', {
          terminal: terminalsWithBerths.find(({ terminalUuid }) => terminalUuid === berthVisitToSubmit.terminalUuid)?.terminalName || 'TERMINAL NAME NOT FOUND',
        })
      }}>
      {({ values, setFieldValue }) => (
        <BerthVisitFormInner
          setFieldValue={setFieldValue}
          isEditing={editingBerthVisit}
          values={values}
          stakeholders={stakeholders}
          terminalsWithBerths={terminalsWithBerths}
          dateFormat={dateFormat}
          timeZone={fromNullable(values.berth?.timeZone)}
        />
      )}
    </FormikWrapper>
  )
}
