import { IBerthVisit } from '../../state/IBerthVisit'
import { IBerthVisitFormState } from './BerthVisitForm'
import { dateTimeToBerthVisitDate } from './dateTimeToBerthVisitDate'
import { none } from '../../utils/strictNull'

export const berthVisitFormStateToBerthVisit = (form: IBerthVisitFormState, dateFormat: string): IBerthVisit => {
  const timeZone = form.berth?.timeZone || form.terminal?.timeZone || none

  const arrivalBerth = dateTimeToBerthVisitDate('FIELD_ARRIVAL_BERTH', form.arrival, timeZone, dateFormat)
  const startCargo = dateTimeToBerthVisitDate('FIELD_START_CARGO', form.startCargo, timeZone, dateFormat)
  const completeCargo = dateTimeToBerthVisitDate('FIELD_COMPLETE_CARGO', form.completeCargo, timeZone, dateFormat)
  const departureBerth = dateTimeToBerthVisitDate('FIELD_DEPARTURE_BERTH', form.departure, timeZone, dateFormat)

  return {
    id: form.id,
    ship: form.vessel || {},
    terminalUuid: form.berth?.uuid ? null : form.terminal?.terminalUuid || null,
    berthUuid: form.berth?.uuid || null,
    bollards:
      form.bollardsStart && form.bollardsEnd
        ? {
            start: form.bollardsStart,
            end: form.bollardsEnd,
          }
        : null,
    orientation: form.orientation,
    arrivalBerth,
    startCargo,
    completeCargo,
    departureBerth,
    stakeholderIds: form.stakeholderIds,
    remark: form.remark !== null && form.remark.trim().length > 0 ? form.remark.trim() : null,
  }
}
