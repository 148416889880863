import React, { useMemo, useState } from 'react'
import styles from './ShipSelection.module.scss'
import { Select, SelectOption } from '../Form/Select/Select'
import { shipIdToDescription, IShip, IShipId, shipIdToDescriptionComponents } from '../../state/IShip'
import { ObservableResult } from '../ObservableResult/ObservableResult'
import { useResultObservable } from './useShipSelection'
import { fetchVesselSearch } from '../../rest/fetchVesselSearch'
import { useSelector } from 'react-redux'
import { tokenSelector } from '../../selectors/tokenSelector'
import { SelectFeedback } from '../Form/Select/SelectFeedback'
import { InlineLoader } from '../InlineLoader/InlineLoader'
import { QUERY_STRING_EMPTY, MORE_CHARACTERS_NEEDED, ERROR, RESULT, LOADING, MINIMUM_QUERY_STRING_LENGTH_FOR_REQUEST } from '../../utils/toResultObservable'
import { SelectOptionList } from '../Form/Select/SelectOptionList'
import { SelectOptionItem } from '../Form/Select/SelectOptionItem'

const shipResultToOptions = (shipOptions: IShip[]): SelectOption<IShip>[] =>
  shipOptions.map(ship => ({ label: ship.shipId.name || 'Unknown ship', value: ship }))

const fetcher = (token: string | undefined, query: string) => fetchVesselSearch(query, token).map(shipResultToOptions)

export type ShipSelectionProps = Readonly<{
  name: string
  handleChange?: (selectedShip: IShipId) => void
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  errorMessage?: string
}>

export const ShipSelection = (props: ShipSelectionProps) => {
  const token = useSelector(tokenSelector)
  const fetchResults = useMemo(() => fetcher.bind(null, token), [token])
  const { querySubject, shipResultObservable } = useResultObservable(fetchResults)
  const [selectedOption, setSelectedOption] = useState<SelectOption<IShip> | null>(null) // Mock selection state

  return (
    <Select
      name={props.name}
      label="Ship"
      options={[]}
      selectedOption={selectedOption}
      handleChange={option => {
        setSelectedOption(option)
        if (props.handleChange) {
          props.handleChange(option.value.shipId)
        }
      }}
      handleBlur={props.handleBlur}
      getKey={option => shipIdToDescription(option.value.shipId)}
      errorMessage={props.errorMessage}
      inputProps={{ placeholder: 'Type to start searching', readOnly: false }}
      onInput={query => querySubject.next(query)}>
      {({ setHighlightedIndex, setItemCount, isOpen, getMenuProps, getItemProps, highlightedIndex, selectedItem, getKey }) => (
        <ObservableResult
          resultObservable={shipResultObservable}
          onLoaded={options => {
            setHighlightedIndex(0)
            setItemCount(options.length)
          }}>
          {({ state }) => {
            if (!isOpen) {
              return null
            }

            switch (state.type) {
              case QUERY_STRING_EMPTY:
                return <SelectFeedback>Search for ship name, IMO, ENI, MMSI or USCG</SelectFeedback>
              case LOADING:
                return (
                  <SelectFeedback>
                    <InlineLoader /> Loading...
                  </SelectFeedback>
                )
              case MORE_CHARACTERS_NEEDED:
                return <SelectFeedback>Too few characters. Please type {MINIMUM_QUERY_STRING_LENGTH_FOR_REQUEST} characters or more.</SelectFeedback>
              case ERROR:
                return <SelectFeedback>{state.value}</SelectFeedback>
              case RESULT:
                return state.value.length ? (
                  <SelectOptionList className={styles.menu} isOpen={isOpen} menuProps={getMenuProps()}>
                    {state.value.map((item, index) => {
                      const shipIdComponents = shipIdToDescriptionComponents(item.value.shipId)
                      return (
                        <SelectOptionItem
                          itemProps={getItemProps({
                            item: item,
                            index: index,
                            onClick: evt => evt.preventDefault(),
                          })}
                          key={getKey(item)}
                          isHighlighted={index === highlightedIndex}
                          isSelected={item === selectedItem}>
                          {item.label}
                          <span className={styles.shipIdContainer}>
                            <span>{shipIdComponents.label}</span>
                            <span>{shipIdComponents.text}</span>
                          </span>
                        </SelectOptionItem>
                      )
                    })}
                  </SelectOptionList>
                ) : (
                  <SelectFeedback>No results. Please try a different query.</SelectFeedback>
                )
              default:
                const exhaustive: never = state
                throw new Error(exhaustive)
            }
          }}
        </ObservableResult>
      )}
    </Select>
  )
}
