import React from 'react'
import { InputWrapper } from '../InputWrapper/InputWrapper'
import { TextArea } from '../TextArea/TextArea'

export type TextAreaInputProps = Readonly<{
  id?: string
  label: string
  name?: string
  defaultValue?: string
  value?: string
  placeholder?: string
  rows?: number
  maxLength?: number
  isRequired?: boolean
  isDisabled?: boolean
  errorMessage?: string
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
}>

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
  id,
  label,
  name,
  defaultValue,
  value,
  placeholder,
  rows,
  maxLength,
  isRequired,
  isDisabled,
  errorMessage,
  handleChange,
  handleBlur,
}) => (
  <InputWrapper label={label} isRequired={isRequired} isDisabled={isDisabled} errorMessage={errorMessage}>
    <TextArea
      id={id}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      name={name}
      rows={rows}
      maxLength={maxLength}
      isDisabled={isDisabled}
      hasError={!!errorMessage}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />
  </InputWrapper>
)
