import { AllSortMethodType } from './../state/IUIState'
import { IUIState, IViewAgnosticUiState, BerthVisitEntryState, BerthVisitEntryKind, CommonSortMethodType } from '../state/IUIState'
import { AppAction } from '../actions/AppAction'
import { UI_CHANGE_SELECTED_TIME_FILTER, UI_TOGGLE_SHOW_BARGES, UI_TOGGLE_SHOW_DEEPSEA, UI_TOGGLE_SHOW_LAY_BY_BERTHS } from '../actions/viewAgnosticActions'
import { Option, none, some } from 'fp-ts/es6/Option'
import { OPEN_NEW_BERTH_VISIT_MODAL, OPEN_EDIT_BERTH_VISIT_MODAL, CLOSE_BERTH_VISIT_ENTRY_MODAL, CHANGE_BERTH_VISITS_SORT_METHOD } from '../actions/uiActions'

const defaultViewAgnosticUiState: IViewAgnosticUiState = {
  showBarges: true,
  showDeepsea: true,
  showLayByBerths: true,
  selectedTimeFilter: 48,
}

function viewAgnosticReducer(viewAgnosticState: IViewAgnosticUiState = defaultViewAgnosticUiState, action: AppAction) {
  switch (action.type) {
    case UI_TOGGLE_SHOW_BARGES:
      return {
        ...viewAgnosticState,
        showBarges: !viewAgnosticState.showBarges,
      }
    case UI_TOGGLE_SHOW_DEEPSEA:
      return {
        ...viewAgnosticState,
        showDeepsea: !viewAgnosticState.showDeepsea,
      }
    case UI_TOGGLE_SHOW_LAY_BY_BERTHS:
      return {
        ...viewAgnosticState,
        showLayByBerths: !viewAgnosticState.showLayByBerths,
      }
    case UI_CHANGE_SELECTED_TIME_FILTER:
      return {
        ...viewAgnosticState,
        selectedTimeFilter: action.numberOfHours,
      }
    default:
      return viewAgnosticState
  }
}

const defaultBerthVisitEntryModalState = none

function berthVisitEntryModalReducer(
  berthVisitEntryModalState: Option<BerthVisitEntryState> = defaultBerthVisitEntryModalState,
  action: AppAction
): Option<BerthVisitEntryState> {
  switch (action.type) {
    case OPEN_NEW_BERTH_VISIT_MODAL:
      return some({
        kind: BerthVisitEntryKind.New,
        prefilledFields: action.prefilledFields,
      })
    case OPEN_EDIT_BERTH_VISIT_MODAL:
      return some({
        kind: BerthVisitEntryKind.Edit,
        berthVisitId: action.berthVisitId,
      })
    case CLOSE_BERTH_VISIT_ENTRY_MODAL:
      return none
    default:
      return berthVisitEntryModalState
  }
}

const defaultSortMethodState = CommonSortMethodType.ByBerthNameAsc

function sortMethodReducer(sortMethodState: AllSortMethodType = defaultSortMethodState, action: AppAction) {
  switch (action.type) {
    case CHANGE_BERTH_VISITS_SORT_METHOD:
      return action.sortMethod

    default:
      return sortMethodState
  }
}

export function uiReducer(uiState: IUIState = {} as IUIState, action: AppAction): IUIState {
  return {
    viewAgnostic: viewAgnosticReducer(uiState.viewAgnostic, action),
    berthVisitEntryModal: berthVisitEntryModalReducer(uiState.berthVisitEntryModal, action),
    sortMethod: sortMethodReducer(uiState.sortMethod, action),
  }
}
