import { useEffect, useRef } from 'react'
import { DateTime } from '../Form/DateTimeInput/DateTimeInput'
import { IBerthVisitFormState } from './BerthVisitForm'
import { Option } from 'fp-ts/lib/Option'
import { TimeZone } from '../../state/TimeZone'
import { isRight } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/pipeable'
import { addHours, addMinutes } from 'date-fns'
import { toDateTime } from '../../utils/dates/dates'
import { toDate } from './toDate'
import { toTimeComponents } from './toTimeComponents'

export const useBatchEntry = (
  arrival: DateTime,
  startCargo: DateTime,
  completeCargo: DateTime,
  departure: DateTime,
  preStartCargoMargin: string,
  preDepartureMargin: string,
  timeZone: Option<TimeZone>,
  dateFormat: string,
  setFieldValue: <Key extends keyof IBerthVisitFormState, Value extends IBerthVisitFormState[Key]>(key: Key, value: Value) => void
) => {
  const startCargoRef = useRef(startCargo)
  const completeCargoRef = useRef(completeCargo)
  const timeZoneRef = useRef(timeZone)
  const dateFormatRef = useRef(dateFormat)

  useEffect(() => {
    const eitherArrival = toDate(arrival, timeZoneRef.current, dateFormatRef.current)
    const eitherPreStartCargoMargin = toTimeComponents(preStartCargoMargin)

    if (isRight(eitherArrival) && isRight(eitherPreStartCargoMargin) && isEmptyDateTime(startCargoRef.current)) {
      const newStartCargo = pipe(
        eitherArrival.right,
        date => addHours(date, eitherPreStartCargoMargin.right.hours),
        date => addMinutes(date, eitherPreStartCargoMargin.right.minutes)
      )
      startCargoRef.current = { ...toDateTime(newStartCargo, timeZoneRef.current, dateFormatRef.current), isActual: false }
      setFieldValue('startCargo', { ...toDateTime(newStartCargo, timeZoneRef.current, dateFormatRef.current), isActual: false })
    }
  }, [arrival, startCargoRef, preStartCargoMargin, dateFormatRef, timeZoneRef, setFieldValue])

  useEffect(() => {
    const eitherDeparture = toDate(departure, timeZoneRef.current, dateFormatRef.current)
    const eitherPreDepartureMargin = toTimeComponents(preDepartureMargin)

    if (isRight(eitherDeparture) && isRight(eitherPreDepartureMargin) && isEmptyDateTime(completeCargoRef.current)) {
      const newCompleteCargo = pipe(
        eitherDeparture.right,
        date => addHours(date, -eitherPreDepartureMargin.right.hours),
        date => addMinutes(date, -eitherPreDepartureMargin.right.minutes)
      )
      completeCargoRef.current = { ...toDateTime(newCompleteCargo, timeZoneRef.current, dateFormatRef.current), isActual: false }
      setFieldValue('completeCargo', { ...toDateTime(newCompleteCargo, timeZoneRef.current, dateFormatRef.current), isActual: false })
    }
  }, [departure, completeCargoRef, preDepartureMargin, dateFormatRef, timeZoneRef, setFieldValue])
}

const isEmptyDateTime = (dateTime: DateTime) => !dateTime.date && !dateTime.time
