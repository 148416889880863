import React from 'react'
import { Formik, Form } from 'formik'
import { number as YupNumber, object as YupObject, string as YupString, ref as YupRef } from 'yup'
import styles from './LayByBerthForm.module.scss'
import { ButtonContainer } from '../ButtonContainer/ButtonContainer'
import { Button } from '../Form/Button/Button'
import { FormikSelect } from '../Form/Select/FormikSelect'
import { StandardOptionList } from '../Form/Select/StandardOptionList'
import { SelectOption } from '../Form/Select/Select'
import { IBerth } from '../../state/IBerth'
import { FormikTextInput } from '../Form/TextInput/FormikTextInput'
import classNames from 'classnames'
import { FormikTextAreaInput } from '../Form/TextAreaInput/FormikTextAreaInput'
import { addHours, differenceInHours, isAfter } from 'date-fns'
import {
  dateTimeToDateUTC,
  emptyDateTime,
  isAvailable,
  ILayByBerth,
  LayByBerthFormState,
  layByBerthFormStateToLayByBerthRequestBody,
  LayByBerthStatus,
  layByBerthToLayByBerthFormState,
  LAY_BY_STATUS_TEXT,
  POSSIBLE_LAY_BY_STATUSES,
} from '../../Domain/LayByBerth'
import { useDispatch, useSelector } from 'react-redux'
import { berthsSelector, orderedBerthsSelector } from '../../selectors/berthsSelector'
import { toOptions } from '../Form/Select/toOptions'
import { identity } from 'fp-ts/es6/function'
import { submitDeleteLayByBerthsThunk, submitEditLayByBerthThunk, submitNewLayByBerthThunk } from '../../actions/thunks'
import { FormikDateTimeInput } from '../Form/DateTimeInput/FormikDateTimeInput'
import { capitalize } from 'lodash'
import classnames from 'classnames'
import { useMixpanel } from '../../context/MixpanelContext'

const MIN_LAYBY_DURATION_HOURS = 6
const validationSchema = YupObject().shape(
  {
    asset: YupObject().nullable().required('Berth needs to be selected'),
    startTime: YupObject({ date: YupString(), time: YupString() })
      .required()
      .test({ message: 'Date is incorrect', test: ({ date, time }) => !!date && !!time }),
    endTime: YupObject({ date: YupString(), time: YupString() })
      .required()
      .test({ message: 'Date is incorrect', test: ({ date, time }) => !!date && !!time })
      .test({
        message: `Minimum duration is ${MIN_LAYBY_DURATION_HOURS} hours`,
        test: function (value) {
          if (!value.date || !value.time) {
            return false
          }

          const { startTime: startTimeValue } = this.parent

          if (!startTimeValue.date || !startTimeValue.time) {
            // if startTime doesn't exist - nothing to check
            return true
          }

          const startTime = dateTimeToDateUTC(startTimeValue)
          const endTime = dateTimeToDateUTC({ date: value.date, time: value.time })

          return isAfter(endTime, addHours(startTime, MIN_LAYBY_DURATION_HOURS))
        },
      }),
    bollardStart: YupNumber().when('bollardEnd', {
      is: (bollardEnd: string) => !!bollardEnd,
      then: YupNumber().required('Required').positive().integer(),
    }),
    bollardEnd: YupNumber().when('bollardStart', {
      is: (bollardStart: string) => !!bollardStart,
      then: YupNumber().required('Required').positive().integer().moreThan(YupRef('bollardStart'), 'Start bollard should be lower than end'),
    }),
    status: YupString().oneOf(POSSIBLE_LAY_BY_STATUSES).required('Required'),
    note: YupString(),
  },
  [
    ['bollardStart', 'bollardEnd'],
    ['startTime', 'endTime'],
  ]
)

type CommonLayByBerthFormProps = {
  initialValues: LayByBerthFormState
  onSubmit: (values: LayByBerthFormState) => void
} & ({ formType: 'create' } | { formType: 'edit'; onDelete: () => void })

type CreateTypeLayByBerthFormProps = CommonLayByBerthFormProps & { formType: 'create' }
type EditTypeLayByBerthFormProps = CommonLayByBerthFormProps & { formType: 'edit'; onDelete: () => void }

type LayByBerthFormProps = CreateTypeLayByBerthFormProps | EditTypeLayByBerthFormProps
const isEditForm = (props: LayByBerthFormProps): props is EditTypeLayByBerthFormProps => props.formType === 'edit'

const LayByBerthForm: React.FC<LayByBerthFormProps> = props => {
  const { initialValues, onSubmit } = props
  const berths = useSelector(orderedBerthsSelector)
  const berthOptions: SelectOption<IBerth>[] = toOptions(berths, identity, b => b.name)
  const statusOptions: SelectOption<LayByBerthStatus>[] = toOptions(POSSIBLE_LAY_BY_STATUSES, identity, s => capitalize(LAY_BY_STATUS_TEXT[s]))

  return (
    <Formik validationSchema={validationSchema} validateOnBlur initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <Form>
          <div className={styles.form}>
            <aside className={styles.settings}>
              <h2 className={styles.title}>Lay-by berth slots</h2>
              <p className={styles.content}>
                Show available Lay-by slots on your Terminal. <br />
                <br />
                Agents / Carriers can contact you in order to make a reservation.
              </p>
              <div className={styles.statusSelectContainer}>
                <FormikSelect name="status" label="Lay-by status" options={statusOptions} getKey={option => option.value}>
                  {props => <StandardOptionList {...props} />}
                </FormikSelect>
              </div>
            </aside>
            <div className={styles.innerForm}>
              <section className={styles.section}>
                <FormikSelect name="asset" label="Berth" options={berthOptions} getKey={option => option.value.uuid}>
                  {props => <StandardOptionList {...props} />}
                </FormikSelect>
                <div className={classnames(styles.group, styles.groupBollards)}>
                  <FormikTextInput name="bollardStart" label="Bollards fore" type="number" />
                  <FormikTextInput name="bollardEnd" label="Bollards aft." type="number" />
                </div>
                <div className={classnames(styles.group, styles.groupTime)}>
                  <FormikDateTimeInput name="startTime" label="Available From" dateFormat="MM/DD" />
                  <FormikDateTimeInput name="endTime" label="Available Till" dateFormat="MM/DD" />
                </div>
              </section>
              <section className={classNames(styles.section, styles.sectionWide)}>
                <FormikTextAreaInput name="note" label="Remark" rows={5} placeholder="Remarks" />
              </section>
            </div>
          </div>
          <ButtonContainer>
            {isEditForm(props) && (
              <Button text="Delete" type="button" onClick={props.onDelete} theme={{ button: classNames(styles.button, styles.buttonOutlined) }} />
            )}
            <Button
              text={isEditForm(props) ? 'Save changes' : 'Save & publish'}
              theme={{ button: classNames(styles.button, styles.buttonCta) }}
              type="submit"
            />
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  )
}

type CreateLayByBerthFormProps = { onClose: () => void }
export const CreateLayByBerthForm: React.FC<CreateLayByBerthFormProps> = ({ onClose }) => {
  const { trackEvent } = useMixpanel()
  const dispatch = useDispatch()

  const handleCreate = (layByBerthFormState: LayByBerthFormState) => {
    const { startTime, endTime, asset } = layByBerthFormState
    const requestBody = layByBerthFormStateToLayByBerthRequestBody(layByBerthFormState)
    dispatch(submitNewLayByBerthThunk(requestBody))
    trackEvent('Add lay-by', {
      berth: asset?.name,
      duration: `${differenceInHours(dateTimeToDateUTC(endTime), dateTimeToDateUTC(startTime))}H`,
    })
    onClose()
  }

  return (
    <LayByBerthForm
      formType="create"
      initialValues={{
        asset: null,
        startTime: emptyDateTime(),
        endTime: emptyDateTime(),
        status: LayByBerthStatus.AVAILABLE,
      }}
      onSubmit={handleCreate}
    />
  )
}

type EditLayByBerthFormProps = { layByBerth: ILayByBerth; onClose: () => void }
export const EditLayByBerthForm: React.FC<EditLayByBerthFormProps> = ({ layByBerth, onClose }) => {
  const { trackEvent } = useMixpanel()
  const berths = useSelector(berthsSelector)
  const dispatch = useDispatch()

  const handleEdit = (layByBerthFormState: LayByBerthFormState) => {
    const { status: newStatus } = layByBerthFormState
    const requestBody = layByBerthFormStateToLayByBerthRequestBody(layByBerthFormState)
    dispatch(submitEditLayByBerthThunk(layByBerth.uuid, requestBody))
    if (layByBerth.status !== newStatus && !isAvailable(newStatus)) {
      trackEvent(`Status Change - ${LAY_BY_STATUS_TEXT[newStatus]}`)
    }
    onClose()
  }

  const handleDelete = () => {
    dispatch(submitDeleteLayByBerthsThunk([layByBerth.uuid]))
    onClose()
  }

  return <LayByBerthForm formType="edit" initialValues={layByBerthToLayByBerthFormState(layByBerth, berths)} onSubmit={handleEdit} onDelete={handleDelete} />
}
