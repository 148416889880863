export const FIELD_SHIP_ID = 'FIELD_SHIP_ID'
export const FIELD_NAME = 'FIELD_NAME'
export const FIELD_BERTH = 'FIELD_BERTH'
export const FIELD_BOLLARDS = 'FIELD_BOLLARDS'
export const FIELD_BOLLARDS_FORE = 'FIELD_BOLLARDS_FORE'
export const FIELD_BOLLARDS_AFT = 'FIELD_BOLLARDS_AFT'
export const FIELD_ORIENTATION = 'FIELD_ORIENTATION'
export const FIELD_ARRIVAL_BERTH = 'FIELD_ARRIVAL_BERTH'
export const FIELD_START_CARGO = 'FIELD_START_CARGO'
export const FIELD_COMPLETE_CARGO = 'FIELD_COMPLETE_CARGO'
export const FIELD_DEPARTURE_BERTH = 'FIELD_DEPARTURE_BERTH'

export type ValidationField =
  | typeof FIELD_SHIP_ID
  | typeof FIELD_NAME
  | typeof FIELD_BERTH
  | typeof FIELD_BOLLARDS
  | typeof FIELD_BOLLARDS_FORE
  | typeof FIELD_BOLLARDS_AFT
  | typeof FIELD_ORIENTATION
  | typeof FIELD_ARRIVAL_BERTH
  | typeof FIELD_START_CARGO
  | typeof FIELD_COMPLETE_CARGO
  | typeof FIELD_DEPARTURE_BERTH

export function getFieldDescription(field: ValidationField): string {
  switch (field) {
    case FIELD_SHIP_ID:
      return 'Ship ID'
    case FIELD_NAME:
      return 'Name'
    case FIELD_BERTH:
      return 'Berth/Jetty'
    case FIELD_BOLLARDS:
      return 'Bollards'
    case FIELD_BOLLARDS_FORE:
      return 'Fore'
    case FIELD_BOLLARDS_AFT:
      return 'Aft'
    case FIELD_ORIENTATION:
      return 'PS/SB'
    case FIELD_ARRIVAL_BERTH:
      return 'Arrival'
    case FIELD_START_CARGO:
      return 'Start cargo'
    case FIELD_COMPLETE_CARGO:
      return 'Complete cargo'
    case FIELD_DEPARTURE_BERTH:
      return 'Departure'
    default:
      const exhaustive: never = field
      throw new Error(exhaustive)
  }
}
