import React from 'react'
import classnames from 'classnames'
import styles from './Header.module.scss'
import HamburgerIcon from '../Icons/HamburgerIcon'
import { LogoWithAppName } from '../LogoWithAppName/LogoWithAppName'
import { Button, ButtonTheme } from '../Form/Button/Button'
import { CenteredGrid } from '../CenteredGrid/CenteredGrid'
import { useLayByBerthEntryModal } from '../LayByBerthEntryModal/LayByBerthEntryModal'
import { FeatureToggle } from '../FeatureToggle/FeatureToggle'
import { isLaybyBerthPlanner, isVesselPlanner } from '../../utils/roles'

interface HeaderProps {
  onSideMenuButtonClick: () => void
}

const buttonTheme: ButtonTheme = {
  button: styles.addButton,
  icon: styles.addButtonIcon,
}

export const Header: React.FC = ({ children }) => (
  <header className={styles.header}>
    <CenteredGrid>
      <div className={styles.headerInner}>{children}</div>
    </CenteredGrid>
  </header>
)

interface PlanningHeaderProps extends HeaderProps {
  onAddVesselButtonClick: () => void
}

export const PlanningHeader = (props: PlanningHeaderProps) => {
  const { LayByBerthModalContainer, createLayByBerthHandler } = useLayByBerthEntryModal()
  return (
    <>
      <Header>
        <div className={styles.left}>
          <div className={styles.hamburger} onClick={props.onSideMenuButtonClick}>
            <HamburgerIcon />
          </div>
          <LogoWithAppName />
        </div>
        <div className={styles.right}>
          <FeatureToggle checks={[isLaybyBerthPlanner]}>
            <Button type="button" text="Add lay-by" materialIconName="add" theme={buttonTheme} onClick={createLayByBerthHandler} />
          </FeatureToggle>
          <FeatureToggle checks={[isVesselPlanner]}>
            <Button type="button" text="Add vessel" materialIconName="add" theme={buttonTheme} onClick={props.onAddVesselButtonClick} />
          </FeatureToggle>
        </div>
      </Header>
      <LayByBerthModalContainer />
    </>
  )
}

export const SecondaryHeader: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={classnames(styles.secondaryHeader, className)}>{children}</div>
)
