const ORIENTATION_JSON_STARBOARD = 'starboard'
const ORIENTATION_JSON_PORTSIDE = 'port'

export const ORIENTATION_DEFAULT = 'None'
export const ORIENTATION_STARBOARD = 'SB'
export const ORIENTATION_PORTSIDE = 'PS'

export type OrientationJson = typeof ORIENTATION_JSON_STARBOARD | typeof ORIENTATION_JSON_PORTSIDE | undefined
export type Orientation = typeof ORIENTATION_DEFAULT | typeof ORIENTATION_STARBOARD | typeof ORIENTATION_PORTSIDE

export const orientations: Orientation[] = [ORIENTATION_DEFAULT, ORIENTATION_STARBOARD, ORIENTATION_PORTSIDE]

export const orientationJsonToOrientation = (json: OrientationJson): Orientation => {
  switch (json) {
    case undefined:
      return ORIENTATION_DEFAULT
    case ORIENTATION_JSON_STARBOARD:
      return ORIENTATION_STARBOARD
    case ORIENTATION_JSON_PORTSIDE:
      return ORIENTATION_PORTSIDE
    default:
      const exhaustive: never = json
      throw exhaustive
  }
}

export const orientationToOrientationJson = (orientation: Orientation): OrientationJson => {
  switch (orientation) {
    case ORIENTATION_DEFAULT:
      return undefined
    case ORIENTATION_STARBOARD:
      return ORIENTATION_JSON_STARBOARD
    case ORIENTATION_PORTSIDE:
      return ORIENTATION_JSON_PORTSIDE
    default:
      const exhaustive: never = orientation
      throw exhaustive
  }
}
