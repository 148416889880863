import { IBerthVisit, BerthVisitId } from '../state/IBerthVisit'
import { AppAction } from '../actions/AppAction'
import { ADD_BERTH_VISITS, UPDATE_BERTH_VISITS, DELETE_BERTH_VISITS, CLEAR_BERTH_VISITS, LOAD_NO_BERTH_VISITS } from '../actions/berthVisitsActions'
import { Option, fold, map, none, some } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'

export function berthVisitsReducer(berthVisits: Option<IBerthVisit[]> = none, action: AppAction): Option<IBerthVisit[]> {
  switch (action.type) {
    case CLEAR_BERTH_VISITS:
      return none
    case LOAD_NO_BERTH_VISITS:
      return some([])
    case ADD_BERTH_VISITS:
      return pipe(
        berthVisits,
        fold(
          () => some(action.berthVisits),
          prevBerthVisits => some([...prevBerthVisits, ...action.berthVisits])
        )
      )

    // Update berth visits list with a berth visits list
    case UPDATE_BERTH_VISITS:
      return pipe(
        berthVisits,
        map(bv => {
          const newBerthVisitIds: BerthVisitId[] = action.payload.map((visit: IBerthVisit) => visit.id)

          // Filters the berth visits that are not needed to be updated and filters the removed BerthVisits
          const nonUpdatedBerthVisits: IBerthVisit[] = bv.filter(visit => newBerthVisitIds.includes(visit.id) && newBerthVisitIds.indexOf(visit.id) === -1)
          return [...nonUpdatedBerthVisits, ...action.payload]
        })
      )

    case DELETE_BERTH_VISITS:
      return pipe(
        berthVisits,
        map(bv => {
          return bv.filter(bv => !action.berthVisitIds.includes(bv.id))
        })
      )

    default:
      return berthVisits
  }
}
