import React from 'react'
import { FieldAttributes, useField } from 'formik'
import { SelectOption } from './Select'
import { MultiSelect, MultiSelectProps } from './MultiSelect'
import { Arrays } from '../../../utils/Arrays'

export type FormikMultiSelectProps<Value> = FieldAttributes<Omit<MultiSelectProps<Value>, 'selectedOptions' | 'errorMessage' | 'handleChange'>> &
  Readonly<{
    handleChange?: (option: SelectOption<Value>[]) => void
  }>
export function FormikMultiSelect<Value>(props: FormikMultiSelectProps<Value>) {
  const [field, meta, helpers] = useField<Value[]>(props.name)
  const errorMessage = meta.error && meta.touched ? meta.error : undefined
  const selectedOptions = props.options.filter(option => Arrays.includes(field.value, option.value))

  return (
    <MultiSelect
      {...props}
      selectedOptions={selectedOptions}
      handleChange={selectedOptions => {
        helpers.setValue([...selectedOptions.map(option => option.value)])
        if (props.handleChange) {
          props.handleChange(selectedOptions)
        }
      }}
      handleBlur={() => helpers.setTouched(true)}
      errorMessage={errorMessage}
    />
  )
}
