import React from 'react'
import { TextAreaInput, TextAreaInputProps } from './TextAreaInput'
import { FieldAttributes, useField } from 'formik'

export type FormikTextAreaInputProps = FieldAttributes<Omit<TextAreaInputProps, 'name' | 'value' | 'handleChange' | 'handleBlur' | 'errorMessage'>>
export const FormikTextAreaInput: React.FC<FormikTextAreaInputProps> = props => {
  const [field, meta] = useField<string>(props)
  const errorMessage = meta.error && meta.touched ? meta.error : undefined

  return (
    <TextAreaInput
      name={field.name}
      value={field.value ? field.value : ''}
      handleChange={field.onChange}
      handleBlur={field.onBlur}
      errorMessage={errorMessage}
      {...props}
    />
  )
}
