import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Route } from 'react-router'
import { App } from '../components/App/App'
import { useAuth, AuthStateType } from '../context/Auth0Context'
import { Login } from '../components/Login/Login'
import { AuthenticationError } from '../components/AuthenticationError/AuthenticationError'
import { Loader } from '../components/Loader/Loader'
import { LOADING } from '../components/Loader/ILoaderState'

const Routes = () => {
  const { authState } = useAuth()

  switch (authState.type) {
    case AuthStateType.Authenticated:
      return <Route component={App} />
    case AuthStateType.Authenticating:
      return <Loader state={{ type: LOADING }} />
    case AuthStateType.AuthenticationError:
      return (
        <>
          <AuthenticationError error={authState.error} />
          <Route component={Login} />
        </>
      )
    case AuthStateType.Unauthenticated:
      return <Route component={Login} />
    default:
      const exhaustive: never = authState
      throw new Error(exhaustive)
  }
}

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}
