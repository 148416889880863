import * as React from 'react'
import styles from './Login.module.scss'
import { Logo } from '../Logo/Logo'
import { LoginButton } from './LoginButton'
import { SUPPORT_EMAIL } from '../../constants'

export const Login = () => {
  return (
    <div className={styles.background}>
      <div className={styles.loginBox}>
        <div>
          <Logo />
          <div className={styles.logoText}>Vessel Planner</div>
        </div>
        <div className={styles.actions}>
          <div>
            <LoginButton />
          </div>
          <div className={styles.requestAccess}>
            <div className={styles.question}>No Vessel Planner account yet?</div>
            <a href={`mailto:${SUPPORT_EMAIL}`} className={styles.answer}>
              send a request
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
