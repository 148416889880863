import { reducer as toastrReducer } from 'react-redux-toastr'
import { berthVisitsReducer } from './berthVisitsReducer'
import { berthsReducer } from './berthsReducer'
import { IAppState } from '../state/IAppState'
import { AppAction } from '../actions/AppAction'
import { uiReducer } from './uiReducer'
import { authTokenReducer } from './authTokenReducer'
import { versionReducer } from './versionReducer'
import { errorReducer } from './errorReducer'
import { userReducer } from './userReducer'
import { stakeholdersReducer } from './stakeholdersReducer'
import { layByBerthsReducer } from './layByBerthsReducer'

export function reducer(appState: IAppState = {} as IAppState, action: AppAction): IAppState {
  return {
    version: versionReducer(appState.version),
    authToken: authTokenReducer(appState.authToken, action),
    user: userReducer(appState.user, action),
    berthVisits: berthVisitsReducer(appState.berthVisits, action),
    layByBerths: layByBerthsReducer(appState.layByBerths, action),
    berths: berthsReducer(appState.berths, action),
    stakeholders: stakeholdersReducer(appState.stakeholders, action),
    ui: uiReducer(appState.ui, action),
    error: errorReducer(appState.error, action),
    toastr: toastrReducer(appState.toastr, action),
  }
}
