import React from 'react'
import { FormikSelect, FormikSelectProps } from '../Select/FormikSelect'
import { FormikTimeInput, FormikTimeInputProps } from '../TimeInput/FormikTimeInput'
import {
  FormikDateTimeInput,
  FormikDateTimeInputProps,
  FormikDateTimeInputWithActual,
  FormikDateTimeInputWithActualProps,
} from '../DateTimeInput/FormikDateTimeInput'
import { FormikTextInput, FormikTextInputProps } from '../TextInput/FormikTextInput'
import { FormikTextAreaInput, FormikTextAreaInputProps } from '../TextAreaInput/FormikTextAreaInput'
import { FormikMultiSelect, FormikMultiSelectProps } from '../Select/FormikMultiSelect'

export const getFormFieldsHelper = <FormState extends {}>() => {
  type NamedFormikField<T> = T & { name: keyof FormState }

  return {
    FormikSelect: <Value extends {}>(props: NamedFormikField<FormikSelectProps<Value>>) => <FormikSelect {...props} />,
    FormikMultiSelect: function <Value>(props: NamedFormikField<FormikMultiSelectProps<Value>>) {
      return <FormikMultiSelect {...props} />
    },
    FormikTextInput: (props: NamedFormikField<FormikTextInputProps>) => <FormikTextInput {...props} />,
    FormikTextAreaInput: (props: NamedFormikField<FormikTextAreaInputProps>) => <FormikTextAreaInput {...props} />,
    FormikTimeInput: (props: NamedFormikField<FormikTimeInputProps>) => <FormikTimeInput {...props} />,
    FormikDateTimeInputWithActual: (props: NamedFormikField<FormikDateTimeInputWithActualProps>) => <FormikDateTimeInputWithActual {...props} />,
    FormikDateTimeInput: (props: NamedFormikField<FormikDateTimeInputProps>) => <FormikDateTimeInput {...props} />,
  }
}
