import { useCallback } from 'react'
import { useAuth0Utils } from '../context/Auth0Context'
import { backend } from './backend'

export const postStartSession = async (authToken: string, idToken: string): Promise<boolean> => {
  const options = {
    headers: {
      'X-Auth-Token': authToken,
      'X-Id-Token': idToken,
    },
  }
  return backend.post('track', options).then(({ ok }) => ok)
}

export const usePostStartSession = () => {
  const authUtils = useAuth0Utils()

  return useCallback(async () => {
    if (!authUtils) {
      return false
    }
    try {
      const [authToken, idToken] = await Promise.all([authUtils.getToken(), authUtils.getIdToken()])

      return await postStartSession(authToken, idToken)
    } catch {
      return false
    }
  }, [authUtils])
}
