import { Either, left, right } from 'fp-ts/lib/Either'
import { validateDateTimeComponent } from '../../utils/dates/dates'

type TimeComponents = Readonly<{
  hours: number
  minutes: number
}>

export const toTimeComponents = (time: string): Either<null, TimeComponents> => {
  const validatedTime = validateDateTimeComponent(':', time)

  return validatedTime.type === 'ok' ? right({ hours: validatedTime.value[0], minutes: validatedTime.value[1] }) : left(null)
}
