import { ILayByBerth, LayByBerthRequestBody } from './../../Domain/LayByBerth'
import { Either } from 'fp-ts/es6/Either'
import { backend, handleRequest, HandleRequestReturn } from '../backend'

export type FetchLayByBerthsResult = Either<[number, Error], ILayByBerth[]>

export const addLayByBerth = async (authToken: string, requestBody: LayByBerthRequestBody) =>
  handleRequest(() =>
    backend.post('api/terminals/laybyberths', {
      headers: {
        'X-Auth-Token': authToken,
      },
      json: requestBody,
    })
  )

export const editLayByBerth = async (authToken: string, layByBerthId: ILayByBerth['uuid'], requestBody: LayByBerthRequestBody) =>
  handleRequest(() =>
    backend.put(`api/terminals/laybyberths/${layByBerthId}`, {
      headers: {
        'X-Auth-Token': authToken,
      },
      json: requestBody,
    })
  )

export const deleteLayByBerths = async (authToken: string, layByBerthIds: ILayByBerth['uuid'][]) =>
  handleRequest(() =>
    backend.delete(`api/terminals/laybyberths`, {
      headers: {
        'X-Auth-Token': authToken,
      },
      json: {
        layByBerthIds,
      },
    })
  )
export const fetchLayByBerths = async (authToken: string): HandleRequestReturn<ILayByBerth[]> =>
  handleRequest(() =>
    backend
      .get('api/terminals/laybyberths', {
        headers: {
          'X-Auth-Token': authToken,
        },
      })
      .json<ILayByBerth[]>()
  )
