import { createSelector } from 'reselect'
import { IBerth, ITerminal, TERMINAL_TYPE, TerminalUuid } from '../state/IBerth'
import { berthsSelector } from './berthsSelector'

export const getTerminals = (berths: IBerth[]): ITerminal[] => [
  ...berths
    .reduce((prev, { terminalUuid, terminalName, timeZone }) => {
      const key = terminalUuid || terminalName || null
      return prev.set(key, {
        type: TERMINAL_TYPE,
        terminalName,
        terminalUuid,
        timeZone,
      })
    }, new Map<TerminalUuid | string | null, ITerminal>())
    .values(),
]

// One of the terminals returned by this selector has no terminalName and no terminalUuid, this is for
// the berths that do not belong to any terminal
export const terminalsSelector = createSelector(berthsSelector, getTerminals)
