import React, { useCallback } from 'react'
import { BerthVisitsHeader } from '../BerthVisitsTable/BerthVisitsHeader'
import { BerthVisitsTable } from '../BerthVisitsTable/BerthVisitsTable'
import { IBerthVisitTableRow, ILayByBerthTableRow, ITableRow } from '../../selectors/berthVisitTableRow'
import { useSelectRows } from './useSelectRows'
import { useBerthVisitEntryModal } from '../BerthVisitEntryModal/useBerthVisitEntryModal'
import { useRowsDeleteModal } from '../BerthVisitDeleteModal/BerthVisitDeleteModal'
import { CenteredGrid } from '../CenteredGrid/CenteredGrid'
import { useLayByBerthEntryModal } from '../LayByBerthEntryModal/LayByBerthEntryModal'

type BerthVisitsContentProps = Readonly<{ berthVisits: IBerthVisitTableRow[]; layByBerths: ILayByBerthTableRow[]; rows: ITableRow[] }>

export const BerthVisitsContent: React.FC<BerthVisitsContentProps> = ({ rows }) => {
  const { selectedRows, allSelected, selectSingle, deselectAll, selectAll, deleteSelected } = useSelectRows(rows)
  const { editBerthVisitHandler } = useBerthVisitEntryModal()
  const { askForDelete, RowsDeleteModalContainer } = useRowsDeleteModal()

  const { LayByBerthModalContainer, editLayByBerthHandler } = useLayByBerthEntryModal()
  const handleOpenDeleteModal = useCallback(() => askForDelete(selectedRows.length, deleteSelected), [selectedRows, deleteSelected, askForDelete])

  return (
    <>
      <BerthVisitsHeader
        allSelected={allSelected}
        selectedRows={selectedRows}
        selectAll={selectAll}
        deselectAll={deselectAll}
        deleteSelected={handleOpenDeleteModal}
      />
      <CenteredGrid>
        <BerthVisitsTable
          rows={rows}
          selectedRows={selectedRows}
          selectSingle={selectSingle}
          editBerthVisitHandler={editBerthVisitHandler}
          editLayByBerthHandler={editLayByBerthHandler}
        />
      </CenteredGrid>

      <LayByBerthModalContainer />
      <RowsDeleteModalContainer />
    </>
  )
}
