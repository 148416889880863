import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import createDebug from 'debug'
import { MIXPANEL_ID } from '../config'
import { noOp } from '../utils/functions'
import {
  identifyUserForTracking,
  incrementUserProperty,
  resetTracking,
  setTrackingSuperProperties,
  setTrackingSuperPropertiesOnce,
  setTrackingUserProperties,
  trackEvent,
  initMixpanel,
  incrementSuperProperty,
} from '../utils/mixpanel/trackMixpanel'

interface IMixpanelContext {
  setupUser: (userId: string, properties: {}, loginCallback?: Function) => void
  trackEvent: (name: string, properties?: {}) => void
  setTrackingSuperProperties: (properties: {}) => void
  setTrackingSuperPropertiesOnce: (properties: {}) => void
  setTrackingUserProperties: (properties: {}) => void
  incrementUserProperty: (key: string, value: number) => void
  incrementSuperProperty: (key: string, value: number) => void
  resetTracking: () => void
}

const MIXPANEL_API_HOST = 'https://api-eu.mixpanel.com'
const debugMixpanel = createDebug('mixpanel')

const MixpanelContext: React.Context<IMixpanelContext> = React.createContext({} as IMixpanelContext)

const useEventQueue = () => {
  const eventQueueRef = useRef<Function[]>([])

  const addEvent = useCallback(
    (fn: Function) => {
      eventQueueRef.current.push(fn)
    },
    [eventQueueRef]
  )
  const emptyQueue = useCallback(() => {
    eventQueueRef.current.forEach(fn => fn())
    eventQueueRef.current = []
  }, [eventQueueRef])

  return { addEvent, emptyQueue }
}

const useInitTracking = () => {
  useEffect(() => {
    initMixpanel(MIXPANEL_ID, {
      // To enable, in the browser console enter `localStorage.debug="mixpanel"` and reload the page
      debug: debugMixpanel.enabled,
      api_host: MIXPANEL_API_HOST,
    })
  }, [])
}

export const useMixpanel = () => useContext(MixpanelContext)

export const MixpanelProvider: React.FC = ({ children }) => {
  const [isUserSetup, setIsUserSetup] = useState<boolean>(false)
  const { addEvent, emptyQueue } = useEventQueue()

  useInitTracking()

  const setupUser = useCallback(
    (userId: string, properties: {}, loginCallback: Function = noOp) => {
      setTrackingSuperProperties(properties)
      setTrackingUserProperties(properties)

      if (!isUserSetup) {
        identifyUserForTracking(userId)
        setIsUserSetup(true)
        loginCallback()
      }
    },
    [isUserSetup, setIsUserSetup]
  )
  const interceptedResetTracking = useCallback(() => {
    resetTracking()
    setIsUserSetup(false)
  }, [setIsUserSetup])

  useEffect(() => {
    if (isUserSetup) {
      emptyQueue()
    }
  }, [isUserSetup, emptyQueue])

  const mixpanelContext = useMemo<IMixpanelContext>(
    () => ({
      setupUser,
      trackEvent: isUserSetup ? trackEvent : (...args) => addEvent(() => trackEvent(...args)),
      setTrackingSuperProperties,
      setTrackingSuperPropertiesOnce,
      setTrackingUserProperties,
      incrementSuperProperty,
      incrementUserProperty: isUserSetup ? incrementUserProperty : (...args) => addEvent(() => incrementUserProperty(...args)),
      resetTracking: interceptedResetTracking,
    }),
    [setupUser, interceptedResetTracking, addEvent, isUserSetup]
  )

  return <MixpanelContext.Provider value={mixpanelContext}>{children}</MixpanelContext.Provider>
}
