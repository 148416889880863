import React from 'react'
import { LabelledCell } from './LabelledCell'
import { IShipId, shipIdToDescriptionComponents } from '../../state/IShip'

type ShipIdViewProps = Readonly<{
  shipId: IShipId
}>

export const ShipIdView: React.FC<ShipIdViewProps> = ({ shipId }) => {
  const { label, text } = shipIdToDescriptionComponents(shipId)

  return <LabelledCell label={label} value={text} />
}
