import { IStakeholder, StakeholderId } from '../../state/IStakeholder'

export interface IStakeholderJson {
  id: StakeholderId
  name: string
}

function toStakeholder(stakeholderJson: IStakeholderJson): IStakeholder {
  return stakeholderJson
}

export function toStakeholders(json: IStakeholderJson[]): IStakeholder[] {
  return json.map(toStakeholder)
}
