import React, { useState, useEffect, useCallback } from 'react'
import { WithResult, RESULT } from '../../utils/toResultObservable'
import { Observable } from 'rxjs'

type ObservableResultProps<Result> = Readonly<{
  resultObservable: Observable<WithResult<Result>>
  onLoaded: (options: Result) => void
  children: (props: ObservableResultChildProps<Result>) => React.ReactElement | null
}>

type ObservableResultChildProps<Result> = Readonly<{
  state: WithResult<Result>
}>

export const ObservableResult = <Result extends {}>({ children, resultObservable, onLoaded }: ObservableResultProps<Result>) => {
  const [state, setState] = useState<WithResult<Result>>({ type: 'QUERY_STRING_EMPTY', value: null })
  const onLoadedCallback = useCallback(onLoaded, [])

  useEffect(() => {
    const sub = resultObservable.subscribe(state => {
      setState(state)

      if (state.type === RESULT) {
        onLoadedCallback(state.value)
      }
    })

    return sub.unsubscribe.bind(sub)
  }, [resultObservable, onLoadedCallback])

  return children({ state })
}
