import React from 'react'
import { AppAction } from '../actions/AppAction'
import { VesselPlannerDispatch } from '../thunk'
import { setError } from '../actions/errorActions'
import { IError } from '../state/IError'
import { PromiseOfValidated } from '../utils/PromiseOfValidated'
import { Validated } from '../utils/Validated'
import { onFetchError } from '../middleware/errorLoggerMiddleware'
import { DefaultError } from '../components/errors/DefaultError'
import { ForbiddenError } from '../components/errors/ForbiddenError'
import { UnauthorisedError } from '../components/errors/UnauthorisedError'
import { InvalidInputError } from '../components/errors/InvalidInputError'

const defaultError: IError = {
  dialogContents: DefaultError,
}

const createErrorFromResponse = (err: any): Error => (err instanceof Error ? err : new Error('message' in err ? err.message : err))

export const deconstructResponseError = async (res: Response): Promise<[number, Error]> => {
  try {
    return [res.status, createErrorFromResponse(await res.json())]
  } catch (e) {
    return [res.status, createErrorFromResponse(e)]
  }
}

export function errorFromResponseStatus(status: number, error: Error) {
  switch (status) {
    case 400: {
      return () => <InvalidInputError error={error} />
    }
    case 401: {
      return UnauthorisedError
    }
    case 403: {
      return ForbiddenError
    }
    default: {
      return DefaultError
    }
  }
}

export const setErrorAction = (dialogContents: () => JSX.Element) =>
  setError({
    dialogContents,
  })

function handleResponse(p: Promise<Response>, url: string, init: RequestInit): PromiseOfValidated<AppAction, Response> {
  const raw: Promise<Validated<AppAction, Response>> = p.then(response => {
    if (response.ok) {
      return Validated.ok<AppAction, Response>(response)
    }

    return deconstructResponseError(response)
      .then(([status, error]) => errorFromResponseStatus(status, error))
      .then(e => Validated.error<AppAction, Response>(setErrorAction(e)))
  })

  return new PromiseOfValidated<AppAction, Response>(raw, reason => {
    const fetchError = {
      reason,
      url,
      init,
    }
    onFetchError(JSON.stringify(fetchError))
    return setError(defaultError)
  })
}

export function handleEmptyResponse(p: Promise<Response>, url: string, init: RequestInit): PromiseOfValidated<AppAction, null> {
  return handleResponse(p, url, init).map(() => null)
}

export function handleJsonResponse<A>(p: Promise<Response>, url: string, init: RequestInit): PromiseOfValidated<AppAction, A> {
  return handleResponse(p, url, init).flatMap(r => PromiseOfValidated.fromPromise<AppAction, A>(r.json()))
}

export function dispatchPromiseOfValidated(dispatch: VesselPlannerDispatch, p: PromiseOfValidated<AppAction, AppAction>) {
  p.raw.then(validated => {
    if (validated.isValid()) {
      dispatch(validated.value)
    } else {
      validated.errors.forEach(dispatch)
    }
  })
}
