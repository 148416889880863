import React from 'react'
import { userAvatarUrlSelector } from '../../selectors/userAvatarUrlSelector'
import { useSelector } from 'react-redux'
import { userCompanySelector } from '../../selectors/userCompanySelector'
import { useAuth } from '../../context/Auth0Context'
import styles from './UserProfile.module.scss'
import { Button } from '../Form/Button/Button'

export const UserProfile = () => {
  const { authUtils } = useAuth()
  const avatar = useSelector(userAvatarUrlSelector)
  const company = useSelector(userCompanySelector)
  const avatarStyles: React.CSSProperties = avatar !== undefined ? { backgroundImage: `url(${avatar})` } : {}

  return (
    <div className={styles.sideMenuFooter}>
      <div className={styles.userIcon} style={avatarStyles} />
      <div className={styles.userProperty}>{company && company.name}</div>
      <Button text="Log out" type="button" onClick={authUtils.logout} />
    </div>
  )
}
