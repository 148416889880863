import { AppAction } from '../actions/AppAction'
import { Option, none, some, fold } from 'fp-ts/es6/Option'
import { ADD_LAY_BY_BERTHS, CLEAR_LAY_BY_BERTHS, LOAD_NO_LAY_BY_BERTHS } from '../actions/layByBerthsActions'
import { pipe } from 'fp-ts/es6/pipeable'
import { ILayByBerth } from '../Domain/LayByBerth'

export function layByBerthsReducer(layByBerths: Option<ILayByBerth[]> = none, action: AppAction): Option<ILayByBerth[]> {
  switch (action.type) {
    case CLEAR_LAY_BY_BERTHS:
      return none
    case LOAD_NO_LAY_BY_BERTHS:
      return some([])
    case ADD_LAY_BY_BERTHS:
      return pipe(
        layByBerths,
        fold(
          () => some(action.payload),
          prevLayByBerths => some([...prevLayByBerths, ...action.payload])
        )
      )
    default:
      return layByBerths
  }
}
