import React from 'react'
import styles from './CloseButton.module.scss'
import { Button, ButtonTheme } from '../Form/Button/Button'

type CloseButtonProps = Readonly<{
  onClick: () => void
}>

const closeButtonTheme: ButtonTheme = {
  button: styles.button,
  label: styles.label,
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => (
  <Button text="Close" type="button" materialIconName="close" theme={closeButtonTheme} onClick={onClick} />
)
