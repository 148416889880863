import { IBerthVisit } from '../state/IBerthVisit'
import { createSelector } from 'reselect'
import { showDeepseaSelector } from './showDeepseaSelector'
import { showBargesSelector } from './showBargesSelector'
import { Option, map } from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/pipeable'
import { berthVisitsSelector } from './berthVisitsSelector'

const shouldDisplay = (showBarges: boolean, showDeepsea: boolean) => ({ ship }: IBerthVisit): boolean =>
  (showBarges && ship.imo === undefined) || (showDeepsea && ship.imo !== undefined)

export const berthVisitsForVesselTypeSelector = createSelector(
  berthVisitsSelector,
  showBargesSelector,
  showDeepseaSelector,
  (berthVisits: Option<IBerthVisit[]>, showBarges: boolean, showDeepsea: boolean) => {
    return pipe(
      berthVisits,
      map(bv => bv.filter(shouldDisplay(showBarges, showDeepsea)))
    )
  }
)
